import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/services/alert.service';
import { EnvService } from 'src/app/services/env.service';
import { GeneralService } from 'src/app/services/general.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-nitemorderoption',
  templateUrl: './nitemorderoption.page.html',
  styleUrls: ['../../general/ngeneral.scss', './nitemorderoption.page.scss'],
})
export class NitemorderoptionPage implements OnInit {

  quotationd_actiontype : number = 0;
  quotationd_instruction : string = '';

  itemgroup_ids: any;
  itemgroup_idsselectedvalue: any;

  radioChangeHandlerArray: any = [];
  radioChangeHandlerPriceArray: any = [];


  ItemOptionsHandlerArray: any = [];
  
  //item_id : number = 23304;
  //item_name : string = 'Asdf asdfas dfdsa fsdf asdf sdafsadfasd fafdas dfsadfad';

  item_id : number;
  item_name : string;

  itemgroup_result : any;
  item_result : any;
  itemtags_list : any; 
  qty:number = 1;

  quotationd_id: any = 0;
  quotationd_result : any;
  quotationdspecifications_result : any; 

  constructor(private loadingController:LoadingController,
    private generalService: GeneralService,
    private alertService: AlertService,
    private storage: Storage,
    private modalController:ModalController,
    public app: AppComponent,
    public env: EnvService,
    private http: HttpClient,
    public lang: LangService) { }

    async ngOnInit() {
      this.app.setupStorageVariables();
      await this.storage.get('selected_branchdata').then(() => { this.setup(); });
    }

    async setup() {
      const loading = await this.loadingController.create({
        cssClass: 'loadingScreen',
        spinner: null,
        translucent: true,
        showBackdrop: false,
        message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
      });
      loading.present();
  
      const formData = new FormData();
      formData.append('cmp_id', this.app.active_cmp_id.toString());
      formData.append('branch_id', this.app.active_branch_id.toString());
      if(this.app.selected_grouptype == 7) {
        formData.append('signature', this.app.selected_signature.toString()+'7');
      } else {
        formData.append('signature', this.app.selected_signature.toString());
      } 
      formData.append('pointer_latitude', this.app.mylocation_latitude.toString());
      formData.append('pointer_longitude', this.app.mylocation_longitude.toString());

      formData.append('item_id', this.item_id.toString());
      formData.append('call_itemspecification', '1');
      formData.append('call_itemtags', '1');
      formData.append('lang', this.lang.current_lang);

      if(this.quotationd_id) {
        formData.append('call_quotationdresult', '1');
        formData.append('quotationd_id', this.quotationd_id);
      }
  
      this.http.post(this.app.env.API_URL+'shop', formData).subscribe(
        (response: Response) => {
          this.itemgroup_result = response['itemgroup_result'];
          this.item_result = response['item_result'];
          this.itemtags_list = response['itemtags_list'];

          if(response['quotationdspecifications_result']) { this.quotationdspecifications_result = response['quotationdspecifications_result']; }
          if(response['quotationd_result']) {
            this.quotationd_result = response['quotationd_result'];
            setTimeout(() => {
              this.setup_quotationdresult();
            }, 200);
          }

          loading.dismiss();

        },
        error => {
          loading.dismiss();
          this.itemgroup_result = undefined;
          this.item_result = undefined;
          this.itemtags_list = undefined;

          console.log( error );
          console.log( error.error );
          if( error.error.messagedetail == undefined ) {
              this.alertService.error('', this.lang.v.Error_to_connect);
          } else {
              
            this.alertService.error('', error.error.messagedetail);
          }
        }
      );
    }

    getlast_itemgroupid(__i:number) {
      if(__i === 0) {
        return 0;
      } else {
        __i--;
        return this.itemgroup_result[__i]['itemgroup_id'];
      }
    }

    addqty(aqty:number) {
      this.qty = this.qty + aqty;
      if(this.qty < 1) { this.qty = 1; }
    }

    close() {
      this.modalController.dismiss('');
    }


    add_to_cart() {
      this.itemgroup_ids = [];
  
      //console.log('item_id');
      //console.log(this.item_result.item_id);
  
      //console.log('qty');
      //console.log(this.qty);
  
      let totaloption:number=0;
      this.itemgroup_result.forEach((itemgroup:any, index:number) => {
        let active_validateitemgroup_id = 0;
        if(index == 0) {
          totaloption++;
          active_validateitemgroup_id = 1;
          
        } else {
          let lastindex = (index-1);
          if(this.itemgroup_result[lastindex].itemgroup_id != itemgroup.itemgroup_id) {
            totaloption++;
            active_validateitemgroup_id = 1;
          }
        }
  
        if(active_validateitemgroup_id == 1) {
          //console.log('itemgroupitem_'+itemgroup.itemgroup_id);
          let arrayi = [];
          arrayi['itemgroup_id'] = itemgroup.itemgroup_id;
  
          let itemgroup_value = 0;
          if(this.radioChangeHandlerArray[itemgroup.itemgroup_id]) {
            itemgroup_value = this.radioChangeHandlerArray[itemgroup.itemgroup_id];
          }
          arrayi['itemgroup_value'] = itemgroup_value;
          this.itemgroup_ids.push(arrayi);
        }
      });
      //console.log('totaloption');
      //console.log(totaloption);
  
      //console.log('itemgroup_ids');
      //console.log( this.itemgroup_ids );
  
      this.fadd_to_cart(this.item_id, this.qty, totaloption, this.itemgroup_ids);
    }

    async fadd_to_cart(item_id:number, qty:number, totaloption:number, itemgroup_ids:any) {
      const loading = await this.loadingController.create({
        cssClass: 'loadingScreen',
        spinner: null,
        translucent: true,
        showBackdrop: false,
        message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
      });
      loading.present();
      const formData = new FormData();
      formData.append('cmp_id', this.app.active_cmp_id.toString());
      formData.append('branch_id', this.app.active_branch_id.toString());
      if(this.app.selected_grouptype == 7) {
        formData.append('signature', this.app.selected_signature.toString()+'7');
      } else {
        formData.append('signature', this.app.selected_signature.toString());
      }
      formData.append('pointer_latitude', this.app.mylocation_latitude.toString());
      formData.append('pointer_longitude', this.app.mylocation_longitude.toString());
      formData.append('cart', '1');
      formData.append('active_advancedaddtocart', '1');
      formData.append('item_id', item_id.toString());
      formData.append('qty', qty.toString());

      formData.append('quotationd_actiontype', this.quotationd_actiontype.toString());
      formData.append('quotationd_instruction', this.quotationd_instruction);

      formData.append('totaloption', this.ItemOptionsHandlerArray.length);
      
      this.ItemOptionsHandlerArray.forEach((element:any, index:number) => {
        formData.append('itemgroup_id_'+index, element.itemgroup_id);
        formData.append('itemgroupitem_id_'+index, element.itemgroupitem_id);
        formData.append('itemgroupitem_price_'+index, element.itemgroupitem_price);
      });
      formData.append('lang', this.lang.current_lang);

      if(this.quotationd_id) {
        formData.append('call_updatecartitem', '1');
        formData.append('quotationd_id', this.quotationd_id);
      } 

      this.http.post(this.env.API_URL+'shop', formData).subscribe(
        (response: Response) => {
          if(response['cartitems_list']) {
            this.storage.set('cartitems_list', response['cartitems_list']).then(() => {  
              loading.dismiss();
              this.modalController.dismiss('1');
              //this.alertService.success('', "Cart updated.");
            });
          }
        },
        error => {
          loading.dismiss();
          console.log( error );
          console.log( error.error );
          if( error.error.messagedetail == undefined ) {
              
            this.alertService.error('', this.lang.v.Error_to_connect);
          } else {              
            this.alertService.error('', error.error.messagedetail);
          }
        }
      );
    }

  get_itemspecification(_vitem:any) {
    if(_vitem==null || _vitem==undefined || _vitem == '') {
      return 0;
    } else {
      return 1;
    }
  }

  triggerRadioBtn(_aType:any) {
    this.quotationd_actiontype = _aType.detail.value;
  }

  trigger_specialinstruction() {
    this.quotationd_actiontype = 1;
  }

  trigger_add_to_cart() {
    //console.log(' trigger_add_to_cart ');
    if(this.get_itemspecification(this.item_result.item_specification) == 1) {
      this.add_to_cart();

    } else {
      this.direct_addtocart();
      
    }
  }

  async direct_addtocart() {
    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
    });
    loading.present();
    const formData = new FormData();
    formData.append('cmp_id', this.app.active_cmp_id.toString());
    formData.append('branch_id', this.app.active_branch_id.toString());
    if(this.app.selected_grouptype == 7) {
      formData.append('signature', this.app.selected_signature.toString()+'7');
    } else {
      formData.append('signature', this.app.selected_signature.toString());
    }
    formData.append('pointer_latitude', this.app.mylocation_latitude.toString());
    formData.append('pointer_longitude', this.app.mylocation_longitude.toString());

    formData.append('active_directaddtocart', '1');
    formData.append('item_id', this.item_id.toString());
    formData.append('qty', this.qty.toString());
    formData.append('cart', '1');

    formData.append('quotationd_actiontype', this.quotationd_actiontype.toString());
    formData.append('quotationd_instruction', this.quotationd_instruction);
    formData.append('lang', this.lang.current_lang);

    if(this.quotationd_id) {
      formData.append('call_updatecartitem', '1');
      formData.append('quotationd_id', this.quotationd_id);
    }

    this.http.post(this.env.API_URL+'shop', formData).subscribe(
      (response: Response) => {
        if(response['cartitems_list']) {
          this.storage.set('cartitems_list', response['cartitems_list']).then(() => {  
            loading.dismiss();
            this.modalController.dismiss('1');
            //this.alertService.success('', "Cart updated.");
          });
        }
      },
      error => {
        loading.dismiss();
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
            
            this.alertService.error('', this.lang.v.Error_to_connect);
        } else {
            
            this.alertService.error('', error.error.messagedetail);
        }
      }
    );
  }
  
  calculate_itemRate(_qty:number, _netPrice:number,) {
    return _netPrice / _qty;
  }

  get_itemincart(item_id:number) {
    let sum:number = 0;
    for (let index = 0; index < this.app.cartitems_list.length; index++) {
      if(item_id == this.app.cartitems_list[index].item_id) {
        sum += parseFloat(this.app.cartitems_list[index]['qty']);
      }
    }
    return sum;
  }

  async delete_cartitem(quotationd_id) {
    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
    });
    loading.present();

    const formData = new FormData();
    formData.append('cmp_id', this.app.active_cmp_id.toString());
    formData.append('branch_id', this.app.active_branch_id.toString());
    if(this.app.selected_grouptype == 7) {
      formData.append('signature', this.app.selected_signature.toString()+'7');
    } else {
      formData.append('signature', this.app.selected_signature.toString());
    }
    formData.append('pointer_latitude', this.app.mylocation_latitude.toString());
    formData.append('pointer_longitude', this.app.mylocation_longitude.toString());

    formData.append('quotationd_id', quotationd_id.toString());
    formData.append('delete_quotationdid', '1');
    
    formData.append('cart', '1');
    formData.append('lang', this.lang.current_lang);

    this.http.post(this.env.API_URL+'shop', formData).subscribe(
      (response: Response) => {
        if(response['cartitems_list']) {
          this.storage.set('cartitems_list', response['cartitems_list']).then(()=>{
            this.app.setupStorageVariables();
          });
        }
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
            
            this.alertService.error('', this.lang.v.Error_to_connect);
        } else {
            
            this.alertService.error('', error.error.messagedetail);
        }
      }
    );
  }

  itemgroup_countoptions(itemgroup_id:number) {
    let total_options = 0;
    this.itemgroup_result.forEach((element: { itemgroup_id: number; }) => {
      if(element.itemgroup_id == itemgroup_id) {
        total_options++;
      }
    });
    return total_options;
  }

  check_openoptionitemgroup() {
    let available_openoptionitemgrounp = 0;
    this.itemgroup_result.forEach((element: { itemgroup_id: number; }) => {
      let itemgroup_countoptions = this.itemgroup_countoptions(element.itemgroup_id);
      if(itemgroup_countoptions == 1) {
        available_openoptionitemgrounp = 1;
        return available_openoptionitemgrounp;
      }
    });
    return available_openoptionitemgrounp;
  }

  get_itemtagimgurl(itemtag_img:any) {
    return this.env.SOURCE_URL+'img/imgstags/'+itemtag_img;
  }

  radioChangeHandler(itemgroup_id:number, itemgroupitem_id:number, itemgroupitem_price:number, itemgroup_multiselectable:number) {
    
    let _itemgroupitem_price = 0; 

    if(itemgroupitem_price == null || itemgroupitem_price == undefined) {
      _itemgroupitem_price = 0;
    } else {
      _itemgroupitem_price = parseFloat(itemgroupitem_price.toString());
    } 

    if(itemgroup_multiselectable == 0) {
      let itemoptionindex_itemoptionshandlerarray = this.ItemOptionsHandlerArray.findIndex((record:any) => record.itemgroup_id == itemgroup_id);
      if(itemoptionindex_itemoptionshandlerarray == -1) {
        let jsonRecord : any = [];
        jsonRecord['itemgroup_id'] = itemgroup_id;
        jsonRecord['itemgroupitem_id'] = itemgroupitem_id;
        jsonRecord['itemgroupitem_price'] = _itemgroupitem_price;
        this.ItemOptionsHandlerArray.push(jsonRecord);
      } else {
        this.ItemOptionsHandlerArray[itemoptionindex_itemoptionshandlerarray]['itemgroupitem_id'] = itemgroupitem_id;
        this.ItemOptionsHandlerArray[itemoptionindex_itemoptionshandlerarray]['itemgroupitem_price'] = _itemgroupitem_price;
      } 

    } else {

      let itemoptionindex_itemoptionshandlerarray = this.ItemOptionsHandlerArray.findIndex((record:any) => record.itemgroup_id == itemgroup_id && record.itemgroupitem_id == itemgroupitem_id);
      if(itemoptionindex_itemoptionshandlerarray == -1) {
        let jsonRecord : any = [];
        jsonRecord['itemgroup_id'] = itemgroup_id;
        jsonRecord['itemgroupitem_id'] = itemgroupitem_id;
        jsonRecord['itemgroupitem_price'] = itemgroupitem_price;
        this.ItemOptionsHandlerArray.push(jsonRecord);
      } else {
        this.ItemOptionsHandlerArray.splice(itemoptionindex_itemoptionshandlerarray, 1);

        // this.ItemOptionsHandlerArray[itemoptionindex_itemoptionshandlerarray]['itemgroup_id'] = itemgroup_id;
        // this.ItemOptionsHandlerArray[itemoptionindex_itemoptionshandlerarray]['itemgroupitem_id'] = itemgroupitem_id;
        // this.ItemOptionsHandlerArray[itemoptionindex_itemoptionshandlerarray]['itemgroupitem_price'] = itemgroupitem_price;
      }
    }

    console.log( this.ItemOptionsHandlerArray );

  }

  validatecheck_value(itemgroup_id:number, itemgroupitem_id:number) {
    let itemoptionindex_itemoptionshandlerarray = this.ItemOptionsHandlerArray.findIndex((record:any) => record.itemgroup_id == itemgroup_id && record.itemgroupitem_id == itemgroupitem_id);
    if(itemoptionindex_itemoptionshandlerarray == -1) {
      return false;
    } else {
      return true;
    }

    /*
    if(itemgroup_multiselectable == 0) {
      let itemoptionindex_itemoptionshandlerarray = this.ItemOptionsHandlerArray.findIndex((record:any) => record.itemgroup_id == itemgroup_id);
      if(itemoptionindex_itemoptionshandlerarray == -1) {
        return false;
      } else {
        return true;
      }

    } else {
    }
    */
  }

  calculated_itemprice() {
    let iPrice:number = 0;
    if(this.item_result !== undefined) {
      iPrice += parseFloat(this.item_result.item_rate);
    }
    this.ItemOptionsHandlerArray.forEach((element:any) => {

      if(element['itemgroupitem_price'] && element['itemgroupitem_price'] != null && element['itemgroupitem_price'] != undefined) {
        iPrice += parseFloat(element['itemgroupitem_price']);
      }
      
      

    });
    return (iPrice * this.qty);

    // let iPrice:number = 0;
    // if(this.item_result !== undefined) {
    //   iPrice += parseFloat(this.item_result.item_rate);
    // }
    // this.radioChangeHandlerPriceArray.forEach(element => {
    //   iPrice += parseFloat(element);
    // });
    // return (iPrice * this.qty);
  }

  setup_quotationdresult() {

    this.qty = parseFloat(this.quotationd_result['qty']);

    if(this.quotationd_result['quotationd_instruction']) { this.quotationd_instruction = this.quotationd_result['quotationd_instruction']; }
    if(this.quotationd_result['quotationd_instructionaction'] == 1) { this.quotationd_actiontype = parseInt(this.quotationd_result['quotationd_actiontype']); }

    this.ItemOptionsHandlerArray = [];
    if(this.quotationdspecifications_result) {
      this.quotationdspecifications_result.forEach((specification:any) => {
        let jsonRecord : any = [];
        jsonRecord['itemgroup_id'] = parseFloat(specification.itemgroup_id);
        jsonRecord['itemgroupitem_id'] = parseFloat(specification.quotationdgitem_value);
        jsonRecord['itemgroupitem_price'] = parseFloat(specification.netprice);
        this.ItemOptionsHandlerArray.push(jsonRecord);
      });
    } 
  }


}
