import { Component, OnInit } from '@angular/core';
import { IonRouterOutlet, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';
import { CallNumber } from '@ionic-native/call-number/ngx';
import { LangService } from 'src/app/services/lang.service';
import { HttpClient } from '@angular/common/http';
import { EnvService } from 'src/app/services/env.service';
import { timeout } from 'rxjs/operators';

@Component({
  selector: 'app-nmobile',
  templateUrl: './nmobile.page.html',
  styleUrls: ['../../general/ngeneral.scss', './nmobile.page.scss'],
})
export class NmobilePage implements OnInit {

  active_logintype: any = 'email';
  action_customaction : any = '';

  status_successfulloggedin : number = 0;
  active_referencecodeblock : number = 0;
  status_referralcodeupdated : number = 0;
  
  active_loading : number = 0;
  active_addpin: number = 0;
  nuser_found: number = 0;
  active_referralcodeinputblock : number = 0;
  
  muser_referencecode: string = '';
  muser_referencecodemsgerror: string = '';

  muser_email: string;
  muser_pin: string;
  muser_exit: number = 0;

  muser_mobile: string;
  muser_fmobile: string;

  device_cordova: string;
  device_model: string;
  device_platform: string;
  device_uuid: string;
  device_version: string;
  device_manufacturer: string;
  device_isVirtual: boolean;
  device_serial: string;


  constructor(
      public app: AppComponent,
      private generalService: GeneralService,
      private alertService: AlertService,
      private storage: Storage,
      private modalController: ModalController,
      private callnumber: CallNumber,
      public lang: LangService,
      private http: HttpClient,
      private env: EnvService)
    {
      
    }

  async ngOnInit() {    

    // alert( this.device_serial );
    // alert( 'Device UUID is: ' + this.device.uuid );

    this.app.setupStorageVariables();
  }

  add() {
    this.active_loading = 1;
    if(this.get_logintype() == 'mobile') {

      const formData = new FormData();
      formData.append('add', '1');

      formData.append('country_id', '167');
      formData.append('country_name', 'Pakistan');
      formData.append('country_iso2', 'PK');
      formData.append('country_calingcode', '92');
      formData.append('muser_mobile', this.muser_mobile);

      formData.append('lang', this.lang.current_lang);
      this.http.post(this.env.API_URL+'mobilemuser', formData)
      .pipe(
        timeout(15000)
      )
      .subscribe(
        (response: Response) => {

          this.active_loading = 0;
          if(response['muser_fmobile']) {
            this.active_addpin = 1;
            this.muser_fmobile = response['muser_fmobile'];
            this.nuser_found = response['nuser_found'];
            this.muser_referencecode = '';
          }

        },  
        async (error) => {
          this.active_loading = 0;
          console.log( error );
          if (error.name == "TimeoutError") {
            this.alertService.error('', this.lang.v.general_connection_time_out_message);  
          } else {
            if( error.error.messagedetail == undefined ) {
              this.alertService.error('', this.lang.v.Error_to_connect);
            } else {
              this.alertService.error('', error.error.messagedetail);
            }
          }          
        }  
      );

    } else {
      const formData = new FormData();
      formData.append('add', '1');
      formData.append('muser_email', this.muser_email);
      formData.append('country_id', this.app.selected_country_id.toString());
      formData.append('lang', this.lang.current_lang);
      this.http.post(this.env.API_URL+'muser', formData)
      .pipe(
        timeout(15000)
      )
      .subscribe(
        (response: Response) => {
          this.active_loading = 0;
          if(response['muser_email']) {
            this.active_addpin = 1;
            this.muser_email = response['muser_email'];
            this.nuser_found = response['nuser_found'];
            this.muser_referencecode = '';
          }          
        },  
        async (error) => {
          this.active_loading = 0;
          console.log( error );
          if (error.name == "TimeoutError") {
            this.alertService.error('', this.lang.v.general_connection_time_out_message);  
          } else {
            if( error.error.messagedetail == undefined ) {
              this.alertService.error('', this.lang.v.Error_to_connect);
            } else {
              this.alertService.error('', error.error.messagedetail);
            }
          }          
        }  
      );
    }
  }

  change() {
    this.active_addpin = 0;
  }

  resend_pin(_genenrate:any) {

    if(this.get_logintype() == 'mobile') {


      this.active_loading = 1;

      const formData = new FormData();
      formData.append('resendcode', '1');
      formData.append('generate', _genenrate);

      formData.append('muser_fmobile', this.muser_fmobile);

      formData.append('country_id', this.app.selected_country_id.toString());
      formData.append('lang', this.lang.current_lang);

      this.http.post(this.env.API_URL+'mobilemuser', formData)
      .pipe(
        timeout(15000)
      )
      .subscribe(
        (response: Response) => {

          this.active_loading = 0;
          console.log( response );
          if(response['muser_fmobile']) {
            this.active_addpin = 1;
            this.muser_fmobile = response['muser_fmobile'];
            this.alertService.success('', this.lang.v.Mobile_verification_code_successfully_sent);
          }

        },  
        async (error) => {
          this.active_loading = 0;
          console.log( error );
          if (error.name == "TimeoutError") {
            this.alertService.error('', this.lang.v.general_connection_time_out_message);  
          } else {
            if( error.error.messagedetail == undefined ) {
              this.alertService.error('', this.lang.v.Error_to_connect);
            } else {
              this.alertService.error('', error.error.messagedetail);
            }
          }          
        }  
      );

    } 

    if(this.get_logintype() == 'email') {
      this.active_loading = 1;

      const formData = new FormData();
      formData.append('add', '1');
      formData.append('generate', _genenrate);

      formData.append('muser_email', this.muser_email);
      formData.append('country_id', this.app.selected_country_id.toString());
      formData.append('lang', this.lang.current_lang);

      this.http.post(this.env.API_URL+'muser', formData)
      .pipe(
        timeout(15000)
      )
      .subscribe(
        (response: Response) => {

          this.active_loading = 0;
          console.log( response );
          if(response['muser_email']) {
            this.active_addpin = 1;
            this.muser_email = response['muser_email'];
            this.alertService.success('', this.lang.v.Check_your_email);  
          } 

        },  
        async (error) => {
          this.active_loading = 0;
          console.log( error );
          if (error.name == "TimeoutError") {
            this.alertService.error('', this.lang.v.general_connection_time_out_message);  
          } else {
            if( error.error.messagedetail == undefined ) {
              this.alertService.error('', this.lang.v.Error_to_connect);
            } else {
              this.alertService.error('', error.error.messagedetail);
            }
          }          
        }  
      );
    }
  }

  closeModel() {
    this.modalController.dismiss({'status' : this.active_referralcodeinputblock});
  }

  _clicktodial(number:any) {
    this.callnumber.callNumber(number, true)
  .then(res => console.log('Launched dialer!', res))
  .catch(err => console.log('Error launching dialer', err));
  }

  open_referralcodeinput() {
    this.active_referralcodeinputblock = 1;
  }

  update_referencecode() { 
    this.active_loading = 1;
    this.status_referralcodeupdated = 0;
    const formData = new FormData();
    formData.append('without_branch', this.app.selected_signature.toString());
    formData.append('signature', this.app.selected_signature.toString());
    formData.append('pointer_latitude', this.app.mylocation_latitude.toString());
    formData.append('pointer_longitude', this.app.mylocation_longitude.toString());
    formData.append('mylocation_name', this.app.mylocation_name.toString());

    formData.append('muser_validation', '1');
    formData.append('muser_id', this.app.muser_result.muser_id.toString());
    formData.append('muserlsignature_signature', this.app.muserlsignature_signature.toString());

    formData.append('muser_updatereferencecode', '1');
    formData.append('muser_referencecode', this.muser_referencecode);

    formData.append('lang', this.lang.current_lang);

    this.http.post(this.env.API_URL+'shop', formData).subscribe(
      () => {
        this.active_loading = 0;
        this.status_referralcodeupdated = 1;
        setTimeout(() => this.closeModel(), 1000);

      },
      error => {
        this.active_loading = 0;
        this.status_referralcodeupdated = 0;
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
            
            this.alertService.error('', this.lang.v.Error_to_connect);
        } else {
            //
            this.alertService.error('', error.error.messagedetail);
            this.muser_referencecodemsgerror = error.error.messagedetail;
        }
      }
    );
  }

  get_logintype() {
    if(this.active_logintype == 'mobile') {
      if(this.app.selected_country_id == 167) {
        return 'mobile';
      } else {
        return 'email';
      }
    } else {
      return 'email';
    }    
  }



  login() {

    if(this.get_logintype() == 'mobile') {


      this.active_loading = 1;

      const formData = new FormData();
      formData.append('login', '1');

      formData.append('muser_fmobile', this.muser_fmobile);
      formData.append('muser_pin', this.muser_pin); 
      
      formData.append('notification_token', this.app.device_fcmtoken);
      formData.append('notification_tokentype', this.app.device_fcmtokentype);
      formData.append('muserlsignature_device', this.app.selected_devicename); 

      formData.append('country_id', this.app.selected_country_id.toString());
      formData.append('lang', this.lang.current_lang);

      this.http.post(this.env.API_URL+'mobilemuser', formData) 
      .pipe(
        timeout(15000)
      )
      .subscribe(
        (response: Response) => {

          this.storage.set('muser_result', response['muser_result']);
          this.storage.set('muserlsignature_signature', response['muserlsignature_signature']).then(() => {
            this.app.setupStorageVariables();
            this.active_loading = 0;
            this.status_successfulloggedin = 1;

            let response_active_referencecodeblock = 0;
            if(response['active_referencecodeblock']) {
              response_active_referencecodeblock = parseInt(response['active_referencecodeblock']);
            } 

            if(response_active_referencecodeblock == 1) {
              this.active_referencecodeblock = 1;
            } else {
              //this.alertService.success('', this.lang.v.Successfully_logged_in);
              this.closeModel();              
            }                        
          });

        },  
        async (error) => {
          this.active_loading = 0;
          console.log( error );
          if (error.name == "TimeoutError") {
            this.alertService.error('', this.lang.v.general_connection_time_out_message);  
          } else {
            if( error.error.messagedetail == undefined ) {
              this.alertService.error('', this.lang.v.Error_to_connect);
            } else {
              this.alertService.error('', error.error.messagedetail);
            }
          }          
        }  
      );





      /*
      this.generalService.action_login_mobilemuser(this.app.device_fcmtoken, this.muser_fmobile, this.muser_pin, this.muser_referencecode, this.lang.current_lang).subscribe(
        getResponse => {
          this.storage.set('muser_result', getResponse['muser_result']);
          this.storage.set('muserlsignature_signature', getResponse['muserlsignature_signature']).then(()=>{
            this.app.setupStorageVariables();

            this.active_loading = 0;
            this.status_successfulloggedin = 1;
            if(this.action_customaction == 'disablerefercode') {
              this.closeModel(); 
            } else {
              let response_active_referencecodeblock = 0;
              if(getResponse['active_referencecodeblock']) {
                response_active_referencecodeblock = parseInt(getResponse['active_referencecodeblock']);
              }
  
              if(response_active_referencecodeblock == 1) {
                this.active_referencecodeblock = 1;
              } else {
                //this.alertService.success('', this.lang.v.Successfully_logged_in);
                this.closeModel();              
              }


            }
            
          });

        },
        error => {
          this.active_loading = 0;
          console.log( error );
          console.log( error.error );
          if( error.error.messagedetail == undefined ) {
              
            this.alertService.error('', this.lang.v.Error_to_connect);
          } else {
              
            this.alertService.error('', error.error.messagedetail);
          }
        }
      ); 
      */
     
      

    } 

    if(this.get_logintype() == 'email') { 

      this.active_loading = 1; 

      const formData = new FormData(); 
      formData.append('login', '1');

      formData.append('muser_email', this.muser_email);
      formData.append('muser_pin', this.muser_pin); 
      
      formData.append('notification_token', this.app.device_fcmtoken);
      formData.append('notification_tokentype', this.app.device_fcmtokentype);
      formData.append('muserlsignature_device', this.app.selected_devicename); 

      formData.append('country_id', this.app.selected_country_id.toString());
      formData.append('lang', this.lang.current_lang);

      this.http.post(this.env.API_URL+'muser', formData)
      .pipe(
        timeout(15000)
      )
      .subscribe(
        (response: Response) => {

          this.storage.set('muser_result', response['muser_result']);
          this.storage.set('muserlsignature_signature', response['muserlsignature_signature']).then(() => {
            this.app.setupStorageVariables();
            this.active_loading = 0;
            this.status_successfulloggedin = 1;

            let response_active_referencecodeblock = 0;
            if(response['active_referencecodeblock']) {
              response_active_referencecodeblock = parseInt(response['active_referencecodeblock']);
            } 

            if(response_active_referencecodeblock == 1) {
              this.active_referencecodeblock = 1;
            } else {
              //this.alertService.success('', this.lang.v.Successfully_logged_in);
              this.closeModel();              
            }                        
          });

        },  
        async (error) => {
          this.active_loading = 0;
          console.log( error );
          if (error.name == "TimeoutError") {
            this.alertService.error('', this.lang.v.general_connection_time_out_message);  
          } else {
            if( error.error.messagedetail == undefined ) {
              this.alertService.error('', this.lang.v.Error_to_connect);
            } else {
              this.alertService.error('', error.error.messagedetail);
            }
          }          
        }  
      );

    }
  } 

}