import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/services/alert.service';
import { EnvService } from 'src/app/services/env.service';
import { GeneralService } from 'src/app/services/general.service';
import { LangService } from 'src/app/services/lang.service';
import { WalkingcustomerappService } from 'src/app/services/walkingcustomerapp.service';

@Component({
  selector: 'app-wcshopniteminstruction',
  templateUrl: './wcshopniteminstruction.page.html',
  styleUrls: ['../../../general/ngeneral.scss', './wcshopniteminstruction.page.scss'],
})
export class WcshopniteminstructionPage implements OnInit {

  item_id : number;
  item_name : string;
  iteminstruction_result : any = '';
  
  constructor(private loadingController:LoadingController,
    private generalService: GeneralService,
    private alertService: AlertService,
    private storage: Storage,
    private modalController:ModalController,
    public app: AppComponent,
    public env: EnvService,
    private http: HttpClient,
    public lang: LangService,
    public walkingcustomerapp: WalkingcustomerappService) { }

    async ngOnInit() {
      await this.storage.get('walkingcustomerapp_signature').then(() => {
        this.walkingcustomerapp.setupStorageVariables();
        this.setup();
      });
    }

    async setup() {
      const loading = await this.loadingController.create({
        cssClass: 'loadingScreen',
        spinner: null,
        translucent: true,
        showBackdrop: false,
        message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
      });
      loading.present();
  
      const formData = new FormData();

      formData.append('branch_id', this.walkingcustomerapp.walkingcustomerapp_branchresult['branch_id']);

      formData.append('active_walkingcustomerapp', '1');
      formData.append('config_walkingcustomerappsignature', '1');
      formData.append('walkingcustomerapp_signature', this.walkingcustomerapp.walkingcustomerapp_signature);
  

      formData.append('item_id', this.item_id.toString());
      formData.append('call_iteminstruction', '1');
      formData.append('lang', this.lang.current_lang);
  
      this.http.post(this.app.env.API_URL+'shop', formData).subscribe(
        (response: Response) => {
          // console.log("response");
          // console.log(response);
          if(response['iteminstruction_result']) {
            this.iteminstruction_result = response['iteminstruction_result'];
          }
          if(response['item_result']) {
            this.item_name = response['item_result']['item_name'];
          }
          loading.dismiss();
        },
        error => {
          loading.dismiss();
          console.log( error );
          console.log( error.error );
          if( error.error.messagedetail == undefined ) {
              this.alertService.error('', this.lang.v.Error_to_connect);
          } else {
              
            this.alertService.error('', error.error.messagedetail);
          }
        }
      );
    }

}
