import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { VehicleLocationPageRoutingModule } from './vehicle-location-routing.module';

import { VehicleLocationPage } from './vehicle-location.page';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    VehicleLocationPageRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD_J6ltB_3ZzYnRq03p_FkUAKKBDv6QjXs',
      /* apiKey is required, unless you are a 
      premium customer, in which case you can 
      use clientId 
      */
     libraries: ['geometry', 'places']
    })

  ],
  declarations: [VehicleLocationPage]
})
export class VehicleLocationPageModule {}
