import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SelectvehiclePageRoutingModule } from './selectvehicle-routing.module';

import { SelectvehiclePage } from './selectvehicle.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SelectvehiclePageRoutingModule
  ],
  declarations: [SelectvehiclePage]
})
export class SelectvehiclePageModule {}
