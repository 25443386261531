import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/services/alert.service';
import { EnvService } from 'src/app/services/env.service';
import { GeneralService } from 'src/app/services/general.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-selectvehiclecolor',
  templateUrl: './selectvehiclecolor.page.html',
  styleUrls: ['../../general/ngeneral.scss', './selectvehiclecolor.page.scss'],
})
export class SelectvehiclecolorPage implements OnInit {

  appvehiclecolors_list:any;
  othervehicle_color:string = '';

  constructor(private loadingController:LoadingController,
    private generalService: GeneralService,
    private alertService: AlertService,
    private storage: Storage,
    private modalController:ModalController,
    public app: AppComponent,
    public env: EnvService,
    private http: HttpClient,
    public lang: LangService) { }

    ngOnInit() {
      this.storage.get('saved_locationslist').then(() => {
        this.setup();
      });
    }
  
    async setup() {
      const loading = await this.loadingController.create({
        cssClass: 'loadingScreen',
        spinner: null,
        translucent: true,
        showBackdrop: false,
        message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
      });
      loading.present();
      const formData = new FormData();
      formData.append('lgcity_id', this.app.selected_lgcity_id.toString());
      formData.append('pointer_latitude', this.app.mylocation_latitude.toString());
      formData.append('pointer_longitude', this.app.mylocation_longitude.toString());
      formData.append('call_appcolorslist', '1');      
      
      this.http.post(this.env.API_URL+'setup', formData).subscribe(
        (response: Response) => {
          console.log(' response ');
          console.log( response );
          if(response['appvehiclecolors_list']) {
            this.appvehiclecolors_list = response['appvehiclecolors_list'];
          }
          loading.dismiss();
        },
        error => {
          loading.dismiss();
          console.log( error );
          console.log( error.error );
          if( error.error.messagedetail == undefined ) {
              
            this.alertService.error('', this.lang.v.Error_to_connect);
          }
          else {
              
            this.alertService.error('', error.error.messagedetail);
          }
        }
      );
    }


    get_appcolorname(data:any, lang:string) {
      if(lang == 'ar') {
        return data.appvehiclecolor_name_ar;
  
      } else if(lang == 'no') {
        return data.appvehiclecolor_name_no;
  
      } else if(lang == 'es') {
        return data.appvehiclecolor_name_es;
  
      } else if(lang == 'ur') {
        return data.appvehiclecolor_name_ur;
  
      } else {
        return data.appvehiclecolor_name_en;
  
      }
    }

    get_otherinputvehicle() {
      if(this.othervehicle_color) {
        this.select_appcolorname(this.othervehicle_color);
      } else {
        this.alertService.error('', this.lang.v.Error);
      }
    }

    select_appcolorname(vName:string) {
      this.modalController.dismiss([vName]);
    }

}
