import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { WcshopniteminstructionPage } from './wcshopniteminstruction.page';

const routes: Routes = [
  {
    path: '',
    component: WcshopniteminstructionPage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class WcshopniteminstructionPageRoutingModule {}
