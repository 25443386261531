import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, UrlSegment } from '@angular/router';

export function validatewalkingcustomerapp_homewithlang(url: UrlSegment[]) {
  if (url.length == 4) {
    const lang = url[0].path;
    const vcategory = url[1].path;
     if(
      (lang ==  'en'|| lang == 'ar' || lang ==  'no' || lang == 'es' || lang == 'ur')
        && 
      (vcategory == 'restaurant' || vcategory == 'homechef' || vcategory == 'shop' || vcategory == 'hawkers')
      ) { return { consumed: url }; }
  }
  return null;
} 

export function validatewalkingcustomerapp_homewithoutlang(url: UrlSegment[]) {
  if (url.length == 3) {
    const vcategory = url[0].path;
     if(
      (vcategory == 'restaurant' || vcategory == 'homechef' || vcategory == 'shop' || vcategory == 'hawkers')
      ) { return { consumed: url }; }
  }
  return null;
} 

export function validatewalkingcustomerapp_cartwithlang(url: UrlSegment[]) {
  if (url.length == 5) {
    const lang = url[0].path;
    const vcategory = url[1].path;
    const page = url[4].path;
     if(
      (lang ==  'en'|| lang == 'ar' || lang ==  'no' || lang == 'es' || lang == 'ur')
      && 
      (vcategory == 'restaurant' || vcategory == 'homechef' || vcategory == 'shop' || vcategory == 'hawkers')
      && 
      (page == 'cart')      
      ) { return { consumed: url }; }
  }
  return null;
}

export function validatewalkingcustomerapp_cartwithoutlang(url: UrlSegment[]) {
  if (url.length == 4) {
    const vcategory = url[0].path;
    const page = url[3].path;

     if(
       ((vcategory == 'restaurant' || vcategory == 'homechef' || vcategory == 'shop' || vcategory == 'hawkers'))
       && 
       (page == 'cart')
       ) {
       return { consumed: url };
      }
  }
  return null;
}


export function validatewalkingcustomerapp_termswithlang(url: UrlSegment[]) {
  if (url.length == 5) {
    const lang = url[0].path;
    const vcategory = url[1].path;
    const page = url[4].path;
     if(
      (lang ==  'en'|| lang == 'ar' || lang ==  'no' || lang == 'es' || lang == 'ur')
      && 
      (vcategory == 'restaurant' || vcategory == 'homechef' || vcategory == 'shop' || vcategory == 'hawkers')
      && 
      (page == 'terms')      
      ) { return { consumed: url }; }
  }
  return null;
}

export function validatewalkingcustomerapp_termswithoutlang(url: UrlSegment[]) {
  if (url.length == 4) {
    const vcategory = url[0].path;
    const page = url[3].path;

     if(
       ((vcategory == 'restaurant' || vcategory == 'homechef' || vcategory == 'shop' || vcategory == 'hawkers'))
       && 
       (page == 'terms')
       ) {
       return { consumed: url };
      }
  }
  return null;
}


export function validatewalkingcustomerapp_policywithlang(url: UrlSegment[]) {
  if (url.length == 5) {
    const lang = url[0].path;
    const vcategory = url[1].path;
    const page = url[4].path;
     if(
      (lang ==  'en'|| lang == 'ar' || lang ==  'no' || lang == 'es' || lang == 'ur')
      && 
      (vcategory == 'restaurant' || vcategory == 'homechef' || vcategory == 'shop' || vcategory == 'hawkers')
      && 
      (page == 'policy')      
      ) { return { consumed: url }; }
  }
  return null;
}

export function validatewalkingcustomerapp_policywithoutlang(url: UrlSegment[]) {
  if (url.length == 4) {
    const vcategory = url[0].path;
    const page = url[3].path;

     if(
       ((vcategory == 'restaurant' || vcategory == 'homechef' || vcategory == 'shop' || vcategory == 'hawkers'))
       && 
       (page == 'policy')
       ) {
       return { consumed: url };
      }
  }
  return null;
}


export function validatewalkingcustomerapp_orderwithlang(url: UrlSegment[]) {
  if (url.length == 5) {
    const lang = url[0].path;
    const vcategory = url[1].path;
    const page = url[4].path;
     if(
      (lang ==  'en'|| lang == 'ar' || lang ==  'no' || lang == 'es' || lang == 'ur')
      && 
      (vcategory == 'restaurant' || vcategory == 'homechef' || vcategory == 'shop' || vcategory == 'hawkers')
      && 
      (page == 'orders')      
      ) { return { consumed: url }; }
  }
  return null;
}

export function validatewalkingcustomerapp_orderwithoutlang(url: UrlSegment[]) {
  if (url.length == 4) {
    const vcategory = url[0].path;
    const page = url[3].path;

     if(
       ((vcategory == 'restaurant' || vcategory == 'homechef' || vcategory == 'shop' || vcategory == 'hawkers'))
       && 
       (page == 'orders')
       ) {
       return { consumed: url };
      }
  }
  return null;
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'general',
    pathMatch: 'full'
  },
  {
    path: 'folder/:id',
    loadChildren: () => import('./folder/folder.module').then( m => m.FolderPageModule)
  },
  {
    matcher: validatewalkingcustomerapp_homewithlang,
    loadChildren: () => import('./walkingcustomerapp/pages/home/home.module').then( m => m.HomePageModule)
  }, 
  {
    matcher: validatewalkingcustomerapp_homewithoutlang,
    loadChildren: () => import('./walkingcustomerapp/pages/home/home.module').then( m => m.HomePageModule)
  }, 
  {
    matcher: validatewalkingcustomerapp_cartwithoutlang,
    loadChildren: () => import('./walkingcustomerapp/pages/wccart/wccart.module').then( m => m.WccartPageModule)
  },
  {
    matcher: validatewalkingcustomerapp_cartwithlang,
    loadChildren: () => import('./walkingcustomerapp/pages/wccart/wccart.module').then( m => m.WccartPageModule)
  },

  {
    matcher: validatewalkingcustomerapp_termswithoutlang,
    loadChildren: () => import('./walkingcustomerapp/pages/wcterms/wcterms.module').then( m => m.WctermsPageModule)
  },
  {
    matcher: validatewalkingcustomerapp_termswithlang,
    loadChildren: () => import('./walkingcustomerapp/pages/wcterms/wcterms.module').then( m => m.WctermsPageModule)
  },
  {
    matcher: validatewalkingcustomerapp_policywithoutlang,
    loadChildren: () => import('./walkingcustomerapp/pages/wcpolicy/wcpolicy.module').then( m => m.WcpolicyPageModule)
  },
  {
    matcher: validatewalkingcustomerapp_policywithlang,
    loadChildren: () => import('./walkingcustomerapp/pages/wcpolicy/wcpolicy.module').then( m => m.WcpolicyPageModule)
  },
  {
    matcher: validatewalkingcustomerapp_orderwithoutlang,
    loadChildren: () => import('./walkingcustomerapp/pages/wcorders/wcorders.module').then( m => m.WcordersPageModule)
  },
  {
    matcher: validatewalkingcustomerapp_orderwithlang,    
    loadChildren: () => import('./walkingcustomerapp/pages/wcorders/wcorders.module').then( m => m.WcordersPageModule)
  },
  {
    path: 'general',
    loadChildren: () => import('./pages/general/general.module').then( m => m.GeneralPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
