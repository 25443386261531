import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { SreviewsPageRoutingModule } from './sreviews-routing.module';

import { SreviewsPage } from './sreviews.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SreviewsPageRoutingModule
  ],
  declarations: [SreviewsPage]
})
export class SreviewsPageModule {}
