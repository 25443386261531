import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class WalkingcustomerappService {

  walkingcustomerapp_signature : string;
  walkingcustomerapp_branchresult : any;
  walkingcustomerapp_cartitems_list : any = [];
  quotation_deliverytype : number = 3;

  constructor(
    private storage: Storage,
    private app: AppComponent
    ) { this.setupStorageVariables(); }
  
  setupStorageVariables() {
    //console.log(' direct order setupStorageVariables ');
    this.storage.get('walkingcustomerapp_signature').then((data) => {
      if(data !== null && data !== undefined) {
        this.walkingcustomerapp_signature = data;        

      } else {
        this.walkingcustomerapp_signature = '';
      }
    });

    this.storage.get('walkingcustomerapp_branchresult').then((data) => {
      if(data !== null && data !== undefined) {
        this.walkingcustomerapp_branchresult = data;        

      } else {
        this.walkingcustomerapp_branchresult = '';
      }
    });


    this.storage.get('walkingcustomerapp_cartitems_list').then((data) => {
      if(data !== null && data !== undefined) {
        this.walkingcustomerapp_cartitems_list = data;        

      } else {
        this.walkingcustomerapp_cartitems_list = [];
      }
    });
  }

  calculate_totalcartitems() {
    let cart_items:number = 0;
    for (let index = 0; index < this.walkingcustomerapp_cartitems_list.length; index++) {
      cart_items += parseFloat(this.walkingcustomerapp_cartitems_list[index]['qty']);
    }
    return cart_items;
  }

  calculate_totalcartamount() {
    let sum = 0;
    for (let index = 0; index < this.walkingcustomerapp_cartitems_list.length; index++) {
      sum += parseFloat(this.walkingcustomerapp_cartitems_list[index]['fnetprice']);
    }
    return sum;
  }

}
