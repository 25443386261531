import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeChangeOrderFunction = new EventEmitter;
  oSubsVar: Subscription;

  invokeFirstComponentFunction = new EventEmitter;
  subsVar: Subscription;
    

  invokeNewChatFunction = new EventEmitter;
  ncSubsVar: Subscription;

  constructor() { }    
    
  onFirstComponentButtonClick(data:any) {
    // console.log('onFirstComponentButtonClick');
    this.invokeFirstComponentFunction.emit(data);
  }

  call_invokeChangeOrderFunction(data:any) {
    this.invokeChangeOrderFunction.emit(data);
  }

  call_invokeNewChatFunction(data:any) {
    this.invokeNewChatFunction.emit(data);
  }

}  
