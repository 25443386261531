import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { NmobilePageRoutingModule } from './nmobile-routing.module';
import { NmobilePage } from './nmobile.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    NmobilePageRoutingModule
  ],
  declarations: [NmobilePage]
})
export class NmobilePageModule {}
