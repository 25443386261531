import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/services/alert.service';
import { EnvService } from 'src/app/services/env.service';
import { HelperService } from 'src/app/services/helper.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-sreviews',
  templateUrl: './sreviews.page.html',
  styleUrls: ['../../general/ngeneral.scss', '../../general/theme.scss', './sreviews.page.scss'],
})
export class SreviewsPage implements OnInit {

  branch_id: number;
  cmp_id: number;

  branch_result: any;
  vtiming_result: any;
  sreviews_list: any;

  branch_maplink: string;

  constructor(
    private storage: Storage,
    private loadingController: LoadingController,
    public app: AppComponent,
    private http: HttpClient,
    public env: EnvService,
    private alertService: AlertService, 
    public helper: HelperService,
    public lang: LangService,
    private modalController: ModalController) { }

  ngOnInit() {
    this.storage.get('selected_signature').then((data) => {
      this.app.setupStorageVariables();
      this.callsetup();
    });
  }

  async callsetup() {
    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
    });
    loading.present();

    const formData = new FormData();
    formData.append('cmp_id', this.cmp_id.toString());
    formData.append('branch_id', this.branch_id.toString());
    formData.append('signature', this.app.selected_signature.toString());


    if(this.app.mylocation_latitude && this.app.mylocation_longitude) {
      formData.append('pointer_latitude', this.app.mylocation_latitude.toString());
      formData.append('pointer_longitude', this.app.mylocation_longitude.toString());  
    } else {
      formData.append('pointer_latitude', 'no-location');
      formData.append('pointer_longitude', 'no-location');
    }

    formData.append('getreviews', '1');
    formData.append('lang', this.lang.current_lang);

    this.http.post(this.env.API_URL+'shop', formData).subscribe(
      (response: Response) => {
        console.log(response);
        this.branch_result = response['branch_result'];
        this.vtiming_result = response['vtiming_result'];
        this.sreviews_list = response['sreviews_list'];
        this.branch_maplink = 'https://maps.google.com/?q='+this.branch_result.branch_lat+','+this.branch_result.branch_lag;
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        this.branch_result = undefined;
        this.sreviews_list = undefined;
        this.vtiming_result = undefined;
        
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
            this.alertService.error('', this.lang.v.Error_to_connect);
        } else {
            
            this.alertService.error('', error.error.messagedetail);
        }

      }
    );
  }

  back() {
    this.modalController.dismiss();
  }

}
