import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ItemalertmessagePageRoutingModule } from './itemalertmessage-routing.module';

import { ItemalertmessagePage } from './itemalertmessage.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ItemalertmessagePageRoutingModule
  ],
  declarations: [ItemalertmessagePage]
})
export class ItemalertmessagePageModule {}
