// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  
  production: false,
  firebase: {
    apiKey: "AIzaSyDyMOMwMtoFUkHPT7ghquPap5nEWv9y3YI",
    authDomain: "foodsted-5a614.firebaseapp.com",
    projectId: "foodsted-5a614",
    storageBucket: "foodsted-5a614.appspot.com",
    messagingSenderId: "311127017290",
    appId: "1:311127017290:web:e517670330dd57d899e3b3",
    measurementId: "G-GW8ZL4WMLB"
  },

};