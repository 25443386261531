import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SelectvehiclePage } from './selectvehicle.page';

const routes: Routes = [
  {
    path: '',
    component: SelectvehiclePage
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SelectvehiclePageRoutingModule {}
