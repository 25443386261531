import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { WcshopnitemorderoptionPageRoutingModule } from './wcshopnitemorderoption-routing.module';
import { WcshopnitemorderoptionPage } from './wcshopnitemorderoption.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    WcshopnitemorderoptionPageRoutingModule
  ],
  declarations: [WcshopnitemorderoptionPage]
})
export class WcshopnitemorderoptionPageModule {}
