import { Component, OnInit } from '@angular/core';
import { ModalController, PickerController } from '@ionic/angular';
import { PickerOptions } from "@ionic/core";
import { Storage } from '@ionic/storage';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-vehiclenumberplate',
  templateUrl: './vehiclenumberplate.page.html',
  styleUrls: ['../../general/ngeneral.scss', './vehiclenumberplate.page.scss'],
})
export class VehiclenumberplatePage implements OnInit {

  _numberplate_idigit:number = -1;
  _numberplate_iidigit:number = -1;
  _numberplate_iiidigit:number = -1;
  _numberplate_ivdigit:number = -1;
  _numberplate_vdigit:number = -1;

  quotation_vehiclenumber:any;

  constructor(
    public lang: LangService,
    private storage: Storage,
    private pickerController: PickerController,
    private modalController: ModalController
  ) { }

  ngOnInit() {
    if(this.quotation_vehiclenumber != '' && this.quotation_vehiclenumber != undefined && this.quotation_vehiclenumber != null) {
      let quotation_vehiclenumber = String(this.quotation_vehiclenumber);
      // console.log(quotation_vehiclenumber);
      // console.log(quotation_vehiclenumber.length);

      let countCharacter = quotation_vehiclenumber.length;
      let xCountCharacter = countCharacter;

      for (let index = 0; index < xCountCharacter; index++) {
        const sValue = parseInt(quotation_vehiclenumber[index]);
        
        if(countCharacter == 5) { this._numberplate_idigit = sValue; }
        if(countCharacter == 4) { this._numberplate_iidigit = sValue; }
        if(countCharacter == 3) { this._numberplate_iiidigit = sValue; }
        if(countCharacter == 2) { this._numberplate_ivdigit = sValue; }
        if(countCharacter == 1) { this._numberplate_vdigit = sValue; }
    
        countCharacter--;        
      }

    }
    
  }

  async select_numberplatedigit(xcolumnValue:any, dNumber:number) {
    let columnValue: number = parseInt(xcolumnValue);
    let options: PickerOptions = {
      buttons: [
        {
          text: this.lang.v.Cancel,
          role: 'cancel'
        },
        {
          text: this.lang.v.ok,
          handler:(value:any) => {
            // console.log(' value ');
            // console.log( value );
            // console.log( value.numberPlate )
            // console.log( value.numberPlate.value )
            if(dNumber == 1) { this._numberplate_idigit = value.numberPlate.value; }
            if(dNumber == 2) { this._numberplate_iidigit = value.numberPlate.value; }
            if(dNumber == 3) { this._numberplate_iiidigit = value.numberPlate.value; }
            if(dNumber == 4) { this._numberplate_ivdigit = value.numberPlate.value; }
            if(dNumber == 5) { this._numberplate_vdigit = value.numberPlate.value; }
          }
        }
      ],
      columns:[{
        name: 'numberPlate',
        selectedIndex: this.getDeliveryOptionsIndex(columnValue),
        options: this.getDeliveryOptions()
      }]
    };
    let picker = await this.pickerController.create(options);
    picker.present()
  }

  getDeliveryOptionsIndex(columnValue:any) {
    return parseInt(columnValue+1);
  }

  getDeliveryOptions() {
    let options = [];
    for (let index = -1; index < 10; index++) {
      let valueText:any = index;
      if(index ==  -1) {
        valueText = '-';
      }
      options.push({
        text: valueText,
        value: index
      });
    }
    return options;
  }

  getFinalPlateNumber() {
    let idigit:any = '';
    let iidigit:any = '';
    let iiidigit:any = '';
    let ivdigit:any = '';
    let vdigit:any = '';
    if(this._numberplate_idigit != -1) { idigit = this._numberplate_idigit; }
    if(this._numberplate_iidigit != -1) { iidigit = this._numberplate_iidigit; }
    if(this._numberplate_iiidigit != -1) { iiidigit = this._numberplate_iiidigit; }
    if(this._numberplate_ivdigit != -1) { ivdigit = this._numberplate_ivdigit; }
    if(this._numberplate_vdigit != -1) { vdigit = this._numberplate_vdigit; }
    return idigit+''+iidigit+''+iiidigit+''+ivdigit+''+vdigit;
  }

  selectPlateNumber() {
    this.modalController.dismiss([this.getFinalPlateNumber()]);
  }

}
