import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/services/alert.service';
import { EnvService } from 'src/app/services/env.service';
import { GeneralService } from 'src/app/services/general.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-itemalertmessage',
  templateUrl: './itemalertmessage.page.html',
  styleUrls: ['../../general/ngeneral.scss', './itemalertmessage.page.scss'],
})
export class ItemalertmessagePage implements OnInit {

  item:any;

  item_id : any;
  item_name : any;
  itembranch_alert : any;
  itembranch_alertiteminstructionid : any;
  itembranch_alertmessage : any;

  minstruction_content : any;

  constructor(
    private loadingController:LoadingController,
    private generalService: GeneralService,
    private alertService: AlertService,
    private storage: Storage,
    private modalController:ModalController,
    public app: AppComponent,
    public env: EnvService,
    private http: HttpClient,
    public lang: LangService,
    private sanitizer: DomSanitizer
  ) {
  }


  async ngOnInit() {

    this.item_id = this.item.item_id;
    this.item_name = this.item['item_name'];
    this.itembranch_alert = this.item['itembranch_alert'];
    this.itembranch_alertiteminstructionid = this.item['itembranch_alertiteminstructionid'];
    let _itembranch_alertmessage = this.item['itembranch_alertmessage'];  

    this.itembranch_alertmessage = this.sanitizer.bypassSecurityTrustHtml(_itembranch_alertmessage);


    this.app.setupStorageVariables();
    await this.storage.get('selected_branchdata').then(() => {
      this.setup();
    });
  } 

  async setup() {
    if(this.itembranch_alertiteminstructionid != 0) {
      const loading = await this.loadingController.create({
        cssClass: 'loadingScreen',
        spinner: null,
        translucent: true,
        showBackdrop: false,
        message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
      });
      loading.present();
  
      const formData = new FormData();
      formData.append('cmp_id', this.app.active_cmp_id.toString());
      formData.append('branch_id', this.app.active_branch_id.toString());
      formData.append('signature', this.app.selected_signature.toString());
      formData.append('pointer_latitude', this.app.mylocation_latitude.toString());
      formData.append('pointer_longitude', this.app.mylocation_longitude.toString());
  
      formData.append('call_alertiteminstruction', '1');
      formData.append('itembranch_alertiteminstructionid', this.itembranch_alertiteminstructionid);
      
      formData.append('lang', this.lang.current_lang);
  
      this.http.post(this.app.env.API_URL+'shop', formData).subscribe(
        (response: Response) => {
          console.log("response");
          console.log(response);
          if(response['iteminstruction_result']) {
            this.minstruction_content = this.sanitizer.bypassSecurityTrustHtml(response['iteminstruction_result']['minstruction_content']);
          } 
          loading.dismiss();
        },
        error => {
          loading.dismiss();
          console.log( error );
          console.log( error.error );
          if( error.error.messagedetail == undefined ) {
              this.alertService.error('', this.lang.v.Error_to_connect);
          } else {
              
            this.alertService.error('', error.error.messagedetail);
          }
        }
      );  
    }
  }

  close() {
    this.modalController.dismiss()
  }

}
