import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/services/alert.service';
import { GeneralService } from 'src/app/services/general.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-quotationpersons',
  templateUrl: './quotationpersons.page.html',
  styleUrls: ['../../general/ngeneral.scss', './quotationpersons.page.scss'],
})
export class QuotationpersonsPage implements OnInit {

  quotation_persons: number;

  constructor(private loadingController:LoadingController,
    private generalService: GeneralService,
    private alertService: AlertService,
    private modalController:ModalController,
    public lang: LangService,
    public app: AppComponent) { }

  ngOnInit() {
  }

  update_persons(aType:number) {
    if(aType == 1) {
      if(this.quotation_persons < 16) {
        this.quotation_persons++;
      }

    } else {
      if(this.quotation_persons > 2) {
        this.quotation_persons--;
      } else {
        this.quotation_persons = 1;
      }

    }
  }

  close() {
    this.modalController.dismiss(this.quotation_persons);
  }

  finalGuest() {
    this.modalController.dismiss(this.quotation_persons);
  }

}
