import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class HelperService { 

  constructor(
      private platform: Platform,
      private storage: Storage
      ) { }

  customAddress(_fullAddress:string, mylocation_tag:string=undefined) {
    if(mylocation_tag) { return mylocation_tag; }
    let check_validateprefix = _fullAddress.substring(0,12);
    if(check_validateprefix == 'Unnamed Road') {
      var x_fAddress = _fullAddress.split(",");
      return x_fAddress[0]+","+x_fAddress[1];

    } else {
      var x_fAddress = _fullAddress.split(",");
      return x_fAddress[0];
    }
  }

  validateDevice() {
    if(this.platform.is('cordova')) {
      return 1;
    } else {
      return 0;
    }
  }
  
  validateIOSDevice() {
    if(this.platform.is('ios')) {
      return 1;
    } else {
      return 0;
    }
  }

  get_shortName(_BranchName:string) {
    let _SplitArray = _BranchName.split(" ");
    if(_SplitArray.length > 1)
    {
      let _final_i = _SplitArray[0].substring(0, 1);
      let _final_ii = _SplitArray[1].substring(0, 1);
      return _final_i+_final_ii;
    } else {
      return _BranchName.substring(0, 2);
    }
  }

  validateValue(_dValue:any) {
    if(_dValue == undefined || _dValue == null ||  _dValue == '' ||  _dValue == ' ') {
      return '';
    } else {
      return _dValue;
    }
  }

  validateNameValue(_dValue:any) {
    if(_dValue == undefined || _dValue == null) {
      return '';
    } else {
      if(_dValue.toLowerCase() == 'my name' || _dValue.toLowerCase() == 'mi nombre') {
        return '';
      } else {
        return _dValue;
      }      
    }
  }



  func() {
    return 'ssss';
  }

  rHTML() {
    let rHtml = '<div class="_cName" (click)="ccall_testfunc()"><h1>Helol Pakistan</h1>sd asdf sadf asdfasd fadf asd</div>';
    rHtml +='<h1>Para two here.</h1>'
    return rHtml;
  }

  validate_ratingstarvalue(_ratingV:string, _i:any) {
    let ratingV = parseFloat(_ratingV.toString());
    let i = parseFloat(_i.toString());
    if(i <= ratingV) {
      return 1;
    } else {
      if(ratingV < i) {
        if(ratingV == (i - 0.5))
        {
          return 2;
        }
        else
        {
          return 0;
        }
        
      } 
    }
  }

  round_tofive(fRating:string) {
    let _fRating:number = parseFloat(fRating);

    let withpoint_number = _fRating.toFixed(1);
    let xlastDigits:number = parseInt(withpoint_number.toString().slice(-1));
    let xfirstDigits:number = parseInt(withpoint_number.toString().slice(0, 1));

    let different:number = 0;

    if(xlastDigits == 0) {

    } else if(xlastDigits == 5) {
      different = 99;

    } else if(xlastDigits > 7) {
      different = 1 - parseFloat(xlastDigits.toString());

    } else if(xlastDigits > 5) {
      different = 0.5 - parseFloat(xlastDigits.toString());

    } else if(xlastDigits > 2) {
      different = 0.5 - parseFloat(xlastDigits.toString());
      
    } else {
      different = 0 - parseFloat(xlastDigits.toString());
    }
    
    if(different == 0)
    {
      return (_fRating).toFixed(0);
    }
    else if(different == 99)
    {
      return parseInt((_fRating).toFixed(0)) - 0.5;
    }
    else
    {
      let cDifferent = parseFloat(xlastDigits.toString()) + different;
      
      //return cDifferent;
      return parseFloat(cDifferent.toString()) + parseFloat(xfirstDigits.toString());
      //return parseFloat(cDifferent.toString()) + parseFloat(withoutpoint_number.toString());
    }
  }

  validate_nRating(fRating:string, i:number) {
    let _fRating:number = parseFloat(fRating);

    let withpoint_number = _fRating.toFixed(1);
    let xlastDigits:number = parseInt(withpoint_number.toString().slice(-1));
    let xfirstDigits:number = parseInt(withpoint_number.toString().slice(0, 1));

    let different:number = 0;

    if(xlastDigits == 0) {

    } else if(xlastDigits == 5) {
      different = 99;

    } else if(xlastDigits > 7) {
      different = 1 - parseFloat(xlastDigits.toString());

    } else if(xlastDigits > 5) {
      different = 0.5 - parseFloat(xlastDigits.toString());

    } else if(xlastDigits > 2) {
      different = 0.5 - parseFloat(xlastDigits.toString());
      
    } else {
      different = 0 - parseFloat(xlastDigits.toString());
    }
    
    if(different == 0)
    {
      let result = (_fRating).toFixed(0);
      return this.validate_ratingstarvalue(result, i);
    }
    else if(different == 99)
    {
      let result = parseInt((_fRating).toFixed(0)) - 0.5;
      return this.validate_ratingstarvalue(result.toString(), i);
    }
    else
    {
      let cDifferent = parseFloat(xlastDigits.toString()) + different;
      
      //return cDifferent;
      let result = parseFloat(cDifferent.toString()) + parseFloat(xfirstDigits.toString());
      return this.validate_ratingstarvalue(result.toString(), i);
      //return parseFloat(cDifferent.toString()) + parseFloat(withoutpoint_number.toString());
    }
  }

  get_changedMobileFormate(_mNumber:any) {
    return '+'+_mNumber.replace(/^(.{2})(.{3})(.{3})(.*)$/, "$1 $2 $3 $4");
  }

  calcCrow(lat1:number, lon1:number, lat2:number, lon2:number) {
    var R = 6371; // km
    var dLat = this.toRad(lat2-lat1);
    var dLon = this.toRad(lon2-lon1);
    var lat1 = this.toRad(lat1);
    var lat2 = this.toRad(lat2);

    var a = Math.sin(dLat/2) * Math.sin(dLat/2) + Math.sin(dLon/2) * Math.sin(dLon/2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
  }

  // Converts numeric degrees to radians
  toRad(Value:number) {
      return Value * Math.PI / 180;
  }

  calcDeliveryCharges(
    lat1:number,
    lon1:number,
    lat2:number,
    lon2:number,
    deliverycharges:number,
    deliverychargeslimit:number,
    deliverylimitexceededcharges:number,
    extradeliverycharges:number,
    extradeliverychargeslimit:number) {

    let distance = this.calcCrow(lat1, lon1, lat2, lon2);
    if(distance > deliverychargeslimit) {

      deliverycharges = parseFloat(deliverycharges.toString()) + parseFloat(deliverylimitexceededcharges.toString());
      if(extradeliverychargeslimit == 0) {
        return deliverycharges;
      } else {
        let total_extradistance = parseFloat(distance.toString()) - parseFloat(deliverychargeslimit.toString());
        let extraiTimes = Math.ceil(total_extradistance / parseFloat(extradeliverychargeslimit.toString()));
        let extraCharges = extraiTimes * parseFloat(extradeliverycharges.toString());
        return parseFloat(deliverycharges.toString()) + extraCharges;
      }
    } else {
      return deliverycharges;
    }
  }

  async checkLocation() {
    let response = true;

    const mylocation_name = await this.storage.get('mylocation_name');
    if(!mylocation_name) {
      response = false;
    }

    const mylocation_latitude = await this.storage.get('mylocation_latitude');
    if(!mylocation_latitude) {
      response = false;
    }

    const mylocation_longitude = await this.storage.get('mylocation_longitude');
    if(!mylocation_longitude) {
      response = false;
    }
    return response;
  }

  async checkSignature() {
    let response = true;
    
    const selected_signature = await this.storage.get('selected_signature');
    if(!selected_signature) {
      response = false;
    }

    return response;
  }


  async check_directshop() {
    let response = true;

    const directshop = await this.storage.get('directshop_active');
    if(!directshop) {
      response = false;
    }

    const directshopbranch = await this.storage.get('directshop_branchresult');
    if(!directshopbranch) {
      response = false;
    }
    
    return response;
  }

  validate_valueexist(value:any) {
    if(value == '' || value == undefined || value == null || value == ' ') {
      return false;
    } else {
      return true;
    }
  }

  validate_valueexistIncNum(value:any) {
    if(value == '0' || value == '' || value == undefined || value == null || value == ' ') {
      return false;
    } else {
      return true;
    }
  }

  formpassing_value(_value:any) {
    if(_value) {
      return _value;
    } else {
      return '';
    }
  }


  get_countrycode(country_id:any) {

    if(country_id == 167) {
      return '92';
    } else  if (country_id == 211) {
      return '249';
    } else  if (country_id == 209) {
      return '34';
    } else  if (country_id == 165) {
      return '47';
    } else  if (country_id == 194) {
      return '966';
    } else  if (country_id == 228) {
      return '90';
    } else  if (country_id == 234) {
      return '971';
    } else  if (country_id == 1) {
      return '93';
    } else {
      return '';
    }
  }


}
