import { Component, NgZone } from '@angular/core';
import { AlertController, LoadingController, MenuController, ModalController, NavController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { GeneralService } from './services/general.service';
import { EnvService } from './services/env.service';
import { LangService } from './services/lang.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService } from './services/alert.service';
import { HttpClient } from '@angular/common/http';
import { EventEmitterService } from './services/event-emitter.service';
import { Subscription } from 'rxjs';
import { StatusBar, Style } from '@capacitor/status-bar';

import { SplashScreen } from '@capacitor/splash-screen';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
//import { FacebookLogin } from '@capacitor-community/facebook-login';
import { WebPlugin } from '@capacitor/core';
import { promise } from 'protractor';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { HelperService } from './services/helper.service';
import { Location } from '@angular/common';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Device } from '@capacitor/device'; 

//new WebPlugin(FacebookLogin.login);
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  setuplocation_actionname: string = 'general';

  active_walkingcustomerapp:number = 0;
  walkingcustomerapp_lang:string;
  walkingcustomerapp_vendortype:string;
  walkingcustomerapp_vendorid:string;
  walkingcustomerapp_vendorurl:string;

  detech_device:number;

  mylocation_index : number;
  mylocation_latitude : number;
  mylocation_longitude : number;
  mylocation_name : string;
  bgeneralconfig_langversion : string;
  selected_slocationzone_id : number = 0;
  selected_slocationzone_reference : string;
  mylocation_tag : string;

  ridepickup_locationlatitude : number;
  ridepickup_locationlongitude : number;
  ridepickup_locationname : string;
  ridepickup_locationtag : string;
  ridepickup_countryid : number;
  ridepickup_countryname: string;
  ridepickup_lgcityid : number;
  ridepickup_lgcityname : string;

  ridedropoff_locationlatitude : number;
  ridedropoff_locationlongitude : number;
  ridedropoff_locationname : string;
  ridedropoff_locationtag : string;
  ridedropoff_countryid : number;
  ridedropoff_countryname: string;
  ridedropoff_lgcityid : number;
  ridedropoff_lgcityname : string;

  packagepickup_locationlatitude : number;
  packagepickup_locationlongitude : number;
  packagepickup_locationname : string;
  packagepickup_locationtag : string;
  packagepickup_countryid : number;
  packagepickup_countryname: string;
  packagepickup_lgcityid : number;
  packagepickup_lgcityname : string;

  packagedropoff_locationlatitude : number;
  packagedropoff_locationlongitude : number;
  packagedropoff_locationname : string;
  packagedropoff_locationtag : string;
  packagedropoff_countryid : number;
  packagedropoff_countryname: string;
  packagedropoff_lgcityid : number;
  packagedropoff_lgcityname : string;

  active_cartlink : number;
  active_carttotalitems : number;
  active_branch_id : number;
  active_cmp_id : number;
  
  selected_branchdata : any;
  selected_branchdatacategory : any;
  selected_branchdatacategorieslist : any;

  cartitems_list : any;
  muser_result : any;
  muserlsignature_signature : any;
  directshop_active:number = 0;
  directshop_branchresult:any;

  selected_country_id : number;
  selected_lgcity_id : number;
  selected_lgcity_name : string;

  selected_signature : string;
  current_lang : string;
  quotation_deliverytype: number = 2;
  deliverytypes_list:any;

  public history: string[] = ['/'];

  active_orderpreview : number = 0;
  active_orderheader : string;
  active_orderbody : string;

  active_company: string;
  active_company_title: string;

  viewed_banner: number = 0;
  active_loadingshops:number = 0;

  current_datetime: string = new Date().toISOString();

  device_fcmtoken : any = '';
  device_fcmtokentype : any = '';

  /* FOR SHOPLIST */
  selected_grouptype:number = 0;
  selected_lpbanner:number = 0;
  selected_lgcitylandingstyled:number = 0;
  selected_orderby:number = 1;
  selected_searchtext:string = '';
  selected_shoptypes:any = [];
  /* FOR SHOPLIST */

  /* FOR REFERENCE CODE BLOCK */
  __active_referencecodeblock: number = 0;
  muser_referencecode: any = '';
  __active_referencecodeaction: string = 'default';
  __active_referencecodepage: string = '';
  __active_referencecodeerror: string = '';
  /* FOR REFERENCE CODE BLOCK */

  /* PAGES ROUTING */
  routepage_vendorslist = '';
  routepage_vendorslistmap = '';
  routepage_vendorpage = '';
  routepage_vendorbrowsercategory = '';
  routepage_vendorcart = '';
  /* PAGES ROUTING */

  token: any = null;
  activemart_browseritems: number = 0;
  public selectedIndex = 0;
  public appPages = [
    {
      title: 'Home',
      url: '/landing-shoplist',
      icon: 'home'
    },
    {
      title: 'Profile',
      url: '/nprofile',
      icon: 'person-circle'
    },
    {
      title: 'My_Promo_Code',
      url: '/npromocode',
      icon: 'pricetags'
    },
    {
      title: 'My_Wallet',
      url: '/nwallet',
      icon: 'wallet'
    },
    {
      title: 'Announcement',
      url: '/announcement',
      icon: 'volume-high'
    },
    {
      title: 'Change_location',
      url: '/select-locationi/'+ new Date(),
      icon: 'locate'
    },
    {
      title: 'My_orders',
      url: '/norders',
      icon: 'list'
    },
    // {
    //   title: 'Looking_for_jobs',
    //   url: '/lookingfor-jobs',
    //   icon: 'ribbon'
    // }, 
    {
      title: 'Language',
      url: '/language',
      icon: 'globe'
    },
    {
      title: 'Trade_Account_request',
      url: '/nbusiness',
      icon: 'add'
    },
    {
      title: 'Privacy_Policy',
      url: '/nprivacypolicy',
      icon: 'key'
    },
    {
      title: 'Terms_of_Use',
      url: '/ntermsofuse',
      icon: 'git-network'
    },
    /*
    {
      title: 'Driver request',
      url: '/tabs/ndriver',
      icon: 'car'
    },
    */
  ];

  backButtonSubscription: Subscription;
  active_platform:string = '';

  active_directurlpush : string = '';

  icon = { url: './assets/foodsted/map-logo.png', scaledSize: {height: 30, width: 30}};
  largeIcon = { url: './assets/foodsted/map-logo.png', scaledSize: {height: 50, width: 50}}; 

  wallsIcon = { url: './assets/wallsCycle.png', scaledSize: {height: 30, width: 30}};
  wallsLargeIcon = { url: './assets/wallsCycle.png', scaledSize: {height: 50, width: 50}};

  bikeIcon = { url: 'assets/ride/track/bike.png', scaledSize: {height: 50, width: 50}};
  autoIcon = { url: 'assets/ride/track/auto.png', scaledSize: {height: 50, width: 50}};
  minicarIcon = { url: 'assets/ride/track/minicar.png', scaledSize: {height: 50, width: 50}};
  accarIcon = { url: 'assets/ride/track/accar.png', scaledSize: {height: 50, width: 50}}; 

  selected_devicename:string = 'Unknown device';

  constructor(
    private storage: Storage,
    private platform: Platform,
    private generalService: GeneralService,
    private navCtrl: NavController,
    private alertController: AlertController,
    public env: EnvService,
    public lang: LangService,
    private router: Router,
    private menu: MenuController,
    private alertService: AlertService,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private http: HttpClient,
    private activatedroute: ActivatedRoute,
    private eventEmitterService: EventEmitterService,
    private signInWithApple: SignInWithApple,
    private zone: NgZone,
    private helper: HelperService,
    private location: Location,
    private afMessaging: AngularFireMessaging
  ) {
    this.initializeApp();
    if(this.env.active_company == 'aqrab') {
      this.icon = { url: './assets/aqrab/map-logo.png', scaledSize: {height: 30, width: 30}};
      this.largeIcon = { url: './assets/aqrab/map-logo.png', scaledSize: {height: 50, width: 50}};
    }
  }
  
  initializeApp() {

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const domain = 'apps.foodsted.com';
        const pathArray = event.url.split(domain);
        let urlPath = pathArray.pop();
        if(urlPath.match("api/payment/aa/msg_success") || urlPath.match("api/payment/aa/msg_error") || urlPath.match("api/payment/aa/dinterocallbacks_for_payment_update")) {
          this.navCtrl.navigateRoot('/norders/'+this.uniqid('callallorder')+'?validate_orderpaymentstatus=1');
        } else {
          this.navCtrl.navigateRoot('/landing-shoplist/'+this.uniqid('landing-shoplist'));
        }
      });
    });

    this.platform.ready().then(async () => {
      setTimeout(() => {
        SplashScreen.hide();
      }, 1000);


      this.selected_devicename = this.lang.v.unknown_device;

      const info = await Device.getInfo();
  
      if(info) {
        let device_name = "";
        if(info.manufacturer) { device_name += info.manufacturer; }
  
        if(info.name) {
          if(device_name) { device_name += " "; }
          device_name += info.name;
        }
  
        this.selected_devicename = device_name;
      } 


      this.setupStorageVariables();

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.config_historyurl(event.urlAfterRedirects);
          /*
          console.log(" + event.urlAfterRedirects + ");
          console.log(event.urlAfterRedirects);
          this.history.push(event.urlAfterRedirects);
          let __vLHURL = this.validate_LastHistoryUrl(event.urlAfterRedirects);
          // console.log(" +++++++++++++++++++++++++++++++++++++++++++++++++ this.history.pop() +++++++++++++++++++++++++++++++++++++++++++++++++ ");
          // console.log(this.history.length);
          // console.log(this.history);
          // console.log('__vLHURL');
          // console.log(__vLHURL);
          if(__vLHURL == 0) {
            this.history.push(event.urlAfterRedirects);
          }
          */
        }
      });

      if(this.platform.is('android') && this.platform.is('capacitor')) {
        this.active_platform = 'android';
        await StatusBar.setOverlaysWebView({ overlay: false });
        await StatusBar.setStyle({ style: Style.Dark });
      }

      if(this.platform.is('ios')) { this.active_platform = 'ios'; }



      if(this.platform.is('capacitor')) {

        this.device_fcmtokentype = 'device';
        
        this.detech_device = 1;

        PushNotifications.requestPermissions().then(async(result) => {  
          if (result.receive === 'granted') {  
            await PushNotifications.register();
          } else {
            //alert("Notificaiton error...");
            this.device_fcmtoken = '';
          } 
        });

        // PushNotifications.addListener('registration',
        //   (token: Token) => {
        //     console.log('My token: ' + JSON.stringify(token));
        //   }
        // );
        PushNotifications.addListener('registration',
          (token: Token) => {
            // console.log(" token token token token token token token token token ");
            // console.log(token);
            this.device_fcmtoken = token.value;
          }
        );
   
        // Some issue with our setup and push will not work
        PushNotifications.addListener('registrationError',
          (error: any) => {
            console.log('Error on registration: ' + JSON.stringify(error));
          }
        );
    
        // Show us the notification payload if the app is open on our device
        PushNotifications.addListener('pushNotificationReceived',
          (notification: PushNotificationSchema) => {
            this.triggerNotification(notification.data);
            //this.triggerNotification(notification);
            //console.log('Push received: ' + JSON.stringify(notification));
          }
        );

        if(this.active_platform == 'android') {
          PushNotifications.createChannel({
            id: 'general',
            description: 'general',
            importance: 5,
            visibility: 1,
            name: 'general'
          })
          .then(sucesso => console.log('sucess', sucesso))
          .catch(erro => console.error('error', erro));  
        }
    
        // Method called when tapping on a notification
        PushNotifications.addListener('pushNotificationActionPerformed',
          (notification: ActionPerformed) => {
            //this.triggerNotification(notification);
            this.triggerNotification(notification.notification.data);
            //console.log('Push action performed: ' + JSON.stringify(notification));
          }
        );
    
      } else {
        GoogleAuth.initialize();
        // .init();
        this.detech_device = 0; 

        this.device_fcmtokentype = 'web'; 

        // NOTIFICATION
        this.afMessaging.requestToken.subscribe(
          (token) => {
            if(token) {
              this.device_fcmtoken = token;
            } else {
              this.device_fcmtoken = '';
            }
          },
          (err) => {
            console.error('Unable to get permission to notify.', err);
          }
        );

        this.afMessaging.messages.subscribe((notification) => {
          //console.log(notification);
          this.triggerNotification(notification['data']);
          //console.log('Push action performed: ' + JSON.stringify(notification));
        });

      }
    
      this.platform.backButton.subscribeWithPriority(1, () => {
        this.call_backlink();
      });

    });
  }

  triggerNotification(data:any) {
    // console.log("triggerNotification");
    // console.log(data);
    // console.log(data.notification);

    if(this.router.url.slice(0,8) == '/norders' && data.tickerText == 'order') {
      this.presentAlert(data.title, data.body);
      this.eventEmitterService.call_invokeChangeOrderFunction(data);

    } else if(data.tickerText == 'chatreply') {      
      let quotation_id = data.quotation_id;
      let characters_limit = 11 + parseFloat(quotation_id.length);

      if(this.router.url.slice(0,characters_limit) == '/orderchat/'+quotation_id) {
        this.eventEmitterService.call_invokeNewChatFunction(data);
        this.alertService.newMessageToast(data.title, data.body, 2000);

      } else {
        this.alertService.newMessageToast(data.title, data.body, 10000);
      }
      
    } else {
      this.presentAlert(data.title, data.body);
    }
  }

  config_historyurl(url:any) {
    if(url.slice(0,17) == '/landing-shoplist') {
      this.routepage_vendorslist = '';
    }

    if(url.slice(0,11) == '/shops-list') {
      this.routepage_vendorslist = url;
      this.routepage_vendorslistmap = '';
    }

    if(url.slice(0,10) == '/shops-map') {
      this.routepage_vendorslistmap = url;
    }

    if(url == '/shop' || url.slice(0,6) == '/shop/') {
      this.routepage_vendorbrowsercategory = '';
      this.routepage_vendorpage = url;
    }

    if(url.slice(0,13) == '/browsershop/') {
      this.routepage_vendorbrowsercategory = url;
    }

    if(url.slice(0,7) == '/ncart/') {
      this.routepage_vendorcart = url;
    }
  }
  
  call_backlink(): void {
    this.modalController.getTop().then((modalDetail) => {
      if(modalDetail) {
        this.modalController.dismiss();
      } else {
        let router_url = this.router.url;  

        if(router_url.slice(0,17) == '/landing-shoplist') {
          return false;
        } else {

          if(router_url.slice(0,11) == '/shops-list') {
            this.navCtrl.navigateRoot('/landing-shoplist');
            return;
          }

          if(router_url.slice(0,16) == '/order-anything/'
            ||
            router_url.slice(0,12) == '/restaurant/'
            ||
            router_url.slice(0,11) == '/home-chef/'
            ||
            router_url.slice(0,6) == '/mart/'
            ||
            router_url.slice(0,6) == '/shop/'
            ||
            router_url.slice(0,8) == '/hawker/'
            ||
            router_url.slice(0,12) == '/happy-hour/'
            ||
            router_url.slice(0,10) == '/catering/'
            ||
            router_url.slice(0,15) == '/kids-activity/'
            ||
            router_url.slice(0,10) == '/pharmacy/'
            ||
            router_url.slice(0,8) == '/bakers/'
            ||
            router_url.slice(0,8) == '/vendor/') {

            if(this.helper.validate_valueexist(this.routepage_vendorslistmap)) {
              this.navCtrl.navigateRoot(this.routepage_vendorslistmap);
              return;
            }
            if(this.helper.validate_valueexist(this.routepage_vendorslist)) {
              this.navCtrl.navigateRoot(this.routepage_vendorslist);
              return;
            }
            this.navCtrl.navigateRoot('/landing-shoplist');
            return;
          }

          if(router_url.slice(0,10) == '/shops-map') {
            if(this.helper.validate_valueexist(this.routepage_vendorslist)) {
              this.navCtrl.navigateRoot(this.routepage_vendorslist);
              return;
            }
            this.navCtrl.navigateRoot('/landing-shoplist');
            return;
          } 

          if(router_url.slice(0,13) == '/browsershop/') {

            if(this.selected_branchdata['branch_url'], this.selected_branchdata['branch_type']) {
              this.select_vendor(this.selected_branchdata, false, '?action=cart');
              return;
            }
            
            // working here
            // if(this.helper.validate_valueexist(this.routepage_vendorpage)) {
            //   this.navCtrl.navigateRoot(this.routepage_vendorpage);
            //   return;
            // } 

            this.navCtrl.navigateRoot('/landing-shoplist');
            return;
          }

          if(router_url.slice(0,7) == '/ncart/') {

            if(this.quotation_deliverytype == 4 && this.cartitems_list.length == 0 && this.selected_branchdata['branch_tablebookingdisablemenu'] == 1) {
              this.navCtrl.navigateRoot('/shops-list/'+this.uniqid());
              return;
            }

            if(this.helper.validate_valueexist(this.routepage_vendorbrowsercategory)) {
              this.navCtrl.navigateRoot(this.routepage_vendorbrowsercategory);
              return;
            }

            if(this.helper.validate_valueexist(this.routepage_vendorpage)) {
              this.navCtrl.navigateRoot(this.routepage_vendorpage);
              return;
            }

            if(this.selected_branchdata['branch_url'], this.selected_branchdata['branch_type']) {
              this.select_vendor(this.selected_branchdata, false, '?action=cart');
              return;
            }
          }


          if(router_url.slice(0,8) == '/norders' || router_url.slice(0,12) == '/ride-orders' || router_url.slice(0,15) == '/package-orders') {
            this.navCtrl.navigateRoot('/landing-shoplist');
            return;
          }

          if(router_url.slice(0,17) == '/package-delivery') {
            this.navCtrl.navigateRoot('/package-dropofflocation');
            return;
          }

          if(router_url.slice(0,24) == '/package-dropofflocation') {
            this.navCtrl.navigateRoot('/package-pickuplocation');
            return;
          }

          if(router_url.slice(0,23) == '/package-pickuplocation') {
            this.navCtrl.navigateRoot('/landing-shoplist');
            return;
          }


          /// 

          if(router_url.slice(0,12) == '/ride-booked') {
            this.navCtrl.navigateRoot('/ridedropoff-location');
            return;
          }
          
          if(router_url.slice(0,21) == '/ridedropoff-location') {
            this.navCtrl.navigateRoot('/ridepickup-location');
            return;
          }

          if(router_url.slice(0,20) == '/ridepickup-location') {
            this.navCtrl.navigateRoot('/landing-shoplist');
            return;
          }

      
          

          this.location.back();
          return;

        }
      }
    });
  }

  reset_routepages() {
    this.routepage_vendorslist = '';
    this.routepage_vendorslistmap = '';
    this.routepage_vendorpage = '';
    this.routepage_vendorbrowsercategory = '';
    this.routepage_vendorcart = '';
  }


  showalert() {
    this.active_orderpreview = 1;
    this.active_orderheader = 'Notifiation Header';
    this.active_orderbody = 'Notifiation Body';
    setTimeout(() => this.getThreshold(), 100000);
  }

  async show_notification(title:string, body:string) {
    this.active_orderpreview = 1;
    this.active_orderheader = title;
    this.active_orderbody = body;
    setTimeout(() => this.close_notification(), 20000);
  }

  async presentAlert(title:string, body:string) {
    let alert = await this.alertController.create({
      subHeader: title,
      message: body,
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            console.log('Ok clicked');
          }
        },
      ]
    });  
    await alert.present();
    setTimeout(()=>{
      alert.dismiss();
    }, 100000);
  }

  close_notification() {
    this.active_orderpreview = 0;
  } 

  getThreshold() {
    this.active_orderpreview = 0;
  }

  open_orders() {
    this.navCtrl.navigateRoot('/tabs/orders/'+new Date().toISOString());
  }


  async setupStorageVariables() {

    this.storage.get('selected_branchdata').then((data) => {
      if(data !== null && data !== undefined) {
        this.active_cartlink = 1;
        this.active_branch_id = data.branch_id;
        this.active_cmp_id = data.cmp_id;
        this.selected_branchdata = data;

      } else {
        this.active_cartlink = 0;
        this.active_branch_id = 0;
        this.active_cmp_id = 0;
        this.selected_branchdata = undefined;
      }      
    });

    this.storage.get('selected_branchdatacategory').then((data) => {
      if(data !== null && data !== undefined) {
        this.selected_branchdatacategory = data;        
      } else {
        this.selected_branchdatacategory = undefined;
      }
    });

    this.storage.get('selected_branchdatacategorieslist').then((data) => {
      if(data !== null && data !== undefined) {
        this.selected_branchdatacategorieslist = data;        
      } else {
        this.selected_branchdatacategorieslist = undefined;
      }
    });


    this.storage.get('cartitems_list').then((data) => {
      if(data !== null && data !== undefined) {
        this.cartitems_list = data;        
      } else {
        this.cartitems_list = [];
      }
    });

    this.storage.get('selected_grouptype').then((data) => {
      if(data !== null && data !== undefined) {
        this.selected_grouptype = parseInt(data);        
      } else {
        this.selected_grouptype = 1;
      }
    });

    this.storage.get('selected_lpbanner').then((data) => {
      if(data !== null && data !== undefined) {
        this.selected_lpbanner = parseInt(data);        
      } else {
        this.selected_lpbanner = 1;
      }
    });

    this.storage.get('selected_lgcitylandingstyled').then((data) => {
      if(data !== null && data !== undefined) {
        this.selected_lgcitylandingstyled = parseInt(data);        
      } else {
        this.selected_lgcitylandingstyled = 0;
      }
    });

    this.storage.get('muser_result').then((data) => {
      if(data !== null && data !== undefined) {
        this.muser_result = data;
      } else {
        this.muser_result = undefined;
      }
    });

    this.storage.get('directshop_active').then((data) => {
      if(data !== null && data !== undefined) {
        this.directshop_active = parseInt(data);
      } else {
        this.directshop_active = 0;
      }
    }); 
    
    this.storage.get('directshop_branchresult').then((data) => {
      if(data !== null && data !== undefined) {
        this.directshop_branchresult = data;
      } else {
        this.directshop_branchresult = undefined;
      }
    });

    this.storage.get('muserlsignature_signature').then((data) => {
      if(data !== null && data !== undefined) {
        this.muserlsignature_signature = data;
      } else {
        this.muserlsignature_signature = undefined;
      }
    });

    this.storage.get('quotation_deliverytype').then((data) => {
      if(data !== null && data !== undefined) {
        this.quotation_deliverytype = data;
      } else {
        this.storage.set('quotation_deliverytype', 2).then(()=>{
          this.quotation_deliverytype = 2;
        });
      }
    }); 
    
    this.storage.get('selected_country_id').then((data) => { this.selected_country_id = data; });
    this.storage.get('selected_lgcity_id').then((data) => { this.selected_lgcity_id = data; });
    this.storage.get('selected_lgcity_name').then((data) => { this.selected_lgcity_name = data; });  

    this.storage.get('mylocation_latitude').then((data) => { this.mylocation_latitude = data; });
    this.storage.get('mylocation_index').then((data) => { this.mylocation_index = data; });    

    this.storage.get('mylocation_longitude').then((data) => { this.mylocation_longitude = data; });
    this.storage.get('mylocation_name').then((data) => { this.mylocation_name = data; }); 

    this.storage.get('selected_slocationzone_id').then((data) => {
      if(data !== null && data !== undefined) {
        this.selected_slocationzone_id = data;
      } else {
        this.selected_slocationzone_id = 0;
      }
    });

    this.storage.get('selected_slocationzone_reference').then((data) => {
      if(data !== null && data !== undefined) {
        this.selected_slocationzone_reference = data;
      } else {
        this.selected_slocationzone_reference = '';
      }
    });

    this.storage.get('mylocation_tag').then((data) => { this.mylocation_tag = data; });

    this.storage.get('selected_signature').then((data) => {
      if(data !== null && data !== undefined) {
        this.selected_signature = data;
      } else {
        this.selected_signature = this.uniqid('DR');
        this.storage.set('selected_signature', this.selected_signature);
      }
    });

    this.storage.get('current_lang').then((data) => {
      if(data !== null && data !== undefined) {
        this.current_lang = data;
      }
    });


    this.storage.get('bgeneralconfig_langversion').then((data) => {
      if(data !== null && data !== undefined) {
        this.bgeneralconfig_langversion = data;
      } else {
        this.bgeneralconfig_langversion = '';
      }
    });
  
    this.storage.get('ridepickup_locationlatitude').then((data) => { this.ridepickup_locationlatitude = data; });
    this.storage.get('ridepickup_locationlongitude').then((data) => { this.ridepickup_locationlongitude = data; }); 
    this.storage.get('ridepickup_locationname').then((data) => { this.ridepickup_locationname = data; });
    this.storage.get('ridepickup_locationtag').then((data) => { this.ridepickup_locationtag = data; }); 
    this.storage.get('ridepickup_countryid').then((data) => { this.ridepickup_countryid = data; });
    this.storage.get('ridepickup_countryname').then((data) => { this.ridepickup_countryname = data; }); 
    this.storage.get('ridepickup_lgcityid').then((data) => { this.ridepickup_lgcityid = data; });
    this.storage.get('ridepickup_lgcityname').then((data) => { this.ridepickup_lgcityname = data; }); 

    this.storage.get('ridedropoff_locationlatitude').then((data) => { this.ridedropoff_locationlatitude = data; });
    this.storage.get('ridedropoff_locationlongitude').then((data) => { this.ridedropoff_locationlongitude = data; }); 
    this.storage.get('ridedropoff_locationname').then((data) => { this.ridedropoff_locationname = data; });
    this.storage.get('ridedropoff_locationtag').then((data) => { this.ridedropoff_locationtag = data; }); 
    this.storage.get('ridedropoff_countryid').then((data) => { this.ridedropoff_countryid = data; });
    this.storage.get('ridedropoff_countryname').then((data) => { this.ridedropoff_countryname = data; }); 
    this.storage.get('ridedropoff_lgcityid').then((data) => { this.ridedropoff_lgcityid = data; });
    this.storage.get('ridedropoff_lgcityname').then((data) => { this.ridedropoff_lgcityname = data; });    
  
    this.storage.get('packagepickup_locationlatitude').then((data) => { this.packagepickup_locationlatitude = data; });
    this.storage.get('packagepickup_locationlongitude').then((data) => { this.packagepickup_locationlongitude = data; }); 
    this.storage.get('packagepickup_locationname').then((data) => { this.packagepickup_locationname = data; });
    this.storage.get('packagepickup_locationtag').then((data) => { this.packagepickup_locationtag = data; }); 
    this.storage.get('packagepickup_countryid').then((data) => { this.packagepickup_countryid = data; });
    this.storage.get('packagepickup_countryname').then((data) => { this.packagepickup_countryname = data; }); 
    this.storage.get('packagepickup_lgcityid').then((data) => { this.packagepickup_lgcityid = data; });
    this.storage.get('packagepickup_lgcityname').then((data) => { this.packagepickup_lgcityname = data; }); 

    this.storage.get('packagedropoff_locationlatitude').then((data) => { this.packagedropoff_locationlatitude = data; });
    this.storage.get('packagedropoff_locationlongitude').then((data) => { this.packagedropoff_locationlongitude = data; }); 
    this.storage.get('packagedropoff_locationname').then((data) => { this.packagedropoff_locationname = data; });
    this.storage.get('packagedropoff_locationtag').then((data) => { this.packagedropoff_locationtag = data; }); 
    this.storage.get('packagedropoff_countryid').then((data) => { this.packagedropoff_countryid = data; });
    this.storage.get('packagedropoff_countryname').then((data) => { this.packagedropoff_countryname = data; }); 
    this.storage.get('packagedropoff_lgcityid').then((data) => { this.packagedropoff_lgcityid = data; });
    this.storage.get('packagedropoff_lgcityname').then((data) => { this.packagedropoff_lgcityname = data; }); 
  
  }


  setup_language() {
    this.storage.get('current_lang').then((data) => {
      if(data !== null && data !== undefined) {
        this.current_lang = data;
      } else {
        this.current_lang = 'en';
        this.storage.set('current_lang', this.current_lang);
      }
    }); 
  } 

  change_deliverytype(_dType:any) {
    this.quotation_deliverytype = _dType;
  }

  change_grouptype(_dType:any) {
    this.selected_grouptype = _dType;
  }

  change_lpbanner(lpbanner:any) {
    this.selected_lpbanner = lpbanner;
  }
  
  calculate_totalcartitems() {
    let cart_items:number = 0;
    for (let index = 0; index < this.cartitems_list.length; index++) {
      cart_items += parseFloat(this.cartitems_list[index]['qty']);
    }
    return cart_items;
  }

  calculate_totalcartamount() {
    let sum = 0;
    for (let index = 0; index < this.cartitems_list.length; index++) {
      sum += parseFloat(this.cartitems_list[index]['fnetprice']);
    }
    return sum;
  }

  openMenu() {
    this.menu.open();
  }

  async logout() {
    if(this.device_fcmtoken != '') {
      this.deletetoken(this.device_fcmtoken);
    } else {
      this.removeMUSERSESSION();
    }
  }
  
  async deletetoken(token:any) {
      const loading = await this.loadingController.create({
        cssClass: 'loadingScreen',
        spinner: null,
        translucent: true,
        showBackdrop: false,
        message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
      });
      loading.present();
      const formData = new FormData();
      formData.append('reset_usertoken', '1');
      formData.append('token', token);
      this.http.post(this.env.API_URL+'shop', formData).subscribe(
        () => {
          this.removeMUSERSESSION();
          loading.dismiss();
        },
        error => {
          this.removeMUSERSESSION();
          loading.dismiss();
          console.log( error );
          console.log( error.error );
          if( error.error.messagedetail == undefined ) {
              this.alertService.error('', this.lang.v.Error_to_connect);
          } else {
            this.alertService.error('', error.error.messagedetail);
          }
        }
      )
  }

  removeMUSERSESSION() {
    this.storage.remove('muser_result').then(() => {
      this.setupStorageVariables();
      // this.alertService.success('', this.lang.v.Account_logged_out);
      this.navCtrl.navigateRoot('/nprofile');
    });
  }

  upload_loadingshops() {
    this.active_loadingshops = 1;
  }

  openios_applink() {
    return this.env.IOS_APPLINK;
  }

  iosapp_update() {
    window.open("https://itunes.apple.com/us/app/keynote/id361285480?mt=8", "_system");
  }

  openandroid_applink() {
    return this.env.ANDROID_APPLINK;
  }

  getstatus_walkingcustomerapp() {
    if(this.active_walkingcustomerapp == 1) {
      return 1;
    } else {
      return 0;      
    }
  }

  open_doorders() {
    this.activatedroute.queryParams.subscribe(params => {
      if(params["branch_id"]) {
        let branch_id = params["branch_id"];
        this.navCtrl.navigateRoot('shoporder/'+branch_id+'?branch_id='+branch_id);
      }
    });
  }

  open_dohome() {
    this.activatedroute.queryParams.subscribe(params => {
      if(params["branch_id"]) {
        let branch_id = params["branch_id"];
        this.navCtrl.navigateRoot('shoppage/'+branch_id+'?branch_id='+branch_id);
      }
    });
  }

  getselected_deliveryOptionName(deliveryType:any, _currentLang:string) {
    if(_currentLang == 'en') {
      return deliveryType.deliverytype_name_en;

    } else if(_currentLang == 'es') {
      return deliveryType.deliverytype_name_es;

    } else if(_currentLang == 'no') {
      return deliveryType.deliverytype_name_no;

    } else if(_currentLang == 'ar') {
      return deliveryType.deliverytype_name_ar;

    } else if(_currentLang == 'ur') {
      return deliveryType.deliverytype_name_ur;

    } else {
      return deliveryType.deliverytype_name_en;
    }
  }
  
  getselected_deliveryName(deliveryType:number, _currentLang:string, _getResult:any) {
    //return 'Name here.';
    if(deliveryType == undefined) {
      return ' - ';
    } else {
      if(this.deliverytypes_list == undefined) {
        return ' - ';
      } else {
        let deliverytype_result:any = undefined;
        this.deliverytypes_list.forEach((element: any) => {
          if(element.deliverytype_id == deliveryType) {    
            deliverytype_result = element;
          }
        });
        if(deliverytype_result !== undefined) {

          if(_getResult == 'name') {
            if(_currentLang == 'en') {
              return deliverytype_result.deliverytype_name_en;

            } else if(_currentLang == 'es') {
              return deliverytype_result.deliverytype_name_es;

            } else if(_currentLang == 'no') {
              return deliverytype_result.deliverytype_name_no;

            } else if(_currentLang == 'ar') {
              return deliverytype_result.deliverytype_name_ar;

            } else if(_currentLang == 'ur') {
              return deliverytype_result.deliverytype_name_ur;

            } else {
              return deliverytype_result.deliverytype_name_en;

            }
          }

          if(_getResult == 'icon') { return deliverytype_result.deliverytype_icon; }

        } else {
          return '-';       
        }

      }
    }
  }

  uniqid(prefix = "", random = false) {
    let sec = Date.now() * 1000 + Math.random() * 1000;
    let id = sec.toString(16).replace(/\./g, "").padEnd(14, "0");
    return `${prefix}${id}${random ? `.${Math.trunc(Math.random() * 100000000)}`:""}`;
  };

  validate_deliveryoption(selectedDeliveryType:number, oType:number) {
    if(selectedDeliveryType == 2) {
      if(oType == 3 || oType == 4) {
        return 0;
      } else {
        return 1;
      }
    } else {
      return 1;
    }  
  }

  JanuaryMonth() {
    if(this.lang.v.January) {
      return this.lang.v.January;
    } else {
      return 'January';
    }
  }

  FebruaryMonth() {
    if(this.lang.v.February) {
      return this.lang.v.February;
    } else {
      return 'February';
    }
  }

  MarchMonth() {
    if(this.lang.v.March) {
      return this.lang.v.March;
    } else {
      return 'March';
    }
  }

  AprilMonth() {
    if(this.lang.v.April) {
      return this.lang.v.April;
    } else {
      return 'April';
    }
  }

  MayMonth() {
    if(this.lang.v.May) {
      return this.lang.v.May;
    } else {
      return 'May';
    }
  }

  JuneMonth() {
    if(this.lang.v.June) {
      return this.lang.v.June;
    } else {
      return 'June';
    }
  }

  JulyMonth() {
    if(this.lang.v.July) {
      return this.lang.v.July;
    } else {
      return 'July';
    }
  }

  AugustMonth() {
    if(this.lang.v.August) {
      return this.lang.v.August;
    } else {
      return 'August';
    }
  }

  SeptemberMonth() {
    if(this.lang.v.September) {
      return this.lang.v.September;
    } else {
      return 'September';
    }
  }

  OctoberMonth() {
    if(this.lang.v.October) {
      return this.lang.v.October;
    } else {
      return 'October';
    }
  }

  NovemberMonth() {
    if(this.lang.v.November) {
      return this.lang.v.November;
    } else {
      return 'November';
    }
  }

  DecemberMonth() {
    if(this.lang.v.December) {
      return this.lang.v.December;
    } else {
      return 'December';
    }
  }

  updateBannerViewed() {
    this.viewed_banner = 1;
  }

  validate_deliverytype(xgrouptype:any, xdeliverytype_id:any) {
    let grouptype = parseInt(xgrouptype);
    let deliverytype_id = parseInt(xdeliverytype_id);

    let returnResponse:number = 0;
    
    if(grouptype == 100) {
      returnResponse = 1;
    }
    
    if(grouptype == 3 || grouptype == 5) {
      if(deliverytype_id == 3 || deliverytype_id == 4 || deliverytype_id == 5) {
        returnResponse = 1;
      }
    }

    if(grouptype == 2) {
      if(deliverytype_id == 3 || deliverytype_id == 4) {
        returnResponse = 1;
      }
    }
    return returnResponse;
  }

  validate_LastHistoryUrl(currentURL:any) {
    let countLengthUrlHistory = this.history.length;
    if(countLengthUrlHistory > 0) {
      let lasturl_FromHistory = this.history[(countLengthUrlHistory-1)];
      /*
      console.log("lasturl_FromHistory");
      console.log(lasturl_FromHistory.slice(0,11));
      console.log(lasturl_FromHistory.slice(0,11));
      console.log(lasturl_FromHistory.slice(0,11));      
      */
      if(currentURL.slice(0,11) == '/shops-list' || currentURL.slice(0,6) == '/shop/' || currentURL.slice(0,7) == '/ncart/') {

        if(currentURL.slice(0,11) == lasturl_FromHistory.slice(0,11) && lasturl_FromHistory.slice(0,11) == '/shops-list') {
          return 1;

        } else if(currentURL.slice(0,6) == lasturl_FromHistory.slice(0,6) && lasturl_FromHistory.slice(0,6) == '/shop/') {
          return 1;

        } else if(currentURL.slice(0,7) == lasturl_FromHistory.slice(0,7) && lasturl_FromHistory.slice(0,7) == '/ncart/') {
          return 1;


        } else {
          return 0;
        }

      } else {
        return 0;
      }
    } else {
      return 0;

    }
  }

  async googleSignup(_pagereference:any) {
    this.__active_referencecodepage = _pagereference;
    const googleUser = await GoogleAuth.signIn() as any;
    this.trigger_google_login(googleUser.authentication.idToken);
    /*
    console.log('googleUser.authentication.idToken: ', googleUser.authentication.idToken);
    console.log('my user: ', googleUser);
    console.log('authentication: ', googleUser.authentication);
    console.log('googleUser.authentication.accessToken: ', googleUser.authentication.accessToken);
    console.log('googleUser.authentication.idToken: ', googleUser.authentication.idToken);

    console.log('googleUser.email: ', googleUser.email);
    
    console.log('googleUser.name: ', googleUser.name);
    console.log('googleUser.displayName: ', googleUser.displayName);

    console.log('googleUser.imageUrl: ', googleUser.imageUrl);
    */ 
  }

  async trigger_google_login(google_token:any) {
    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
    });
    loading.present();

    const formData = new FormData();    
    formData.append('login_plateformname', 'google');
    formData.append('token', google_token);
    formData.append('country_id', this.selected_country_id.toString());
    formData.append('lang', this.lang.current_lang);

    formData.append('notification_token', this.device_fcmtoken);
    formData.append('notification_tokentype', this.device_fcmtokentype);
    formData.append('muserlsignature_device', this.selected_devicename); 

    this.http.post(this.env.API_URL+'socialmedia', formData).subscribe(
      (responose) => {
        
        // console.log("responose responose responose responose responose responose");
        // console.log(responose);

        if(responose['active_referencecodeblock'], responose['muser_result'], responose['muserlsignature_signature']) {
          this.storage.set('muser_result', responose['muser_result']);
          this.storage.set('muserlsignature_signature', responose['muserlsignature_signature']).then(()=>{
            this.setupStorageVariables();

            let active_referencecodeblock = responose['active_referencecodeblock'];
            if(active_referencecodeblock == '1') {

              this.__active_referencecodeblock = 1;
              this.muser_referencecode = '';
              this.__active_referencecodeaction = 'default';

            } else {
              this.trigger_successlogin();
            }

          });
        } else {
          this.alertService.error('', 'Try again.');          
        }
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
            this.alertService.error('', this.lang.v.Error_to_connect);
        } else {            
            this.alertService.error('', error.error.messagedetail);
        }
      }
    );
  } 

  async getCurrentState() {
  } 

  async trigger_fb_login(fb_token:any) {
    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
    });
    loading.present();
    const formData = new FormData();
    formData.append('login_plateformname', 'facebook');
    formData.append('token', fb_token);
    formData.append('country_id', this.selected_country_id.toString());
    formData.append('lang', this.lang.current_lang);

    formData.append('notification_token', this.device_fcmtoken);
    formData.append('notification_tokentype', this.device_fcmtokentype);
    formData.append('muserlsignature_device', this.selected_devicename); 


    this.http.post(this.env.API_URL+'socialmedia', formData).subscribe(
      (responose) => {
        // console.log("responose responose responose responose responose responose");
        // console.log(responose);
        if(responose['active_referencecodeblock'], responose['muser_result'], responose['muserlsignature_signature']) {
          this.storage.set('muser_result', responose['muser_result']);
          this.storage.set('muserlsignature_signature', responose['muserlsignature_signature']).then(() => {
            this.setupStorageVariables();

            let active_referencecodeblock = responose['active_referencecodeblock'];
            if(active_referencecodeblock == '1') {

              this.__active_referencecodeblock = 1;
              this.muser_referencecode = '';
              this.__active_referencecodeaction = 'default';

            } else {
              this.trigger_successlogin();
            }

          });
        } else {
          this.alertService.error('', 'Try again.');          
        }
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
            
            this.alertService.error('', this.lang.v.Error_to_connect);
        } else {
            
            this.alertService.error('', error.error.messagedetail);
        }
      }
    );
  }

  trigger_successlogin() {
    if(this.__active_referencecodepage == 'deleteaccount') {
      this.navCtrl.navigateRoot('/applyfordeleteaccount/'+this.uniqid('aupdated-login'));

    } else if(this.__active_referencecodepage == 'asking-login') {
      this.navCtrl.navigateRoot('/landing-shoplist/'+this.uniqid('aupdated-login'));

    } else if(this.__active_referencecodepage == 'ncart') {
      this.navCtrl.navigateRoot('/ncart/'+this.uniqid('ncart-login'));

    } else if(this.__active_referencecodepage == 'nprofile') {
      this.navCtrl.navigateRoot('/nprofile/'+this.uniqid('ncart-login'));

    } else {
      this.navCtrl.navigateRoot('/nprofile/'+this.uniqid('ncart-login'));
    }
  }

  async loadUserData() {
    const url = `https://graph.facebook.com/${this.token.userId}?fields=id,name,picture.width(720),birthday,email&access_token=${this.token.token}`;
    this.http.get(url).subscribe(res => {
      //this.user = res;
      console.log('Login Successfully',JSON.stringify(res));
    });
  }

  async signOut(): Promise<void> {
    console.log('Logout Successfully');
  }

  close_referencecodeblock() {
    this.__active_referencecodeblock = 0;
    this.muser_referencecode = '';
    this.__active_referencecodeaction = 'default';

    this.trigger_successlogin();
  } 

  update_referencecode() {
    this.__active_referencecodeerror = '';
    this.__active_referencecodeaction = 'loading';
    const formData = new FormData();
    formData.append('without_branch', '1');
    formData.append('signature', this.selected_signature.toString());
    formData.append('pointer_latitude', this.mylocation_latitude.toString());
    formData.append('pointer_longitude', this.mylocation_longitude.toString());
    formData.append('mylocation_name', this.mylocation_name.toString());

    formData.append('muser_validation', '1');
    formData.append('muser_id', this.muser_result.muser_id.toString());
    formData.append('muserlsignature_signature', this.muserlsignature_signature.toString());

    formData.append('muser_updatereferencecode', '1');
    formData.append('muser_referencecode', this.muser_referencecode);

    formData.append('lang', this.lang.current_lang);

    this.http.post(this.env.API_URL+'shop', formData).subscribe(
      () => {
        this.__active_referencecodeaction = 'completed';
        setTimeout(() => {
          this.close_referencecodeblock();
        }, 1000);
      },
      error => {
        this.__active_referencecodeaction = 'default';
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
            
            this.alertService.error('', this.lang.v.Error_to_connect);
        } else {
            //
            this.alertService.error('', error.error.messagedetail);
            this.__active_referencecodeerror = error.error.messagedetail;
        }
      }
    );
  } 

  validatelocationcountry(getResponse:any):number {
    let current_country_name = '';
    let current_country_id = 0;

    getResponse['results'].forEach((element:any) => {
      if(element.types[0] == 'country') {
        current_country_name = element.formatted_address;
      }
    });


    if(current_country_name == 'Pakistan' || current_country_name == 'pakistan') {
      current_country_id = 167;

    } else if(current_country_name == 'Sudan' || current_country_name == 'sudan') {
      current_country_id = 211;

    } else if(current_country_name == 'Spain' || current_country_name == 'spain') {
      current_country_id = 209;

    } else if(current_country_name == 'Norway' || current_country_name == 'norway') {
      current_country_id = 165;

    } else if(current_country_name == 'Saudi Arabia' || current_country_name == 'saudi arabia') {
      current_country_id = 194;

    } else if(current_country_name == 'United Arab Emirates' || current_country_name == 'united arab emirates') {
      current_country_id = 234;

    } else if(current_country_name == 'Turkey' || current_country_name == 'turkey') {
      current_country_id = 228;

    }
    return current_country_id;
  }


  get_locationcountryname(getResponse:any) {
    let current_country_name = '';
    getResponse['results'].forEach((element:any) => {
      if(element.types[0] == 'country') {
        current_country_name = element.formatted_address;
      }
    });
    return current_country_name;
  }


  call_locationresult(data:any):any {
    let current_country_id = this.validatelocationcountry(data);
    let current_country_name = this.get_locationcountryname(data);

    let current_city_name = '';
    data['results'].forEach((element:any) => {
      if(current_country_id == 167 || current_country_id == 211 || current_country_id == 209 || current_country_id == 194) {
        if(element.types[0] == 'administrative_area_level_2') {
          current_city_name = element.formatted_address;
        }
      } else {
        if(element.types[0] == 'administrative_area_level_1') {
          current_city_name = element.formatted_address;
        }
      }
    });

    let mylocation_latitude = data['results'][0]['geometry']['location']['lat'];
    let mylocation_longitude = data['results'][0]['geometry']['location']['lng'];
    let mylocation_name = data['results'][0]['formatted_address'];

    let responseData:any = [];
    responseData['current_city_name'] = current_city_name;
    responseData['current_country_name'] = current_country_name;
    responseData['current_country_id'] = current_country_id;
    responseData['mylocation_latitude'] = mylocation_latitude;
    responseData['mylocation_longitude'] = mylocation_longitude;
    responseData['mylocation_name'] = mylocation_name;
    return responseData;
  }

  
  async reset_logindetail(mylocation_latitude:any, mylocation_longitude:any, mylocation_name:any, selected_country_id:any, selected_country_name:any, lgcity_id:any, lgcity_name:any, slocationzone_id:any, slocationzone_reference:any, mylocation_tag:any) {
    let alert = this.alertController.create({
      message: this.lang.v.switch_to_new_country_msg,
      buttons: [
        {
          text: this.lang.v.No,
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        },
        {
          text: this.lang.v.Yes,
          handler: () => {
            this.storage.remove('saved_locationslist');
            this.storage.remove('muser_id');
            this.storage.remove('muser_result').then(() => {
              this.select_myLocationDetail(mylocation_latitude, mylocation_longitude, mylocation_name, selected_country_id, selected_country_name, lgcity_id, lgcity_name, slocationzone_id, slocationzone_reference, mylocation_tag);
            })
          }
        }
      ]
    });
    (await alert).present();
  }


  appleLogin(_pagereference:string) {
    this.__active_referencecodepage = _pagereference;
    // console.log(" + device_fcmtoken + ");
    // console.log(this.device_fcmtoken);
    // return false;
    this.signInWithApple.signin({
      requestedScopes: [
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
        ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
      ]
    })
    .then((res: AppleSignInResponse) => {
      // console.error(res);
      // console.error(res.identityToken);
      // console.error('Send token to apple for verification: ' + res.identityToken);
      // console.error('ssss');
      // console.error(res);
      // console.error(res);

      // console.log('ssss');
      // console.log(res.identityToken);
      // console.log(res.user);
      // console.log(res.fullName.givenName);
      // console.log(res.fullName.familyName);
      
      if(res.identityToken) {
        let fullName = '';
        if(res.fullName.givenName) {
          fullName += res.fullName.givenName;
        }
        if(res.fullName.familyName) {
          fullName += ' '+res.fullName.familyName;
        }
        this.trigger_apple_login(res.identityToken, res.user, fullName);
      }
    })
    .catch((error: AppleSignInErrorResponse) => {
      // alert(error.code + ' ' + error.localizedDescription);
      console.error(error);
      this.alertService.error('', this.lang.v.Login_with_apple_fail_message);
    });
  }

  async trigger_apple_login(token:any, tokenuser:any, tokenname:any) {
    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
    });
    loading.present();

    const formData = new FormData();    
    formData.append('login_plateformname', 'apple');
    formData.append('token', token);
    formData.append('tokenuser', tokenuser);
    formData.append('tokenname', tokenname);
    formData.append('country_id', this.selected_country_id.toString());
    formData.append('lang', this.lang.current_lang);

    formData.append('notification_token', this.device_fcmtoken);
    formData.append('notification_tokentype', this.device_fcmtokentype);
    formData.append('muserlsignature_device', this.selected_devicename); 

    this.http.post(this.env.API_URL+'socialmedia', formData).subscribe(
      (responose) => {
        // console.log("responose responose responose responose responose responose");
        // console.log(responose);

        if(responose['active_referencecodeblock'], responose['muser_result'], responose['muserlsignature_signature']) {
          this.storage.set('muser_result', responose['muser_result']);
          this.storage.set('muserlsignature_signature', responose['muserlsignature_signature']).then(()=>{
            this.setupStorageVariables();

            let active_referencecodeblock = responose['active_referencecodeblock'];
            if(active_referencecodeblock == '1') {

              this.__active_referencecodeblock = 1;
              this.muser_referencecode = '';
              this.__active_referencecodeaction = 'default';

            } else {
              this.trigger_successlogin();
            }

          });
        } else {
          this.alertService.error('', 'Try again.');          
        }
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
            
            this.alertService.error('', this.lang.v.Error_to_connect);
        } else {
            
            this.alertService.error('', error.error.messagedetail);
        }
      }
    );
  }

  set_walkingcustomerparameters(walkingcustomerapp_lang:string, walkingcustomerapp_vendortype:string, walkingcustomerapp_vendorid:string, walkingcustomerapp_vendorurl:string) {
    this.walkingcustomerapp_lang = walkingcustomerapp_lang;
    this.walkingcustomerapp_vendortype = walkingcustomerapp_vendortype;
    this.walkingcustomerapp_vendorid = walkingcustomerapp_vendorid;
    this.walkingcustomerapp_vendorurl = walkingcustomerapp_vendorurl;
  }
  walkingcustomerapp_homelink() {
    return '/'+this.walkingcustomerapp_lang+'/'+this.walkingcustomerapp_vendortype+'/'+this.walkingcustomerapp_vendorid+'/'+this.walkingcustomerapp_vendorurl;
  }

  walkingcustomerapp_cartlink() {
    return '/'+this.walkingcustomerapp_lang+'/'+this.walkingcustomerapp_vendortype+'/'+this.walkingcustomerapp_vendorid+'/'+this.walkingcustomerapp_vendorurl+'/cart';
  }

  walkingcustomerapp_termslink() {
    return '/'+this.walkingcustomerapp_lang+'/'+this.walkingcustomerapp_vendortype+'/'+this.walkingcustomerapp_vendorid+'/'+this.walkingcustomerapp_vendorurl+'/terms';
  }

  walkingcustomerapp_policylink() {
    return '/'+this.walkingcustomerapp_lang+'/'+this.walkingcustomerapp_vendortype+'/'+this.walkingcustomerapp_vendorid+'/'+this.walkingcustomerapp_vendorurl+'/policy';
  }

  walkingcustomerapp_orderlink() {
    return '/'+this.walkingcustomerapp_lang+'/'+this.walkingcustomerapp_vendortype+'/'+this.walkingcustomerapp_vendorid+'/'+this.walkingcustomerapp_vendorurl+'/orders';
  }

  trigger_urlaction(lpbanner_id:any, deliverytype_id:any, grouptype_id:any, lpbanner_url:any) {

    this.selected_searchtext = '';
    
    if(this.helper.validate_valueexistIncNum(deliverytype_id)) {
      this.storage.set('quotation_deliverytype', deliverytype_id);
      this.quotation_deliverytype = deliverytype_id;
    }

    if(this.helper.validate_valueexistIncNum(grouptype_id)) {
      this.storage.set('selected_grouptype', grouptype_id);
      this.selected_grouptype = grouptype_id;
    }

    this.storage.set('selected_lpbanner', lpbanner_id);
    this.selected_lpbanner = lpbanner_id;

    if(this.helper.validate_valueexistIncNum(lpbanner_url)) {

      if(lpbanner_url.substring(0, 9) == 'shoplink/') {
        return;
        // let x_url = lpbanner_url.split("/");
        // if(x_url[1] && x_url[2]) {
        //   let branch_id = x_url[1];
        //   let cmp_id = x_url[2];
        //   this.active_branch_id = branch_id;
        //   this.active_cmp_id = cmp_id;
        //   this.storage.set('selected_branchdata', this.active_branch_id).then(() => {
        //     this.navCtrl.navigateRoot('/shop/'+this.uniqid());
        //   })
        // }
      }

      this.navCtrl.navigateRoot(lpbanner_url);
    } else {
      this.navCtrl.navigateRoot('/shops-list/'+this.uniqid('surl'));        
    }
  }


  get_grouptypeslug(grouptype_id:any=0) {
    if(grouptype_id == 1) { return 'order-anything'; }
    else if(grouptype_id == 2) { return 'restaurant'; }
    else if(grouptype_id == 3) { return 'home-chef'; }
    else if(grouptype_id == 4) { return 'mart'; }
    else if(grouptype_id == 5) { return 'shop'; }
    else if(grouptype_id == 6) { return 'hawker'; }
    else if(grouptype_id == 7) { return 'happy-hour'; }
    else if(grouptype_id == 8) { return 'catering'; }
    else if(grouptype_id == 9) { return 'kids-activity'; } 
    else if(grouptype_id == 10) { return 'pharmacy'; } 
    else if(grouptype_id == 11) { return 'bakers'; }
    else { return 'vendor'; }
  }

  counter(data: any) {
    let i = parseFloat(data);
    return new Array(i);
  }


  padZero(n:number) {
    return n<10 ? '0'+n : n
  }


  calculate_deliverytime(_branch_deliverytimevalue:number, _branch_orderreadytime:number, distance:number) {
    let branch_deliverytimevalue = parseFloat(_branch_deliverytimevalue.toString());
    let cTime = branch_deliverytimevalue * parseFloat(distance.toString()); 

    cTime = cTime + parseFloat(_branch_orderreadytime.toString());

    let fTime:any = parseFloat(cTime.toString()).toFixed(0);
    let xlastDigits = fTime % 10;

    let different:number = 0;

    if(xlastDigits == 0) {
      // 0

    } else if(xlastDigits == 5) {
      // 0

    } else if(xlastDigits > 7) {
      different = 10 - xlastDigits;
      
    } else if(xlastDigits > 5) {
      different = 5 - xlastDigits;

    } else if(xlastDigits > 2) {
      different = 5 - xlastDigits;
      
    } else {
      different = 0 - xlastDigits;
    }
    fTime = parseFloat(fTime) + different;
    //console.log('The last digit of ', cTime, ' is ', xlastDigits, 'diff', different, 'fTime', fTime);

    let _fTime = Math.ceil(fTime);
    if(_fTime < 119) {
      return _fTime+' '+this.lang.v.minutes;
    } else if(_fTime < 1439) {
      return Math.ceil(fTime/60)+' '+this.lang.v.hours;
    } else {
      return this.lang.v.next_day;
    }
  }

  select_vendor(vendor:any, enablesignature:boolean=false, eUrlParameters:any="", validate_deliverytype:boolean=false) {

    if(validate_deliverytype) {
      if(vendor.branch_defaultdeliverytype && vendor.branch_defaultdeliverytype != 0) {
        let deliverytype_id = parseInt(vendor.branch_defaultdeliverytype);
        this.storage.set('quotation_deliverytype', deliverytype_id);
        this.quotation_deliverytype = deliverytype_id;
      }
    }

    if(this.quotation_deliverytype == 4 && vendor.branch_tablebookingdisablemenu == 1) {

      this.storage.remove('cartitems_list');
      this.storage.set('selected_branchdata', vendor).then(()=> {
        this.setupStorageVariables();
        setTimeout(() => {
          this.navCtrl.navigateRoot('ncart/'+this.uniqid());
        }, 100);

      });
    } else {
      if(vendor.branch_url) {
        let get_grouptypeslug = this.get_grouptypeslug(vendor.grouptype_id);
        let navUrlPath = '/'+get_grouptypeslug+'/'+vendor.branch_url;
        if(enablesignature) { navUrlPath += '/'+this.uniqid('nup'); }
        if(eUrlParameters) { navUrlPath += eUrlParameters; }
        this.navCtrl.navigateRoot(navUrlPath);
      } else {
        alert("Vendor url error.");
      }
    }
  }


  async setup_location(setuplocation:string, mylocation_tag:string, Response:any): Promise<any> {

    this.setuplocation_actionname = setuplocation;

    let locationresult = this.call_locationresult(Response);

    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
    });

    loading.present();

    const formData = new FormData();
    formData.append('lgcity_name', locationresult['current_city_name']);
    formData.append('pointer_latitude', locationresult['mylocation_latitude']);
    formData.append('pointer_longitude', locationresult['mylocation_longitude']);
    this.http.post(this.env.API_URL+'configcity', formData).subscribe(
      (response: Response) => {

        loading.dismiss();

        if(response['lgcity_id']) {

          let mylocation_latitude = locationresult['mylocation_latitude'];
          let mylocation_longitude = locationresult['mylocation_longitude'];
          let mylocation_name = locationresult['mylocation_name'];
          let selected_country_id = locationresult['current_country_id'];
          let selected_country_name = locationresult['current_country_name'];
          let current_city_name = locationresult['current_city_name'];  

          let selected_lgcity_id = response['lgcity_id'];
          let slocationzone_id = response['slocationzone_id'];
          let slocationzone_reference = response['slocationzone_reference'];
    
          if(this.selected_country_id == undefined || this.selected_country_id == null || this.selected_country_id == 0 || this.selected_country_id == selected_country_id ) {
            this.select_myLocationDetail(mylocation_latitude, mylocation_longitude, mylocation_name, selected_country_id, selected_country_name, selected_lgcity_id, current_city_name, slocationzone_id, slocationzone_reference, mylocation_tag);
            loading.dismiss();
          } else {
            this.reset_logindetail(mylocation_latitude, mylocation_longitude, mylocation_name, selected_country_id, selected_country_name, selected_lgcity_id, current_city_name, slocationzone_id, slocationzone_reference, mylocation_tag);
            loading.dismiss();
          }
        }        
      },
      error => {
        loading.dismiss();
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
          this.alertService.error('', this.lang.v.Error_to_connect);
        } else {
          this.alertService.error('', error.error.messagedetail);
        }
      }
    );
  }

  async setup_newlocation(setuplocation:string, mylocation_tag:string, response:any): Promise<any> { 
    
    this.setuplocation_actionname = setuplocation;

    let mylocation_latitude = response['mylocation_latitude'];
    let mylocation_longitude = response['mylocation_longitude'];
    let mylocation_name = response['mylocation_name'];
    
    let country_id = response['country_id'];
    let country_name = response['country_name'];
    let lgcity_id = response['lgcity_id'];
    let lgcity_name = response['lgcity_name'];
    let slocationzone_id = response['slocationzone_id'];
    let slocationzone_reference = response['slocationzone_reference'];

    if(!mylocation_tag && response['neighborhood_name']) {
      mylocation_tag = response['neighborhood_name'];
    }

    if(!mylocation_tag && (setuplocation == 'general' || setuplocation == 'ncart' || setuplocation == 'directshop')) { 
      mylocation_tag = this.helper.customAddress(mylocation_name, mylocation_tag);
    } 

    if(this.selected_country_id == undefined || this.selected_country_id == null || this.selected_country_id == 0 || this.selected_country_id == country_id ) {
      this.select_myLocationDetail(mylocation_latitude, mylocation_longitude, mylocation_name, country_id, country_name, lgcity_id, lgcity_name, slocationzone_id, slocationzone_reference, mylocation_tag);
    } else {
      this.reset_logindetail(mylocation_latitude, mylocation_longitude, mylocation_name, country_id, country_name, lgcity_id, lgcity_name, slocationzone_id, slocationzone_reference, mylocation_tag);
    }
  }



  async select_myLocationDetail(mylocation_latitude:any, mylocation_longitude:any, mylocation_name:any, selected_country_id:any, selected_country_name:any, lgcity_id:any, lgcity_name:any, slocationzone_id:any, slocationzone_reference:any, mylocation_tag:any) {

    if(this.helper.validate_valueexist(mylocation_tag)) {

      let saved_locationslist: any = [];
      let location_record = {
        mylocation_latitude: mylocation_latitude,
        mylocation_longitude: mylocation_longitude,
        mylocation_name: mylocation_name,
        selected_country_id: selected_country_id,
        selected_country_name: selected_country_name,
        selected_lgcity_id: lgcity_id,
        selected_lgcity_name: lgcity_name,
        selected_slocationzone_id: slocationzone_id,
        selected_slocationzone_reference: slocationzone_reference,
        mylocation_tag: mylocation_tag,
      };

      saved_locationslist.push(location_record);

      this.storage.get('saved_locationslist').then((data) => {
        if(data === null || data === undefined) {
          this.storage.set('saved_locationslist', JSON.stringify(saved_locationslist));
        } else {
          let called_saved_locationslist:any = JSON.parse(data);

          let called_saved_locationslist_length = called_saved_locationslist.length;
          if(called_saved_locationslist_length > 10) {
            called_saved_locationslist.splice(10, 1);
          }

          let updateableindex_locationalreadyexist = called_saved_locationslist.findIndex((record:any) => selected_country_id == record.selected_country_id && mylocation_name == record.mylocation_name);
          if(updateableindex_locationalreadyexist != -1) {
            called_saved_locationslist.splice(updateableindex_locationalreadyexist, 1);
          }

          let updateableindex_savelocationtag = called_saved_locationslist.findIndex((record:any) => mylocation_tag == record.mylocation_tag);
          if(updateableindex_savelocationtag != -1) {
            called_saved_locationslist.splice(updateableindex_savelocationtag, 1);
          }

          called_saved_locationslist.unshift(location_record);
          this.storage.set('saved_locationslist', JSON.stringify(called_saved_locationslist));       
        }
      });
    }

    if(this.setuplocation_actionname == 'ridepickuplocation' || this.setuplocation_actionname == 'editride_pickuplocation') {
     
      this.storage.set('ridepickup_locationlatitude', mylocation_latitude);
      this.storage.set('ridepickup_locationlongitude', mylocation_longitude);
      this.storage.set('ridepickup_locationtag', mylocation_tag);
      this.storage.set('ridepickup_countryid', selected_country_id);
      this.storage.set('ridepickup_countryname', selected_country_name);
      this.storage.set('ridepickup_lgcityid', lgcity_id);
      this.storage.set('ridepickup_lgcityname', lgcity_name);
      
      this.storage.set('ridepickup_locationname', mylocation_name).then(() => {
        this.setupStorageVariables();
        if(this.setuplocation_actionname == 'ridepickuplocation'){
          this.navCtrl.navigateRoot('/ridedropoff-location');
        } else{
          this.navCtrl.navigateRoot('/ride-booked/'+this.uniqid());
        }
      });

    } else if(this.setuplocation_actionname == 'ridedropofflocation' || this.setuplocation_actionname == 'editride_dropofflocation') {
      
      this.storage.set('ridedropoff_locationlatitude', mylocation_latitude);
      this.storage.set('ridedropoff_locationlongitude', mylocation_longitude);
      this.storage.set('ridedropoff_locationtag', mylocation_tag);
      this.storage.set('ridedropoff_countryid', selected_country_id);
      this.storage.set('ridedropoff_countryname', selected_country_name);
      this.storage.set('ridedropoff_lgcityid', lgcity_id);
      this.storage.set('ridedropoff_lgcityname', lgcity_name);
      
      this.storage.set('ridedropoff_locationname', mylocation_name).then(() => {
        this.setupStorageVariables();
        if(this.setuplocation_actionname == 'ridedropofflocation'){
          this.navCtrl.navigateRoot('/ride-booked');
        } else{
          this.navCtrl.navigateRoot('/ride-booked/'+this.uniqid());
        }
      });

    } else if(this.setuplocation_actionname == 'packagepickuplocation' || this.setuplocation_actionname == 'editpackagepickuplocation'){
      
      this.storage.set('packagepickup_locationlatitude', mylocation_latitude);
      this.storage.set('packagepickup_locationlongitude', mylocation_longitude);
      this.storage.set('packagepickup_locationtag', mylocation_tag);
      this.storage.set('packagepickup_countryid', selected_country_id);
      this.storage.set('packagepickup_countryname', selected_country_name);
      this.storage.set('packagepickup_lgcityid', lgcity_id);
      this.storage.set('packagepickup_lgcityname', lgcity_name);
      
      this.storage.set('packagepickup_locationname', mylocation_name).then(() => {
        this.setupStorageVariables();
        if(this.setuplocation_actionname == 'editpackagepickuplocation'){
          this.navCtrl.navigateRoot('/package-delivery/'+this.uniqid());
        }
      });

    }
    else if(this.setuplocation_actionname == 'packagedropofflocation' || this.setuplocation_actionname == 'editpackagedropofflocation'){
      
        this.storage.set('packagedropoff_locationlatitude', mylocation_latitude);
        this.storage.set('packagedropoff_locationlongitude', mylocation_longitude);
        this.storage.set('packagedropoff_locationtag', mylocation_tag);
        this.storage.set('packagedropoff_countryid', selected_country_id);
        this.storage.set('packagedropoff_countryname', selected_country_name);
        this.storage.set('packagedropoff_lgcityid', lgcity_id);
        this.storage.set('packagedropoff_lgcityname', lgcity_name);
        
        this.storage.set('packagedropoff_locationname', mylocation_name).then(() => {
          this.setupStorageVariables();
          if(this.setuplocation_actionname == 'editpackagedropofflocation'){
            this.navCtrl.navigateRoot('/package-delivery/'+this.uniqid());
          }
      });

    }
    else{

      this.storage.set('mylocation_index', 0);
      this.storage.set('mylocation_latitude', mylocation_latitude);
      this.storage.set('mylocation_longitude', mylocation_longitude);

      this.storage.set('selected_country_id', selected_country_id);
      this.storage.set('selected_country_name', selected_country_name);
      this.storage.set('selected_lgcity_id', lgcity_id);
      this.storage.set('selected_lgcity_name', lgcity_name);
      this.storage.set('selected_slocationzone_id', slocationzone_id);
      this.storage.set('selected_slocationzone_reference', slocationzone_reference);
      this.storage.set('mylocation_tag', mylocation_tag);

      this.storage.set('mylocation_name', mylocation_name).then(() => {

        this.setupStorageVariables();
  
        if(this.setuplocation_actionname == 'directshop') {
          if(this.selected_branchdata['branch_url'], this.selected_branchdata['branch_type']) {
            this.select_vendor(this.selected_branchdata, false, '?action=locationadded&time='+this.current_datetime);
            return;
          }
        }
  
        if(this.setuplocation_actionname == 'ncart') {
          if(this.selected_branchdata['branch_url']) { 
            this.navCtrl.navigateRoot('/ncart/'+this.selected_branchdata['branch_url']+'?action=locationadded');
          } else {
            this.navCtrl.navigateRoot('/ncart/'+this.uniqid('cart'));
          }
          return;
        }
  
        if(this.muser_result == undefined || this.muser_result == null) {
          this.navCtrl.navigateRoot('/asking-login/'+this.uniqid('asking-login'));
        } else { 
   
          if(this.active_directurlpush == 'cart') {
            this.active_directurlpush = '';
            this.navCtrl.navigateRoot('/ncart/'+mylocation_latitude+'-'+mylocation_longitude);
          } else {
            this.navCtrl.navigateRoot('/landing-shoplist/'+mylocation_latitude+'-'+mylocation_longitude);
          }
        }
      });

    }
  }

  returnvalue_validatenparsefloat(_value:any) {
    let value = parseFloat(_value);
    if(value < 0 || value > 0) {
      return value;
    } else {
      return 0;
    }
  }

  
  getCurrencyName(country_id: any){
    
    let currency_symbol = '';

    if(country_id == '167' || country_id == 167) {
      currency_symbol = 'Rs';

    } else if(country_id == '211' || country_id == 211) {
      currency_symbol = '£';
      
    } else if(country_id == '209' || country_id == 209) {
      currency_symbol = '€';

    } else if(country_id == '165' || country_id == 165) {
      currency_symbol = 'kr';

    } else if(country_id == '194' || country_id == 194) {
      currency_symbol = '﷼';

    } else if(country_id == '234' || country_id == 234) {
      currency_symbol = 'د.إ';

    } else if(country_id == '228' || country_id == 228) {
      currency_symbol = '₺';

    }
    return currency_symbol;
  }

  formate_datetime(MySQLDate:any) {
    return new Date(MySQLDate.replace(/\s+/g, 'T'));
  }

  dir() {
    if(this.lang.current_lang == 'ar' || this.lang.current_lang == 'ur') {
      return 'rtl';
    } else {
      return 'ltr';
    }
  } 


}
