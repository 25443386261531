import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class EnvService {

  aqrablocation_latitude : any = 33.504040;
  aqrablocation_longitude : any = 73.903286;

  foodstedlocation_latitude : any = 58.970167;
  foodstedlocation_longitude : any = 5.733402;

  ANDROID_APPLINK = 'https://play.google.com/store/apps/details?id=com.foodsted.apps&hl=en&gl=US';
  IOS_APPLINK = 'https://itunes.apple.com/us/app/foodsted/id1530698059';
  
  //IOS_APPLINK = 'https://apps.apple.com/us/app/foodsted/id1530698059';
  // IOS_APPLINK = 'https://itunes.apple.com/us/app/keynote/id361285480?mt=8';
  // IOS_APPLINK = 'itms-apps://itunes.apple.com/br/app/example-app/id1234567890?mt=8';
  //API_URL = 'http://192.168.0.55:8080/knet/laravel-project/QHSE/public/api/';
  //SOURCE_URL = 'http://192.168.0.55:8080/knet/laravel-project/QHSE/public/';
  
  //API_URL = 'http://192.168.1.101:8080/knet/laravel-project/QHSE/public/api/';
  //SOURCE_URL = 'http://192.168.1.101:8080/knet/laravel-project/QHSE/public/';

  //API_URL = 'http://127.0.0.1:8080/knet/laravel-project/QHSE/public/api/';
  //SOURCE_URL = 'http://127.0.0.1:8080/knet/laravel-project/QHSE/public/';

  //API_URL = 'https://apps.sahl24.com/api/';
  //SOURCE_URL = 'https://apps.sahl24.com/';

  //API_URL = 'http://127.0.0.1:8080/knet/laravel-project/api/cart/1.1/public/api/';
  //SOURCE_URL = 'http://127.0.0.1:8080/knet/laravel-project/QHSE/public/';

  //API_URL = 'http://192.168.0.103:8080/knet/laravel-project/api/cart/1.1/public/api/';
  //SOURCE_URL = 'http://192.168.0.103:8080/knet/laravel-project/QHSE/public/';

  //API_URL = 'http://192.168.43.239:8080/knet/laravel-project/api/cart/1.1/public/api/';
  //SOURCE_URL = 'http://192.168.43.239:8080/knet/laravel-project/QHSE/public/';
  // http://192.168.0.106:8080/

  //API_URL = 'http://192.168.0.106:8080/knet/laravel-project/api/cart/1.1/public/api/';
  //SOURCE_URL = 'http://192.168.0.106:8080/knet/laravel-project/QHSE/public/';

  //API_URL = 'http://192.168.100.10:8080/laravel/api/checkout_aa/public/api/';
  //SOURCE_URL = 'http://192.168.100.10:8080/laravel/backoffice/public/';

  // API_URL = 'http://192.168.0.105:8080/laravel/api/checkout_aa/public/api/';
  // SOURCE_URL = 'http://192.168.0.105:8080/laravel/backoffice/public/';
  // PAYMENT_URL = 'http://192.168.0.105:8080/laravel/api/payment_aa/public/';  
   
  // API_URL = 'http://192.168.0.160:8000/api/';
  // SOURCE_URL = 'https://apps.sahl24.com/';
  // PAYMENT_URL = 'https://payment.foodsted.com/api/payment/aa/';

  // API_URL = 'http://192.168.0.200:8080/LARAVEL/checkout/public/api/';
  // SOURCE_URL = 'https://apps.sahl24.com/';
  // PAYMENT_URL = 'https://apps.foodsted.com/api/payment/aa/';

  // API_URL = 'http://192.168.0.160:8080/laravel/api/checkout_aa/public/api/';
  // SOURCE_URL = 'http://192.168.0.160:8080/laravel/backoffice/public/';
  // PAYMENT_URL = 'https://apps.foodsted.com/api/payment/aa/'; 

  // API_URL = 'http://192.168.0.109:8080/laravel/api/checkout_aa/public/api/';
  // SOURCE_URL = 'http://192.168.0.109:8080/laravel/backoffice/public/';
  // PAYMENT_URL = 'http://192.168.0.160:8080/laravel/api/payment_aa/public/';

  // API_URL = 'http://localhost:8000/api/'; 
  // SOURCE_URL = 'https://apps.sahl24.com/';  
  // PAYMENT_URL = 'https://payment.foodsted.com/api/payment/aa/';
  
  // API_URL = 'http://localhost:8080/laravel/api/checkout_aa/public/api/';
  // SOURCE_URL = 'https://apps.sahl24.com/';
  // PAYMENT_URL = 'http://localhost:8080/laravel/api/payment_aa/public/';

  // API_URL = 'http://192.168.0.160:1111/api/';
  // SOURCE_URL = 'https://apps.sahl24.com/';
  // PAYMENT_URL = 'https://payment.foodsted.com/api/payment/aa/';

  API_URL = 'https://apps.foodsted.com/api/foodsted/ai/api/';
  SOURCE_URL = 'https://apps.sahl24.com/'; 
  PAYMENT_URL = 'https://payment.foodsted.com/api/payment/aa/';

  FOODSTED_ORDERNOW = 'https://ordernow.foodsted.no/';
  AQRAB_ORDERNOW = 'https://ordernow.aqrab.net/';

  active_app_android_version = '2.0.4';
  active_app_ios_version = '3.0.4'; 
 
  // active_company = 'foodsted';
  // active_company_title = 'FoodSted';

  active_company = 'aqrab';
  active_company_title = 'Aqrab';
  
  constructor() { }
  

}