import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
@Injectable({
  providedIn: 'root'
})
export class LangService {
  v : any = [];
  current_lang : string;

  constructor(private storage: Storage) {
    this.storage.create();
    this.storage.get('current_lang').then((data) => {
      if(data === null || data === undefined) {
        this.current_lang = 'en';
      } else {
        this.current_lang = data;
      }
      this.setup_lVE();
    });
  }

  change_lang(_lang:string) {
    this.current_lang = _lang;
    this.setup_lVE();
  } 

  setup_lVE() { 

    this.v['current_lang'] = this.current_lang;

    if(this.current_lang == 'ar') {

      this.v['Search_Nearest_Location'] = 'ابحث عن أقرب موقع';
      this.v['Confirm_Location'] = 'تأكيد الموقع';
      this.v['Select_your_position'] = 'حدد موقعك';
      this.v['Please_wait'] = 'ارجوك انتظر..';
      this.v['Selected_Location'] = 'الموقع المحدد';
      this.v['Delivery'] = 'توصيل';
      this.v['Pickup'] = 'استلم من المتجر';
      this.v['Restaurants_Nearby'] = 'المطاعم المجاورة';
      this.v['MIN'] = 'دقيقة';
      this.v['Distance'] = 'المسافة';
      this.v['reviews'] = 'مراجعة';
      this.v['Featured'] = 'متميز';
      this.v['Restaurant_types'] = 'أنواع المطاعم';
      this.v['All_Restaurants'] = 'جميع المطاعم';
      this.v['Minimum'] = 'الحد الأدنى';
      this.v['Delivery_fee'] = 'رسوم التوصيل';
      this.v['Error_to_connect'] = 'خطأ في الاتصال';
      this.v['another_restaurant_selection_message'] = 'سيتم تحويلك إلى عربة التسوق الجديدة';
      this.v['No'] = 'لا';
      this.v['Yes'] = 'نعم';
      this.v['KM'] = 'كم';
      this.v['Meter'] = 'متر';
      this.v['Miles'] = 'اميال';
      this.v['Error'] = 'خطأ';
      this.v['Unit'] = 'وحدة';
      this.v['Update'] = 'تحديث';
      this.v['Language'] = 'لغة';
      this.v['Select'] = 'يختار';
      this.v['Cancel'] = 'يلغي';
      this.v['Successfully_saved'] = 'تم الحفظ بنجاح';
      this.v['Home'] = 'الصفحة الرئيسية';
      this.v['Orders'] = 'الطلبات';
      this.v['Search'] = 'بحث';
      this.v['Map_View'] = 'عرض الخريطة';
      this.v['More'] = 'أكثر';
      this.v['My_orders'] = 'طلباتي';
      this.v['Add_your'] = 'أضف';
      this.v['Email'] = 'بريد الالكتروني';
      this.v['Mobile'] = 'رقم الجوال';
      this.v['for_view_place_order'] = 'للعرض / أطلب';
      this.v['Add_email'] = 'أضف بريدًا إلكترونيًا';
      this.v['Add_mobile'] = 'أضف رقم الجوال';
      this.v['Search_order'] = 'ترتيب البحث';
      this.v['Pending'] = 'قيد الانتظار';
      this.v['Completed'] = 'مكتمل';
      this.v['Rejected'] = 'مرفوض';
      this.v['Ready'] = 'مستعد';
      this.v['Net_Amount'] = 'مجموع الطلب';
      this.v['Type'] = 'نوع';
      this.v['Date'] = 'تاريخ';
      this.v['Detail'] = 'التفاصيل';
      this.v['Name'] = 'الاسم';
      this.v['Delivery_Time'] = 'موعد التسليم';
      this.v['Location'] = 'الموقع';
      this.v['Address'] = 'العنوان';
      this.v['Price'] = 'السعر';
      this.v['Service_fee'] = 'رسوم الخدمة';
      this.v['Net_Payable'] = 'مجموع المدفوع';
      this.v['Paid'] = 'مدفوع';
      this.v['Transaction_id'] = 'رقم المعاملة';
      this.v['Pending_payable'] = 'الدفع المعلق';
      this.v['Item'] = 'العناصر';
      this.v['Qty'] = 'الكمية';
      this.v['Amount'] = 'الكمية';
      this.v['Submit_review'] = 'إرسال المراجعة';
      this.v['Describe_your_exeriene_input_message'] = 'صِف تجربتك (اختياري)';
      this.v['Close'] = 'قريب';
      this.v['Save'] = 'حفظ';
      this.v['No_record_found'] = 'لم يتم العثور على نتائج';
      this.v['Review_saved'] = 'مراجعة محفوظة';
      this.v['Search_by_input_message'] = 'البحث عن طريق المتجر ، رمز المتجر ، اسم العنصر';
      this.v['Offer_by'] = 'عرض من قبل';
      this.v['Dinein'] = 'تناول الطعام في';
      this.v['Appoinetment'] = 'موعد';
      this.v['for_manage_profile'] = 'لإدارة الملف الشخصي';
      this.v['Profile'] = 'الملف الشخصي';
      this.v['Country'] = 'دولة';
      this.v['Location_error'] = 'خطأ في الموقع';
      this.v['Business_Request'] = 'طلب عمل';
      this.v['Business_request_submitted_msg'] = 'يتم إرسال طلبك  إلى المسؤول <br /> وسنتصل بك مرة أخرى';
      this.v['Phone_Mobile'] = 'رقم الجوال';
      this.v['City'] = 'المدينة';
      this.v['Submit_Request'] = 'تقديم الطلب';
      this.v['Successfully_request_submitted'] = 'تم إرسال الطلب بنجاح';
      this.v['Change_location'] = 'تغيير الموقع';
      this.v['Configuration'] = 'إعدادات';
      this.v['Trade_Account_request'] = 'إضافة نشاط تجاري جديد';
      this.v['Privacy_Policy'] = 'سياسة الخصوصية';
      this.v['Terms_of_Use'] = 'تعليمات الاستخدام';
      this.v['Login'] = 'تسجيل الدخول';
      this.v['My_Wallet'] = 'محفظتى';
      this.v['Announcement'] = 'يغذي';
      this.v['My_Promo_Code'] = 'الرمز الترويجي الخاص بي';
      this.v['switch_to_new_country_msg'] = 'للتبديل إلى بلد جديد ، تحتاج إلى التسجيل / تسجيل الدخول مرة أخرى';
      this.v['select_your_address'] = 'حدد عنوان التسليم الخاص بك';
      this.v['home_made'] = 'محلي الصنع';
      this.v['shops'] = 'متاجر';
      this.v['unknown_device'] = 'جهاز غير معروف';
      this.v['new_location'] = 'موقع جديد';
      this.v['deliver_to'] = 'يسلم إلى';
      this.v['restaurants'] = 'مطاعم';
      this.v['all'] = 'الجميع';
      this.v['no_result_found_in_ure_area'] = 'نأسف لإبلاغك أن هذه الخدمة غير متوفرة حاليًا في منطقتك';
      this.v['closed'] = 'مغلق';
      this.v['coming_soon'] = 'قريبا';
      this.v['km'] = 'كم';
      this.v['no_review'] = 'لا مراجعة';
      this.v['search_shop_name_label'] = 'ابحث عن اسم المتجر';
      this.v['minutes'] = 'الدقائق';
      this.v['hours'] = 'ساعات';
      this.v['next_day'] = 'اليوم التالي';
      this.v['rating'] = 'تقييم';
      this.v['ok'] = 'نعم';
      this.v['sorting'] = 'فرز';
      this.v['change'] = 'يتغيرون';
      this.v['minimum_order'] = 'الحد الأدنى للطلب';
      this.v['pickup_time'] = 'اختار الوقت';
      this.v['item_selected'] = 'عنصر محدد';
      this.v['items_selected'] = 'العناصر المحددة';
      this.v['view'] = 'رأيك';
      this.v['required'] = 'مطلوب';
      this.v['optional'] = 'اختياري';
      this.v['add_to_cart'] = 'أضف إلى السلة';
      this.v['your_order'] = 'طلبك';
      this.v['available_balance'] = 'الرصيد المتوفر';
      this.v['deduct_from_wallet'] = 'اقتطاع من المحفظة';
      this.v['apply'] = 'تطبيق';
      this.v['promo_code'] = 'رمز ترويجي';
      this.v['cart_is_empty'] = 'السلة فارغة';
      this.v['add_instruction'] = 'أضف ملاحظاتك';
      this.v['if_not_available_remove_item'] = 'إذا لم يكن متاحا ازل العنصر';
      this.v['If_not_available_cancel_the_whole_order'] = 'إذا لم يكن متاحًا ، قم بإلغاء الطلب بالكامل';
      this.v['subtotal'] = 'المجموع ';
      this.v['discount'] = 'خصم';
      this.v['sales_tax'] = 'ضريبة المبيعات';
      this.v['total'] = 'المجموع';
      this.v['add_promo_voucher'] = 'أضف قسيمة ترويجية';
      this.v['promo'] = 'برومو';
      this.v['wallet'] = 'المحفظة';
      this.v['balance'] = 'الرصيد';
      this.v['Please_login_to_place_the_order'] = 'الرجاء تسجيل الدخول لتقديم الطلب';
      this.v['order_ready_in'] = 'الطلب جاهز في';
      this.v['estimated_delivery_time'] = 'يقدر وقت التسليم';
      this.v['schedule_date_and_time'] = 'جدول';
      this.v['select_date'] = 'تاريخ';
      this.v['select_time'] = 'زمن';
      this.v['add_comments'] = 'أضف الملاحظات';
      this.v['payment_mode'] = 'طريقة الدفع';
      this.v['credit_card'] = 'بطاقة الائتمان';
      this.v['cash_on_delivery'] = 'الدفع عند الاستلام';
      this.v['place_order'] = 'قدم الطلب';
      this.v['order_successfully_placed'] = 'تم وضع الطلب بنجاح';
      this.v['change_wallet_amount'] = 'تغيير مبلغ المحفظة';
      this.v['wrong_amount'] = 'ليس لديك مبلغ كافي';
      this.v['my_profile'] = 'حسابي';
      this.v['login_for_profile_msg'] = 'تسجيل الدخول إلى حسابك لإدارة الملف الشخصي';
      this.v['code'] = 'الشفرة';
      this.v['dialing_number'] = 'رقم الاتصال';
      this.v['profile_updated'] = 'تحديث الملف الشخصي';
      this.v['my_voucher_codes'] = 'رموز القسيمة الخاصة بي';
      this.v['from_date'] = 'من التاريخ';
      this.v['to_date'] = 'حتى الآن';
      this.v['limit'] = 'الحد';
      this.v['no_voucher_available'] = 'لاتوجد قسيمة';
      this.v['copied'] = 'نسخ';
      this.v['pkr_pakistan_currency'] = 'PKR';
      this.v['sdg_sudan_currency'] = 'SDG';
      this.v['eur_spain_currency'] = 'يورو';
      this.v['nok_norway_currency'] = 'كرونة نرويجية';
      this.v['sar_saudi_currency'] = 'ريال سعودي';
      this.v['transaction_history'] = 'تاريخ المعاملات';
      this.v['announcement_not_available'] = 'الإعلان غير متوفر!';
      this.v['continue_shopping'] = 'مواصلة التسوق';
      this.v['order_amount'] = 'كمية الطلب';
      this.v['menu_amount'] = 'مبلغ القائمة';
      this.v['promo_amount'] = 'المبلغ الترويجي';
      this.v['wallet_amount'] = 'مبلغ المحفظة';
      this.v['balance_amount'] = 'مقدار وسطي';
      this.v['track_order'] = 'ترتيب المسار';
      this.v['order_cancelled'] = 'تم إلغاء الأمر او الطلب';
      this.v['order_on_hold'] = 'طلب معلق';
      this.v['order_placed'] = 'تم الطلب';
      this.v['order_accepted'] = 'تم قبول الطلب';
      this.v['order_ready'] = 'الطلب جاهز';
      this.v['order_delivered'] = 'تم تسليم الطلب';
      this.v['support_chat'] = 'دعم الدردشة';
      this.v['place_order_with_card'] = 'تقديم الطلب بالبطاقة';
      this.v['place_order_with_cod'] = 'تقديم الطلب مع COD';
      this.v['payment_not_completed_alert_msg'] = 'عملية الدفع لم تكتمل';
      this.v['payment_completed_alert_msg'] = 'شكرا! دفعتك كاملة';
      this.v['direction'] = 'لتر';
      this.v['general_textdirection'] = 'اليسار';
      this.v['general_utltextdirection'] = 'اليمين';
      this.v['feed'] = 'تغذية';
      this.v['trade_account'] = 'الحساب التجاري';
      this.v['online_order'] = 'طلب الشراء عبر الإنترنت';
      this.v['feeds'] = 'يغذي';
      this.v['select_your_delivery_address'] = 'حدد عنوان التسليم الخاص بك';
      this.v['order_completed'] = 'تم اكتمال الطلب';
      this.v['describe_your_experience_optional'] = 'صف تجربتك (اختياري)';
      this.v['order_anything'] = 'اطلب أي شيء';
      this.v['food'] = 'الطعام';
      this.v['home_chef'] = 'طاه المنزل';
      this.v['grocery'] = 'خضروات';
      this.v['howker'] = 'بائع متجول';
      this.v['shopping_cart_record'] = 'سجل عربة التسوق';
      this.v['table_booking'] = 'الحجز';
      this.v['parking'] = 'موقف سيارات';
      this.v['search_shop_name'] = 'البحث عن اسم المحل';
      this.v['all_review'] = 'كل المراجعات';
      this.v['schedule_date__time'] = 'جدول التاريخ والوقت';
      this.v['if_selected_item_is_not_available'] = 'إذا كان العنصر المحدد غير متوفر';
      this.v['remove_the_item_from_order'] = 'إزالة العنصر من الطلب';
      this.v['cancel_the_whole_order'] = 'إلغاء الأمر كامل';
      this.v['special_instruction'] = 'تعليمات خاصة';
      this.v['table_name'] = 'اسم طاولة (إذا كان معروفًا)';
      this.v['logout'] = 'تسجيل خروج';
      this.v['Walking_Customer'] = 'العميل';
      this.v['Wellcome_msg_title'] = 'مرحبا بكم في FOODSTED';
      this.v['Wellcome_msg_desc'] = 'توفير الراحة على عتبة داركم';
      this.v['Car_Dining'] = 'تناول الطعام في السيارة';
      this.v['Grocery_Shops'] = 'بقالة / متاجر';
      this.v['Howkers'] = 'الباعة المتجولون';
      this.v['ShopList_first_features'] = 'متميز';
      this.v['ShopList_second_features'] = 'متميز';
      this.v['ShopList_third_features'] = 'متميز';
      this.v['Delivery_closed'] = 'التسليم مغلق';
      this.v['Pickup_closed'] = 'بيك اب مغلق';
      this.v['Dinein_closed'] = 'مغلق Dinein';
      this.v['Table_booking_closed'] = 'تم إغلاق الحجز';
      this.v['Car_Dining_closed'] = 'طلبات المطعم مغلقة';
      this.v['Special_instruction'] = 'تعليمات خاصة';
      this.v['no_spicy'] = 'تعليمات خاصة';
      this.v['Add_more_instruction'] = 'أضف المزيد من التعليمات';
      this.v['If_selected_item_is_not_available'] = 'إذا كان العنصر المحدد غير متوفر';
      this.v['Remove_the_item_from_order'] = 'إزالة العنصر من الطلب';
      this.v['Cancel_the_whole_order'] = 'إلغاء الأمر برمته';
      this.v['Select_Item'] = 'حدد العنصر';
      this.v['Already_item_in_your_cart'] = 'العنصر بالفعل في عربة التسوق الخاصة بك';
      this.v['No_Reviews'] = 'لم يتم تقديم تعليقات';
      this.v['Add_shipping_name'] = 'أضف اسم الشحن';
      this.v['Add_shipping_mobile'] = 'إضافة الشحن المحمول';
      this.v['No_of_guest'] = 'عدد الأشخاص';
      this.v['Vehicle_name'] = 'اسم السيارة';
      this.v['Vehicle'] = 'السيارة';
      this.v['Vehicle_plate'] = 'رقم لوحة السيارة';
      this.v['Plate'] = 'طبق';
      this.v['Vehicle_color'] = 'لون السيارة';
      this.v['Color'] = 'اللون';
      this.v['Vehicle_location'] = 'موقع السيارة';
      this.v['VIPPS_Account'] = 'حساب VIPPS';
      this.v['Login_with'] = 'تسجيل الدخول مع';
      this.v['Continue'] = 'اكمل';
      this.v['Pin'] = 'دبوس';
      this.v['mobile_verification_pin'] = 'رقم التعريف الشخصي للتحقق عبر رقم الجوال';
      this.v['Enter_your_PIN_from_email'] = 'أدخل رقم التعريف الشخصي الخاص بك من البريد الإلكتروني';
      this.v['Resend_PIN_to_Mobile'] = 'إعادة إرسال PIN إلى رقم الجوال';
      this.v['Re_Generate_and_Send_PIN_to_Mobile'] = 'أعد إنشاء وإرسال رقم التعريف الشخصي إلى رقم الجوال';
      this.v['Resend_PIN_to_email'] = 'إعادة إرسال PIN إلى البريد الإلكتروني';
      this.v['Re_Generate_and_Send_PIN_to_email'] = 'أعد إنشاء وإرسال رقم التعريف الشخصي إلى البريد الإلكتروني';
      this.v['Mobile_verification_code_successfully_sent'] = 'تم إرسال رمز التحقق  بنجاح';
      this.v['Check_your_email'] = 'بريدك الالكتروني';
      this.v['Successfully_logged_in'] = 'تم تسجيله بنجاح';
      this.v['Login_Form'] = 'نموذج تسجيل الدخول';
      this.v['Login_to_continue_FoodSted'] = 'تسجيل الدخول لمواصلة';
      this.v['Enter_your_email'] = 'أدخل بريدك الإلكتروني';
      this.v['Referral_code'] = 'كود الإحالة';
      this.v['Have_a_referral_code'] = 'هل لديك رمز إحالة؟';
      this.v['Toll_free'] = 'اتصال مجاني';
      this.v['Email_verification_pin'] = 'دبوس التحقق من البريد الإلكتروني';
      this.v['January'] = 'جون';
      this.v['February'] = ' فبراير';
      this.v['March'] = 'مارس';
      this.v['April'] = 'أبريل';
      this.v['May'] = 'ماي';
      this.v['June'] = 'يونيو';
      this.v['July'] = 'يوليو';
      this.v['August'] = ' اغسطس';
      this.v['September'] = 'سبتمبر';
      this.v['October'] = 'اكتوبر';
      this.v['November'] = ' نوفمبر';
      this.v['December'] = 'ديسمبر';
      this.v['Done'] = 'منتهي';
      this.v['Order_no'] = 'لايوجد طلب';
      this.v['instruction_input_hint'] = 'على سبيل المثال تعليمات خاصة';
      this.v['services_not_available_country_message'] = 'خدماتنا غير متوفرة في مدينتك';
      this.v['select_your_country'] = 'اختر مدينتك';
      this.v['Pakistan'] = 'باكستان';
      this.v['saudi_arabia'] = 'المملكة العربية السعودية';
      this.v['norway'] = 'النرويج';
      this.v['spain'] = 'إسبانيا';
      this.v['select_your_city'] = 'اختر مدينتك';
      this.v['Delivery_Address'] = 'عنوان التسليم';
      this.v['vehicle_type'] = 'نوع السيارة';
      this.v['Other_vehicle'] = 'سيارات أخرى';
      this.v['Other_color'] = 'لون آخر';
      this.v['Select_car_location'] = 'حدد موقع السيارة';
      this.v['Account_logged_out'] = 'تم تسجيل الخروج من الحساب';
      this.v['Remarks'] = 'ملاحظات';
      this.v['Table'] = 'طاولة';
      this.v['Time'] = 'زمن';
      this.v['OR'] = 'أو';
      this.v['Skip_Now'] = 'تخطي الآن';
      this.v['Get_Your_Free_Credit'] = 'احصل على رصيدك المجاني';
      this.v['your_referral_code_is_successful'] = 'رمز الإحالة الخاص بك ناجح';
      this.v['SKIP'] = 'تخطي';
      this.v['NEXT'] = 'التالي';
      this.v['Delivery_instructions'] = 'تعليمات التوصيل';
      this.v['Enter_OTP_Code_sent_to'] = 'أدخل رمز OTP المرسل اليك';
      this.v['Cash'] = 'نقدي';
      this.v['Credit_Card_on_delivery'] = 'بطاقة الائتمان عند التسليم';
      this.v['LOGIN_WITH_FACEBOOK'] = 'تسجيل الدخول باستخدام الفيسبوك';
      this.v['LOGIN_WITH_GOOGLE'] = 'تسجيل الدخول عبر جوجل';
      this.v['LOGIN_WITH_EMAIL'] = 'تسجيل الدخول بالبريد الإلكتروني';
      this.v['Delivery_not_available_in_your_area'] = 'التوصيل غير متوفر في منطقتك. يمكنك اختيار الاستلام';
      this.v['LOGIN_WITH_PHONE_NUMBER'] = 'تسجيل الدخول باستخدام رقم الهاتف';
      this.v['Welcome'] = 'مرحبا';
      this.v['sign_up_or_login_to_continue_foodsted'] = 'قم بالتسجيل أو تسجيل الدخول لمواصلة';
      this.v['by_signing_up_you_agree_to_our'] = 'بالتسجيل فإنك توافق على';
      this.v['terms_and_conditions'] = 'الأحكام والشروط';
      this.v['and'] = 'و';
      this.v['LOGIN_WITH_MOBILE'] = 'تسجيل الدخول باستخدام الموبايل';
      this.v['min_order'] = 'كمية الطلب';
      this.v['can_you_confirm_if_this_is_your_location'] = 'يمكنك تأكيد ما إذا كان هذا هو موقعك؟';
      this.v['select_a_different_location'] = 'حدد موقعا مختلف';
      this.v['confirm_location'] = 'تأكيد الموقع';
      this.v['add_a_new_address'] = 'أضف عنوان جديدا';
      this.v['add_a_label'] = 'إضافة تسمية';
      this.v['home'] = 'الصفحة الرئيسية';
      this.v['work'] = 'العمل';
      this.v['partner'] = 'شريك';
      this.v['other'] = 'آخر';
      this.v['place_label'] = 'وضع التسمية';
      this.v['save_and_continue'] = 'حفظ واستمرار';
      this.v['find_restaurants_and_shops_near_you'] = 'العثور على المطاعم والمتاجر بالقرب منك';
      this.v['by_allowing_location_access_msg'] = 'من خلال السماح بالوصول إلى الموقع، يمكنك البحث عن إعادة المطاعم والمتاجر بالقرب منك والحصول على تسليم أكثر دقة';
      this.v['allow_location_access'] = 'السماح بالوصول إلى الموقع';
      this.v['enter_my_location'] = 'أدخل موقعي';
      this.v['deals'] = 'صفقات';
      this.v['Error_for_getting_location'] = 'السماح بالوصول إلى موقع هذا الجهاز؟';
      this.v['Login_with_apple_fail_message'] = 'خطأ في تسجيل الدخول مع Apple ، حاول مرة أخرى لاحقًا';
      this.v['login_with_apple'] = 'تسجيل الدخول مع أبل';
      this.v['JazzCash_wallet'] = 'محفظة جاز كاش';
      this.v['place_order_with_vipps'] = 'ضع النظام مع vipps';
      this.v['place_order_with_jazzcashwallet'] = 'ضع النظام مع محفظة jazzcash';
      this.v['Featuredv1'] = 'توصيل مجاني';
      this.v['Featuredv2'] = 'خصم على الاستلام';
      this.v['turkey'] = 'ديك الرومى';
      this.v['united_arab_emirates'] = 'الإمارات العربية المتحدة';
      this.v['credit_card_other'] = 'بطاقة ائتمان / أخرى';
      this.v['you_must_enter'] = 'يجب عليك إدخال';
      this.v['save_and_place_order'] = 'حفظ وتقديم الطلب';
      this.v['view_all'] = 'مشاهدة الكل';
      this.v['payment_successful'] = 'تم الدفع بنجاح';
      this.v['Welcome_to_aqrab'] = 'مرحبا بكم في AQRAB';
      this.v['delete_my_account'] = 'احذف حسابي';
      this.v['apply_for_delete_account'] = 'تقدم بطلب حذف الحساب';
      this.v['cancel_request_for_delete_account'] = 'إلغاء طلب حذف الحساب';
      this.v['are_you_sure_to_submit_a_request_to_delete_the_account'] = 'هل أنت متأكد من تقديم طلب لحذف الحساب';
      this.v['owner_name'] = 'اسم المالك';
      this.v['business_name'] = 'الاسم التجاري';
      this.v['category'] = 'فئة';
      this.v['shop_keeper_id'] = 'معرف أمين المتجر';
      this.v['shop_keeper_name'] = 'اسم صاحب المتجر';
      this.v['phone'] = 'هاتف';
      this.v['gender'] = 'جنس';
      this.v['shipping_address'] = 'عنوان الشحن';
      this.v['contact'] = 'اتصال';
      this.v['order_type'] = 'نوع الطلبات';
      this.v['guests'] = 'الأشخاص';
      this.v['adults'] = 'الكبار';
      this.v['date_and_time'] = 'التاريخ و الوقت';
      this.v['vehicle_detail'] = 'تفاصيل السيارة';
      this.v['restaurant_infomartion'] = 'معلومات المطعم';
      this.v['see_map'] = 'انظر الخريطة';
      this.v['opening_timing'] = 'توقيت الافتتاح';
      this.v['see_more_information'] = 'رؤية المزيد من المعلومات';
      this.v['search_a_place'] = 'ابحث عن مكان';
      this.v['organization_no'] = 'رقم المنظمة';
      this.v['organization_account_no'] = 'رقم حساب المؤسسة';
      this.v['message'] = 'رسالة';
      this.v['you_must_need_to_select_date'] = 'يجب عليك تحديد التاريخ';
      this.v['you_must_need_to_select_time_slot'] = 'يجب عليك تحديد الفترة الزمنية';
      this.v['slot_has_been_reserved'] = 'تم حجز الفتحة';
      this.v['enter_promo_code'] = 'إدخال الرمز الترويجي';
      this.v['seconds'] = 'ثواني';
      this.v['time_remaining'] = 'الوقت المتبقي';
      this.v['happy_hours_vendor_not_available'] = 'يفتح كل يوم بعد الساعة 21:00 <br /> حارب هدر الطعام واستمتع بالطعام الجيد السعر';
      this.v['amount_to_deduct_from_wallet'] = 'المبلغ المخصوم من المحفظة';
      this.v['order_delivery_time'] = 'طلب وقت التسليم';
      this.v['order_pickup_time'] = 'وقت استلام الطلب';
      this.v['order_car_dining_time'] = 'اطلب وقت تناول الطعام في السيارة';
      this.v['asap'] = 'في اسرع وقت ممكن';
      this.v['confirm'] = 'يتأكد';
      this.v['current_location_country_not_match_with_your_selected_location_country'] = 'بلد الموقع الحالي لا يتطابق مع بلد الموقع المحدد.';
      this.v['pickup_location'] = 'اختر موقعا';
      this.v['now'] = 'الآن';
      this.v['add_to_favourite'] = 'أضف إلى المفضلة';
      this.v['drop_off_location'] = 'موقع الإنزال';
      this.v['book_ride'] = 'كتاب ركوب';
      this.v['pickup_from'] = 'تلتقط من';
      this.v['drop_off_to'] = 'إنزال إلى';
      this.v['select_vehicle'] = 'اختر السيارة';
      this.v['ride_charges'] = 'رسوم الركوب';
      this.v['estimated_time'] = 'الوقت المقدر';
      this.v['sender_details'] = 'بيانات المرسل';
      this.v['address_detail'] = 'تفاصيل العنوان';
      this.v['sender_contact'] = 'جهة اتصال المرسل';
      this.v['receiver_details'] = 'تفاصيل المتلقي';
      this.v['address_detail'] = 'تفاصيل العنوان';
      this.v['receiver_contact'] = 'جهة اتصال المتلقي';
      this.v['package_detail'] = 'تفاصيل الحزمة';
      this.v['scheduled_time'] = 'جدول زمني';
      this.v['package_charges'] = 'رسوم الحزمة';
      this.v['instructions'] = 'تعليمات';
      this.v['add_items'] = 'إضافة عناصر';
      this.v['place_request'] = 'طلب مكان';
      this.v['rides'] = 'ركوب';
      this.v['packages'] = 'الحزم';
      this.v['total_charges'] = 'الكلفة الاجماليه';
      this.v['package_items'] = 'عناصر الحزمة';
      this.v['ride'] = 'يركب';
      this.v['package'] = 'طَرد';
      this.v['place_order_delivery'] = 'قدم الطلب';
      this.v['place_order_pickup'] = 'قدم الطلب';
      this.v['place_order_dine_in'] = 'قدم الطلب';
      this.v['place_order_car_dining'] = 'قدم الطلب';
      this.v['place_order_tablebooking_with_item'] = 'قدم الطلب';
      this.v['place_order_tablebooking_without_item'] = 'تأكيد الحجز';
      this.v['happy_hours'] = 'ساعات السعادة';
      this.v['catering'] = 'تقديم الطعام';
      this.v['general_connection_time_out_message'] = 'انقضت مهلة الاتصال';
      this.v['try_again'] = 'حاول ثانية';
      this.v['foodsted_wants_to_open_payment_provider'] = 'يريد FOODSTED فتح مزود الدفع';
      this.v['open'] = 'فتح';
      this.v['process_payment_place_order'] = 'عملية الدفع (تقديم الطلب)';
      this.v['your_booking'] = 'حجزك';
      this.v['Midnight'] = 'منتصف الليل';
      this.v['After_Midnight'] = 'بعد منتصف الليل';
      this.v['Current_Order'] = 'النظام الحالي';
      this.v['PayNow'] = 'ادفع الآن';
      



    } else {


      this.v['Search_Nearest_Location'] = 'Search Nearest Location';
      this.v['Confirm_Location'] = 'CONFIRM LOCATION';
      this.v['Select_your_position'] = 'Select your position';
      this.v['Please_wait'] = 'Please wait..';
      this.v['Selected_Location'] = 'Selected Location';
      this.v['Delivery'] = 'Delivery';
      this.v['Pickup'] = 'Pickup';
      this.v['Restaurants_Nearby'] = 'Restaurants Nearby';
      this.v['MIN'] = 'MIN';
      this.v['Distance'] = 'Distance';
      this.v['reviews'] = 'reviews';
      this.v['Featured'] = 'Featured';
      this.v['Restaurant_types'] = 'Restaurant types';
      this.v['All_Restaurants'] = 'All Restaurants';
      this.v['Minimum'] = 'Minimum';
      this.v['Delivery_fee'] = 'Delivery fee';
      this.v['Error_to_connect'] = 'Error to connect';
      this.v['another_restaurant_selection_message'] = 'You will be switched to new shopping cart';
      this.v['No'] = 'No';
      this.v['Yes'] = 'Yes';
      this.v['KM'] = 'KM';
      this.v['Meter'] = 'Meter';
      this.v['Miles'] = 'Miles';
      this.v['Error'] = 'Error';
      this.v['Unit'] = 'Unit';
      this.v['Update'] = 'Update';
      this.v['Language'] = 'Language';
      this.v['Select'] = 'Select';
      this.v['Cancel'] = 'Cancel';
      this.v['Successfully_saved'] = 'Successfully saved';
      this.v['Home'] = 'Home';
      this.v['Orders'] = 'Orders';
      this.v['Search'] = 'Search';
      this.v['Map_View'] = 'Map View';
      this.v['More'] = 'More';
      this.v['My_orders'] = 'My orders';
      this.v['Add_your'] = 'Add your sss';
      this.v['Email'] = 'Email';
      this.v['Mobile'] = 'Mobile';
      this.v['for_view_place_order'] = 'for view / place order.';
      this.v['Add_email'] = 'Add email';
      this.v['Add_mobile'] = 'Add mobile';
      this.v['Search_order'] = 'Search order';
      this.v['Pending'] = 'Pending';
      this.v['Completed'] = 'Completed';
      this.v['Rejected'] = 'Rejected';
      this.v['Ready'] = 'Ready';
      this.v['Net_Amount'] = 'Net Amount';
      this.v['Type'] = 'Type';
      this.v['Date'] = 'Date';
      this.v['Detail'] = 'Detail';
      this.v['Name'] = 'Name';
      this.v['Delivery_Time'] = 'Delivery Time';
      this.v['Location'] = 'Location';
      this.v['Address'] = 'Address';
      this.v['Price'] = 'Price';
      this.v['Service_fee'] = 'Service fee';
      this.v['Net_Payable'] = 'Net Payable';
      this.v['Paid'] = 'Paid';
      this.v['Transaction_id'] = 'Transaction id';
      this.v['Pending_payable'] = 'Pending payable';
      this.v['Item'] = 'Item';
      this.v['Qty'] = 'Qty';
      this.v['Amount'] = 'Amount';
      this.v['Submit_review'] = 'Submit review';
      this.v['Describe_your_exeriene_input_message'] = 'Describe your experience (optional)';
      this.v['Close'] = 'Close';
      this.v['Save'] = 'Save';
      this.v['No_record_found'] = 'No result found';
      this.v['Review_saved'] = 'Review saved.';
      this.v['Search_by_input_message'] = 'search by shop, shop code, item name';
      this.v['Offer_by'] = 'Offer by';
      this.v['Dinein'] = 'Dinein';
      this.v['Appoinetment'] = 'Appoinetment';
      this.v['for_manage_profile'] = 'for manage profile.';
      this.v['Profile'] = 'Profile';
      this.v['Country'] = 'Country';
      this.v['Location_error'] = 'Location error';
      this.v['Business_Request'] = 'Business Request';
      this.v['Business_request_submitted_msg'] = 'Your Business request send to admin <br /> we will contact you back';
      this.v['Phone_Mobile'] = 'Phone / Mobile';
      this.v['City'] = 'City';
      this.v['Submit_Request'] = 'Submit Request';
      this.v['Successfully_request_submitted'] = 'Successfully request submitted.';
      this.v['Change_location'] = 'Change location';
      this.v['Configuration'] = 'Configuration';
      this.v['Trade_Account_request'] = 'Add New Business';
      this.v['Privacy_Policy'] = 'Privacy Policy';
      this.v['Terms_of_Use'] = 'Terms of Use';
      this.v['Login'] = 'Login';
      this.v['My_Wallet'] = 'My Wallet';
      this.v['Announcement'] = 'Feeds';
      this.v['My_Promo_Code'] = 'My Promo code';
      this.v['switch_to_new_country_msg'] = 'To switch to new country you need to Register / Login again';
      this.v['select_your_address'] = 'Select your delivery address';
      this.v['home_made'] = 'Home Made';
      this.v['shops'] = 'Shops';
      this.v['unknown_device'] = 'Unknown device';
      this.v['new_location'] = 'New Location';
      this.v['deliver_to'] = 'Deliver to';
      this.v['restaurants'] = 'Restaurants';
      this.v['all'] = 'All';
      this.v['no_result_found_in_ure_area'] = 'We regret to inform you that this service is currently not availableÂ inÂ yourÂ area';
      this.v['closed'] = 'Closed';
      this.v['coming_soon'] = 'Coming Soon';
      this.v['km'] = 'KM';
      this.v['no_review'] = 'no review';
      this.v['search_shop_name_label'] = 'Search shop name';
      this.v['minutes'] = 'Minutes';
      this.v['hours'] = 'hours';
      this.v['next_day'] = 'Next day';
      this.v['rating'] = 'Rating';
      this.v['ok'] = 'Ok';
      this.v['sorting'] = 'Sorting';
      this.v['change'] = 'Change';
      this.v['minimum_order'] = 'Minimum order';
      this.v['pickup_time'] = 'Pickup time';
      this.v['item_selected'] = 'item selected';
      this.v['items_selected'] = 'items selected';
      this.v['view'] = 'View';
      this.v['required'] = 'Required';
      this.v['optional'] = 'Optional';
      this.v['add_to_cart'] = 'Add to cart';
      this.v['your_order'] = 'Your order';
      this.v['available_balance'] = 'Available balance';
      this.v['deduct_from_wallet'] = 'Deduct from wallet';
      this.v['apply'] = 'Apply';
      this.v['promo_code'] = 'Promo code';
      this.v['cart_is_empty'] = 'Cart is empty';
      this.v['add_instruction'] = 'Add instruction';
      this.v['if_not_available_remove_item'] = 'If not available remove item';
      this.v['If_not_available_cancel_the_whole_order'] = 'If not available cancel the whole order';
      this.v['subtotal'] = 'Subtotal';
      this.v['discount'] = 'Discount';
      this.v['sales_tax'] = 'Sales Tax';
      this.v['total'] = 'Total';
      this.v['add_promo_voucher'] = 'Add promo voucher';
      this.v['promo'] = 'Promo';
      this.v['wallet'] = 'Wallet';
      this.v['balance'] = 'Balance';
      this.v['Please_login_to_place_the_order'] = 'Please Login to place the order';
      this.v['order_ready_in'] = 'Order ready in';
      this.v['estimated_delivery_time'] = 'Estimated delivery time';
      this.v['schedule_date_and_time'] = 'Schedule';
      this.v['select_date'] = 'Date';
      this.v['select_time'] = 'Time';
      this.v['add_comments'] = 'Add comments';
      this.v['payment_mode'] = 'Payment Mode';
      this.v['credit_card'] = 'Credit card';
      this.v['cash_on_delivery'] = 'Cash on delivery';
      this.v['place_order'] = 'Place order';
      this.v['order_successfully_placed'] = 'Order successfully placed';
      this.v['change_wallet_amount'] = 'Change wallet amount';
      this.v['wrong_amount'] = 'You have insufficient amount';
      this.v['my_profile'] = 'My profile';
      this.v['login_for_profile_msg'] = 'login your account for manage profile';
      this.v['code'] = 'Code';
      this.v['dialing_number'] = 'Dialing Number';
      this.v['profile_updated'] = 'Profile updated';
      this.v['my_voucher_codes'] = 'My Voucher Codes';
      this.v['from_date'] = 'From Date';
      this.v['to_date'] = 'To Date';
      this.v['limit'] = 'Limit';
      this.v['no_voucher_available'] = 'No voucher available';
      this.v['copied'] = 'Copied';
      this.v['pkr_pakistan_currency'] = 'PKR';
      this.v['sdg_sudan_currency'] = 'SDG';
      this.v['eur_spain_currency'] = 'EUR';
      this.v['nok_norway_currency'] = 'NOK';
      this.v['sar_saudi_currency'] = 'SAR';
      this.v['transaction_history'] = 'Transaction history';
      this.v['announcement_not_available'] = 'Announcement not available!';
      this.v['continue_shopping'] = 'Continue Shopping';
      this.v['order_amount'] = 'Order Amount';
      this.v['menu_amount'] = 'Menu Amount';
      this.v['promo_amount'] = 'Promo Amount';
      this.v['wallet_amount'] = 'Wallet Amount';
      this.v['balance_amount'] = 'Balance Amount';
      this.v['track_order'] = 'Track order';
      this.v['order_cancelled'] = 'Order cancelled';
      this.v['order_on_hold'] = 'Order on hold';
      this.v['order_placed'] = 'Order placed';
      this.v['order_accepted'] = 'Order accepted';
      this.v['order_ready'] = 'Order ready';
      this.v['order_delivered'] = 'Order Delivered';
      this.v['support_chat'] = 'Support Chat';
      this.v['place_order_with_card'] = 'Place order with Card';
      this.v['place_order_with_cod'] = 'Place order with COD';
      this.v['payment_not_completed_alert_msg'] = 'Payment process not completed';
      this.v['payment_completed_alert_msg'] = 'Thank You! Your payment is complete';
      this.v['direction'] = 'ltr';
      this.v['general_textdirection'] = 'left';
      this.v['general_utltextdirection'] = 'right';
      this.v['feed'] = 'feed';
      this.v['trade_account'] = 'trade account';
      this.v['online_order'] = 'online order';
      this.v['feeds'] = 'feeds';
      this.v['select_your_delivery_address'] = 'select your delivery address';
      this.v['order_completed'] = 'order completed';
      this.v['describe_your_experience_optional'] = 'describe your experience (optional)';
      this.v['order_anything'] = 'Order anything';
      this.v['food'] = 'Food';
      this.v['home_chef'] = 'Home chef';
      this.v['grocery'] = 'grocery';
      this.v['howker'] = 'howker';
      this.v['shopping_cart_record'] = 'Shopping cart record';
      this.v['table_booking'] = 'Booking';
      this.v['parking'] = 'parking';
      this.v['search_shop_name'] = 'search shop name';
      this.v['all_review'] = 'All review';
      this.v['schedule_date__time'] = 'schedule date & time';
      this.v['if_selected_item_is_not_available'] = 'If selected item is not available';
      this.v['remove_the_item_from_order'] = 'Remove the item from order';
      this.v['cancel_the_whole_order'] = 'Cancel the whole order';
      this.v['special_instruction'] = 'Special instruction';
      this.v['table_name'] = 'Table Name (If Known)';
      this.v['logout'] = 'Logout';
      this.v['Walking_Customer'] = 'Walking Customer';
      this.v['Wellcome_msg_title'] = 'Welcome to FOODSTED';
      this.v['Wellcome_msg_desc'] = 'Delivering convenience at your doorstep';
      this.v['Car_Dining'] = 'Car Dining';
      this.v['Grocery_Shops'] = 'Grocery / Shops';
      this.v['Howkers'] = 'Hawkers';
      this.v['ShopList_first_features'] = 'Featured';
      this.v['ShopList_second_features'] = 'Featured';
      this.v['ShopList_third_features'] = 'Featured';
      this.v['Delivery_closed'] = 'Delivery closed';
      this.v['Pickup_closed'] = 'Pickup closed';
      this.v['Dinein_closed'] = 'Dinein closed';
      this.v['Table_booking_closed'] = 'Booking Closed';
      this.v['Car_Dining_closed'] = 'Car Dining closed';
      this.v['Special_instruction'] = 'Special instruction';
      this.v['no_spicy'] = 'Special instructions';
      this.v['Add_more_instruction'] = 'Add more instruction';
      this.v['If_selected_item_is_not_available'] = 'If selected item is not available';
      this.v['Remove_the_item_from_order'] = 'Remove the item from order';
      this.v['Cancel_the_whole_order'] = 'Cancel the whole order';
      this.v['Select_Item'] = 'Select Item';
      this.v['Already_item_in_your_cart'] = 'Already item in your cart';
      this.v['No_Reviews'] = 'No Reviews';
      this.v['Add_shipping_name'] = 'Add shipping name';
      this.v['Add_shipping_mobile'] = 'Add shipping mobile';
      this.v['No_of_guest'] = 'No of persons';
      this.v['Vehicle_name'] = 'Vehicle name';
      this.v['Vehicle'] = 'Vehicle';
      this.v['Vehicle_plate'] = 'Vehicle Plate No';
      this.v['Plate'] = 'Plate';
      this.v['Vehicle_color'] = 'Vehicle color';
      this.v['Color'] = 'Color';
      this.v['Vehicle_location'] = 'Vehicle location';
      this.v['VIPPS_Account'] = 'VIPPS Account';
      this.v['Login_with'] = 'Login with';
      this.v['Continue'] = 'Continue';
      this.v['Pin'] = 'Pin';
      this.v['mobile_verification_pin'] = 'mobile verification pin';
      this.v['Enter_your_PIN_from_email'] = 'Enter your PIN from email';
      this.v['Resend_PIN_to_Mobile'] = 'Resend PIN to Mobile';
      this.v['Re_Generate_and_Send_PIN_to_Mobile'] = 'Re-Generate & Send PIN to Mobile';
      this.v['Resend_PIN_to_email'] = 'Resend PIN to email';
      this.v['Re_Generate_and_Send_PIN_to_email'] = 'Re-Generate & Send PIN to email';
      this.v['Mobile_verification_code_successfully_sent'] = 'Mobile verification code successfully sent';
      this.v['Check_your_email'] = 'your email';
      this.v['Successfully_logged_in'] = 'Successfully logged';
      this.v['Login_Form'] = 'Login Form';
      this.v['Login_to_continue_FoodSted'] = 'Login to continue';
      this.v['Enter_your_email'] = 'Enter your email';
      this.v['Referral_code'] = 'Referral code';
      this.v['Have_a_referral_code'] = 'Have a referral code?';
      this.v['Toll_free'] = 'Toll free';
      this.v['Email_verification_pin'] = 'Email verification pin';
      this.v['January'] = 'January';
      this.v['February'] = 'February';
      this.v['March'] = 'March';
      this.v['April'] = 'April';
      this.v['May'] = 'May';
      this.v['June'] = 'June';
      this.v['July'] = 'July';
      this.v['August'] = 'August';
      this.v['September'] = 'September';
      this.v['October'] = 'October';
      this.v['November'] = 'November';
      this.v['December'] = 'December';
      this.v['Done'] = 'Done';
      this.v['Order_no'] = 'Order no';
      this.v['instruction_input_hint'] = 'e.g. Special instruction';
      this.v['services_not_available_country_message'] = 'Our services not available in your country';
      this.v['select_your_country'] = 'select your country';
      this.v['Pakistan'] = 'Pakistan';
      this.v['saudi_arabia'] = 'Saudi Arabia';
      this.v['norway'] = 'norway';
      this.v['spain'] = 'spain';
      this.v['select_your_city'] = 'select your city';
      this.v['Delivery_Address'] = 'Delivery Address';
      this.v['vehicle_type'] = 'vehicle type';
      this.v['Other_vehicle'] = 'Other vehicle';
      this.v['Other_color'] = 'Other color';
      this.v['Select_car_location'] = 'Select car location';
      this.v['Account_logged_out'] = 'Account logged out';
      this.v['Remarks'] = 'Remarks';
      this.v['Table'] = 'Table';
      this.v['Time'] = 'Time';
      this.v['OR'] = 'OR';
      this.v['Skip_Now'] = 'Skip Now';
      this.v['Get_Your_Free_Credit'] = 'Get Your Free Credit';
      this.v['your_referral_code_is_successful'] = 'your referral code is successful';
      this.v['SKIP'] = 'SKIP';
      this.v['NEXT'] = 'NEXT';
      this.v['Delivery_instructions'] = 'Delivery instructions';
      this.v['Enter_OTP_Code_sent_to'] = 'Enter OTP Code sent to';
      this.v['Cash'] = 'Cash';
      this.v['Credit_Card_on_delivery'] = 'Credit Card on delivery';
      this.v['LOGIN_WITH_FACEBOOK'] = 'LOGIN WITH FACEBOOK';
      this.v['LOGIN_WITH_GOOGLE'] = 'LOGIN WITH GOOGLE';
      this.v['LOGIN_WITH_EMAIL'] = 'LOGIN WITH EMAIL';
      this.v['Delivery_not_available_in_your_area'] = 'Delivery not available in your area. You can choose pickup';
      this.v['LOGIN_WITH_PHONE_NUMBER'] = 'LOGIN WITH PHONE NUMBER';
      this.v['Welcome'] = 'Welcome';
      this.v['sign_up_or_login_to_continue_foodsted'] = 'Sign Up Or Login To Continue';
      this.v['by_signing_up_you_agree_to_our'] = 'by signing up you agree to our';
      this.v['terms_and_conditions'] = 'terms and conditions';
      this.v['and'] = 'and';
      this.v['LOGIN_WITH_MOBILE'] = 'LOGIN WITH MOBILE';
      this.v['min_order'] = 'min order';
      this.v['can_you_confirm_if_this_is_your_location'] = 'Can you confirm if this is your location?';
      this.v['select_a_different_location'] = 'Select a different location';
      this.v['confirm_location'] = 'Confirm Location';
      this.v['add_a_new_address'] = 'Add a New Address';
      this.v['add_a_label'] = 'Add a label';
      this.v['home'] = 'Home';
      this.v['work'] = 'Work';
      this.v['partner'] = 'Partner';
      this.v['other'] = 'Other';
      this.v['place_label'] = 'Place label';
      this.v['save_and_continue'] = 'Save and Continue';
      this.v['find_restaurants_and_shops_near_you'] = 'Find restaurants and shops near you';
      this.v['by_allowing_location_access_msg'] = 'By allowing location access,you can search for restuarants and shops near you and receive more accurate delivery';
      this.v['allow_location_access'] = 'Allow Location Access';
      this.v['enter_my_location'] = 'Enter My Location';
      this.v['deals'] = 'Campaign & Daily Deals';
      this.v['Error_for_getting_location'] = 'Allow to access this device location?';
      this.v['Login_with_apple_fail_message'] = 'Login error with apple, Try again later';
      this.v['login_with_apple'] = 'Login with Apple';
      this.v['JazzCash_wallet'] = 'JazzCash wallet';
      this.v['place_order_with_vipps'] = 'place order with vipps';
      this.v['place_order_with_jazzcashwallet'] = 'place order with jazzcash wallet';
      this.v['Featuredv1'] = 'Popular';
      this.v['Featuredv2'] = 'Featured';
      this.v['turkey'] = 'Turkey';
      this.v['united_arab_emirates'] = 'United Arab Emirates';
      this.v['credit_card_other'] = 'Credit card / Other';
      this.v['you_must_enter'] = 'You must enter';
      this.v['save_and_place_order'] = 'Save & place order';
      this.v['view_all'] = 'View all';
      this.v['payment_successful'] = 'Payment Successful';
      this.v['Welcome_to_aqrab'] = 'Welcome to AQRAB';
      this.v['delete_my_account'] = 'Delete my Account';
      this.v['apply_for_delete_account'] = 'Apply for delete account';
      this.v['cancel_request_for_delete_account'] = 'Cancel request for delete account';
      this.v['are_you_sure_to_submit_a_request_to_delete_the_account'] = 'Are you sure to submit a request to delete the account';
      this.v['owner_name'] = 'Owner Name';
      this.v['business_name'] = 'Business Name';
      this.v['category'] = 'Category';
      this.v['shop_keeper_id'] = 'Shop Keeper ID';
      this.v['shop_keeper_name'] = 'Shop keeper Name';
      this.v['phone'] = 'Phone';
      this.v['gender'] = 'Gender';
      this.v['shipping_address'] = 'Shipping Address';
      this.v['contact'] = 'Contact';
      this.v['order_type'] = 'Order Type';
      this.v['guests'] = 'Persons';
      this.v['adults'] = 'Adults';
      this.v['date_and_time'] = 'Date and Time';
      this.v['vehicle_detail'] = 'Vehicle Detail';
      this.v['restaurant_infomartion'] = 'Restaurant Infomartion';
      this.v['see_map'] = 'See map';
      this.v['opening_timing'] = 'Opening Timing';
      this.v['see_more_information'] = 'See more information';
      this.v['search_a_place'] = 'Search a Place';
      this.v['organization_no'] = 'Organization No';
      this.v['organization_account_no'] = 'Organization Account No';
      this.v['message'] = 'Message';
      this.v['you_must_need_to_select_date'] = 'You must need to select date';
      this.v['you_must_need_to_select_time_slot'] = 'You must need to select time slot';
      this.v['slot_has_been_reserved'] = 'Slot has been reserved';
      this.v['enter_promo_code'] = 'Enter a promo code';
      this.v['seconds'] = 'Seconds';
      this.v['time_remaining'] = 'Time Remaining';
      this.v['happy_hours_vendor_not_available'] = 'Open after 21:00 every day <br /> Fight against food waste and enjoy good price food';
      this.v['amount_to_deduct_from_wallet'] = 'Amount to deduct from wallet';
      this.v['order_delivery_time'] = 'Order Delivery Time';
      this.v['order_pickup_time'] = 'Order Pickup Time';
      this.v['order_car_dining_time'] = 'Order Car Dining Time';
      this.v['asap'] = 'ASAP';
      this.v['confirm'] = 'Confirm';
      this.v['current_location_country_not_match_with_your_selected_location_country'] = 'Current location country not match with your selected locationÂ country.';
      this.v['pickup_location'] = 'Pickup Location';
      this.v['now'] = 'Now';
      this.v['add_to_favourite'] = 'Add To Favourite';
      this.v['drop_off_location'] = 'Drop-off Location';
      this.v['book_ride'] = 'Book Ride';
      this.v['pickup_from'] = 'Pickup From';
      this.v['drop_off_to'] = 'Drop-off To';
      this.v['select_vehicle'] = 'Select Vehicle';
      this.v['ride_charges'] = 'Ride Charges';
      this.v['estimated_time'] = 'Estimated Time';
      this.v['sender_details'] = 'Sender Details';
      this.v['address_detail'] = 'Address Detail';
      this.v['sender_contact'] = 'Sender Contact';
      this.v['receiver_details'] = 'Receiver Details';
      this.v['address_detail'] = 'Address Detail';
      this.v['receiver_contact'] = 'Receiver Contact';
      this.v['package_detail'] = 'Package Detail';
      this.v['scheduled_time'] = 'Scheduled Time';
      this.v['package_charges'] = 'Package Charges';
      this.v['instructions'] = 'Instruction';
      this.v['add_items'] = 'Add Items';
      this.v['place_request'] = 'Place Request';
      this.v['rides'] = 'Rides';
      this.v['packages'] = 'Packages';
      this.v['total_charges'] = 'Total Charges';
      this.v['package_items'] = 'Package Items';
      this.v['ride'] = 'Ride';
      this.v['package'] = 'Package';
      this.v['place_order_delivery'] = 'Place order';
      this.v['place_order_pickup'] = 'Place order';
      this.v['place_order_dine_in'] = 'Place order';
      this.v['place_order_car_dining'] = 'Place order';
      this.v['place_order_tablebooking_with_item'] = 'Place order';
      this.v['place_order_tablebooking_without_item'] = 'Confirm Booking';
      this.v['happy_hours'] = 'Happy Hours';
      this.v['catering'] = 'Catering';
      this.v['general_connection_time_out_message'] = 'Connection has timedÂ out';
      this.v['try_again'] = 'Try again';
      this.v['foodsted_wants_to_open_payment_provider'] = 'FOODSTED wants to open payment provider';
      this.v['open'] = 'Open';
      this.v['process_payment_place_order'] = 'Process Payment (Place Order)';
      this.v['your_booking'] = 'Your Booking';
      this.v['Midnight'] = 'Midnight';
      this.v['After_Midnight'] = 'After Midnight';
      this.v['Current_Order'] = 'Current Order';
      this.v['PayNow'] = 'PayNow';
      
    }


  }

}
