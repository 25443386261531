/// <reference types="@types/googlemaps" />
import { Component, OnInit } from '@angular/core';
import { Geolocation } from '@capacitor/geolocation';
import { ModalController } from '@ionic/angular';
import { EnvService } from 'src/app/services/env.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-vehicle-location',
  templateUrl: './vehicle-location.page.html',
  styleUrls: ['./../../general/general.scss', './vehicle-location.page.scss'],
})
export class VehicleLocationPage implements OnInit {

  quotation_vehiclelat: number = 31.4504498;
  quotation_vehiclelang: number = 74.3822896;

  quotation_maplat: number = 31.4504498;
  quotation_maplang: number = 74.3822896;

  constructor(
    public lang: LangService,
    private modalController: ModalController,
    public env: EnvService
  ) {}

  ngOnInit() {
  }
  
  addMarker(lat: any, lng: any) {
    this.quotation_vehiclelat = lat;
    this.quotation_vehiclelang = lng;
  }

  mapDragEnd($event) {
    // console.log( $event );
    this.addMarker($event.lat, $event.lng);
  }

  call_QryPosition($event) {
    // console.log( 'call_QryPosition' );
    // console.log( $event );
    // this.addMarker($event.lat, $event.lng);
  }

  async setCurrentLocation() {
    console.log('setCurrentLocation');

    let coordinates = await Geolocation.getCurrentPosition();
    this.quotation_vehiclelat = coordinates.coords.latitude;
    this.quotation_vehiclelang = coordinates.coords.longitude;

    this.quotation_maplat = coordinates.coords.latitude;
    this.quotation_maplang = coordinates.coords.longitude;

    /*
	  this.geolocation.getCurrentPosition().then((resp) => {

      this.quotation_vehiclelat = resp.coords.latitude;
      this.quotation_vehiclelang = resp.coords.longitude;
      
      this.quotation_maplat = resp.coords.latitude;
      this.quotation_maplang = resp.coords.longitude;
    });
    */
  }

  close() {
    this.modalController.dismiss('');
  }

  confirm() {
    let responseData = {
      'quotation_vehiclelat': this.quotation_vehiclelat,
      'quotation_vehiclelang': this.quotation_vehiclelang,
    };    
    this.modalController.dismiss(responseData);
  }

}
