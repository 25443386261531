import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { LangService } from './lang.service';


@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(
    private toastController: ToastController,
    private alertController: AlertController,
    private lang: LangService
    ) { }

  async newMessageToast(title:any, body:any, time:number) {
    const toast = await this.toastController.create({
      header: title,
      message: body,
      duration: time,
      position: 'middle',
      cssClass: 'errorToastclass',
      buttons: [
        {
          text: 'X',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]      
    });
    toast.present();
  }

  async presentToast(message: any) {
    const toast = await this.toastController.create({
      message: message,      
      duration: 15000,
      position: 'middle',
      cssClass: 'errorToastclass',
      buttons: [
        {
          icon: 'close-circle-outline',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]      
    });
    toast.present();
  }

  /*
  async presentToast(message: any) {
    const toast = await this.toastController.create({
      message: message,
      duration: 2000,
      position: 'top',
      color: 'dark'
    });
    toast.present();
  }
  */

  async successToast(message: any) {
    const toast = await this.toastController.create({
      message: message,
      duration: 5000,
      position: 'middle',
      cssClass: 'successToastclass',
      buttons: [
        {
          icon: 'close-circle-outline',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    
    });
    toast.present();
  }

  async success(title:any,des:any) {
    const alert = await this.alertController.create({
      header: title,
      cssClass:'success',
      message: des,
      buttons: [this.lang.v.Close],
      mode: 'ios'
    });
    await alert.present();
  }

  async error(title:string, error:string) {
    const alert = await this.alertController.create({
      header: title,
      cssClass:'error',
      message: error,
      buttons: [this.lang.v.Close],
      mode: 'ios'
    });
    await alert.present();
  }
  

}
