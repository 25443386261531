import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GeneralService } from 'src/app/services/general.service';
import { AlertService } from 'src/app/services/alert.service';
import { Storage } from '@ionic/storage';
import { EnvService } from 'src/app/services/env.service';
import { LoadingController, ModalController, NavController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { AppComponent } from 'src/app/app.component';
import { LangService } from 'src/app/services/lang.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-orderdeliveryinfo',
  templateUrl: './orderdeliveryinfo.page.html',
  styleUrls: ['../../general/ngeneral.scss', '../../general/general.scss', './orderdeliveryinfo.page.scss'],
})
export class OrderdeliveryinfoPage {

  dataloading:number = 1;
  active_loading:number = 1;

  party_displayname:any;
  country_calingcode:any;
  muser_mobile:any;
  mylocation_name:any;
  party_address:any;
  mylocation_result : any;

  saved_locationslist: any;
  selected_mlocation: any;
  selected_mlocation_index : number = undefined;

  constructor(private generalService: GeneralService,
    private alertService: AlertService,
    private storage: Storage,
    private env: EnvService,
    private modalController: ModalController,
    private route: Router,
    private navParams: NavParams,
    public app: AppComponent,
    public lang: LangService,
    private loadingController: LoadingController,
    private http: HttpClient,
    private navCtrl: NavController) { }

  ionViewWillEnter() {

    this.storage.get('saved_locationslist').then((data) => {
      if(data === null || data === undefined) {
        //ACTION
      } else {
        this.saved_locationslist = JSON.parse(data);
      }
    });

    if(this.navParams.get('party_displayname') == 'My Name' || this.navParams.get('party_displayname') == 'Mi nombre') {
      this.party_displayname = '';
    } else {
      this.party_displayname = this.navParams.get('party_displayname');
    }
    this.country_calingcode = this.navParams.get('country_calingcode');
    this.muser_mobile = this.navParams.get('muser_mobile');
    this.party_address = this.navParams.get('party_address');
    this.storage.get('selected_signature').then((data) => {
      this.mylocation_name = this.app.mylocation_name;
    });
    this.dataloading = 0;
    this.active_loading = 0;
  }

  closeModel() {
    this.modalController.dismiss('');
  }

  async applyChanging() {

    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
    });
    loading.present();

    const formData = new FormData();

    formData.append('without_branch', this.app.selected_signature.toString());
    formData.append('signature', this.app.selected_signature.toString());
    formData.append('pointer_latitude', this.app.mylocation_latitude.toString());
    formData.append('pointer_longitude', this.app.mylocation_longitude.toString());
    formData.append('mylocation_name', this.app.mylocation_name.toString());

    formData.append('muser_validation', '1');
    formData.append('muser_id', this.app.muser_result.muser_id.toString());
    formData.append('muserlsignature_signature', this.app.muserlsignature_signature);

    formData.append('musermobile_validation', '1');
    formData.append('country_calingcode', this.country_calingcode);
    formData.append('muser_mobile', this.muser_mobile);

    formData.append('musername_validation', '1');
    formData.append('muser_name', this.party_displayname);
    

    this.http.post(this.env.API_URL+'shop', formData).subscribe(
      (response: Response) => {

        // console.log(" response ");
        // console.log(response);

        console.log(response['country_calingcode']);

        if(response['muser_result']) {
          this.storage.set('muser_result', response['muser_result']).then(() => {

            let mResponse:any = [];
            mResponse['party_displayname'] = response['muser_name'];
            mResponse['country_calingcode'] = response['country_calingcode'];
            mResponse['muser_mobile'] = response['muser_mobile'];
            mResponse['mylocation_name'] = this.mylocation_name;
            mResponse['party_address'] = this.party_address;

            this.modalController.dismiss(mResponse);

          });
        } 

        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
          this.alertService.error('', this.lang.v.Error_to_connect);
        } else {
          this.alertService.error('', error.error.messagedetail);
        }

      }
    );

  }

  redirectlink(_link:string) {
    this.route.navigate([_link]);
    this.modalController.dismiss('');
  }

  focus_mobileinput() { }

  async trigge_changeAddress() {}


}
