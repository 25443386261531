import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'; 

import { AppComponent } from './app.component'; 
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AgmCoreModule } from '@agm/core';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { DatePipe } from '@angular/common'; 
import { CallNumber } from '@ionic-native/call-number/ngx';
import { HttpClientModule } from '@angular/common/http';
import { NmobilePageModule } from './modal/nmobile/nmobile.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NitemorderoptionPageModule } from './modal/nitemorderoption/nitemorderoption.module';
import { SreviewsPageModule } from './modal/sreviews/sreviews.module'; 
import { OrderdeliveryinfoPageModule } from './modal/orderdeliveryinfo/orderdeliveryinfo.module';
import { VehicleLocationPageModule } from './modal/vehicle-location/vehicle-location.module';
import { EventEmitterService } from './services/event-emitter.service';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { SelectvehiclePageModule } from './modal/selectvehicle/selectvehicle.module';
import { QtndInstructionsPageModule } from './modal/qtnd-instructions/qtnd-instructions.module';
import { QuotationpersonsPageModule } from './modal/quotationpersons/quotationpersons.module';
import { SelectvehiclecolorPageModule } from './modal/selectvehiclecolor/selectvehiclecolor.module';
import { VehiclenumberplatePageModule } from './modal/vehiclenumberplate/vehiclenumberplate.module';
import { FormsModule } from '@angular/forms';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { WcshopniteminstructionPageModule } from './walkingcustomerapp/modal/wcshopniteminstruction/wcshopniteminstruction.module';
import { WcshopnitemorderoptionPageModule } from './walkingcustomerapp/modal/wcshopnitemorderoption/wcshopnitemorderoption.module';
import { AgmDirectionModule } from 'agm-direction';
import { ItemalertmessagePageModule } from './modal/itemalertmessage/itemalertmessage.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD_J6ltB_3ZzYnRq03p_FkUAKKBDv6QjXs',
      language: 'en',
      libraries: ['places']
    }),
    AgmDirectionModule,
    NmobilePageModule,
    LazyLoadImageModule,
    NitemorderoptionPageModule,
    SreviewsPageModule,
    OrderdeliveryinfoPageModule,
    VehicleLocationPageModule,
    SelectvehiclePageModule,
    QtndInstructionsPageModule,
    VehicleLocationPageModule,
    QuotationpersonsPageModule,
    SelectvehiclecolorPageModule,
    VehiclenumberplatePageModule,
    FormsModule,
    WcshopniteminstructionPageModule,
    WcshopnitemorderoptionPageModule,
    ItemalertmessagePageModule,
    ServiceWorkerModule.register('combined-sw.js', {
      enabled: environment.production,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    InAppBrowser,
    DatePipe,
    CallNumber,
    AppComponent,
    EventEmitterService,
    Clipboard,
    SignInWithApple,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
 
