import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/services/alert.service';
import { EnvService } from 'src/app/services/env.service';
import { GeneralService } from 'src/app/services/general.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-selectvehicle',
  templateUrl: './selectvehicle.page.html',
  styleUrls: ['../../general/ngeneral.scss', './selectvehicle.page.scss'],
})
export class SelectvehiclePage implements OnInit {

  appvehicles_list:any = [];
  othervehicle_name:string = '';

  constructor(private loadingController:LoadingController,
    private generalService: GeneralService,
    private alertService: AlertService,
    private storage: Storage,
    private modalController:ModalController,
    public app: AppComponent,
    public env: EnvService,
    private http: HttpClient,
    public lang: LangService) { }

  ngOnInit() {
    this.storage.get('saved_locationslist').then(() => {
      this.setup();
    });
  }

  async setup() {
    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
    });
    loading.present();
    const formData = new FormData();
    formData.append('lgcity_id', this.app.selected_lgcity_id.toString());
    formData.append('pointer_latitude', this.app.mylocation_latitude.toString());
    formData.append('pointer_longitude', this.app.mylocation_longitude.toString());
    formData.append('call_appvehicleslist', '1');    
    
    this.http.post(this.env.API_URL+'setup', formData).subscribe(
      (response: Response) => {
        if(response['appvehicles_list']) {
          this.appvehicles_list = response['appvehicles_list'];
        }
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
            
            this.alertService.error('', this.lang.v.Error_to_connect);
        }
        else {
            
            this.alertService.error('', error.error.messagedetail);
        }
      }
    );
  }

  get_appvehiclename(data:any, lang:string) {
    if(lang == 'ar') {
      return data.appvehicle_name_ar;

    } else if(lang == 'no') {
      return data.appvehicle_name_no;

    } else if(lang == 'es') {
      return data.appvehicle_name_es;

    } else if(lang == 'ur') {
      return data.appvehicle_name_ur;

    } else {
      return data.appvehicle_name_en;

    }
  }

  get_otherinputvehicle() {
    if(this.othervehicle_name) {
      this.select_appvehiclename(this.othervehicle_name);
    } else {
      this.alertService.error('', this.lang.v.Error);
    }
  }

  select_appvehiclename(vName:string) {
    this.modalController.dismiss([vName]);
  }
  

}
