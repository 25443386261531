import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { EnvService } from './env.service';
import { HttpErrorResponse } from "@angular/common/http";
import { Storage } from '@ionic/storage';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  issSigned = false;
  selected_signature: string;

  isBranchSelected = false;
  selected_branchdata: any;

  constructor(
    private http: HttpClient,
    private env: EnvService,
    private storage: Storage,
    private route: Router,
    private platform: Platform
  ) {
    this.platform.ready().then(() => {
      this.getSignature();
      this.getShop();
    });
  }

  async getSignature() {
    try {
      const data = await this.storage.get('selected_signature');
      //console.log('GService');
      //console.log(data);
      this.selected_signature = data;
      if (this.selected_signature != null && this.selected_signature != 'null') {
        this.issSigned = true;
      }
      else {
        this.issSigned = false;
      }
    }
    catch (error) {
      this.issSigned = false;
    }
  }

  async getShop() {
    try {
      const data = await this.storage.get('selected_branchdata');
      this.selected_branchdata = data;

      if (this.selected_branchdata != null) {
        this.isBranchSelected = true;
      }
      else {
        this.isBranchSelected = false;

      }
    }
    catch (error) {
      this.selected_branchdata = null;
      this.isBranchSelected = false;
    }
  }


  select_shop(_shopreord:any) {
    this.storage.set('selected_branchdata', _shopreord).then(()=>{
      this.selected_branchdata = _shopreord;
      this.isBranchSelected = true;
      this.route.navigate(['/tabs/shop-index']);
    })
  }

  select_shop_redirect(_shopreord:any, redirecturl:any) {
    this.storage.set('selected_branchdata', _shopreord).then(()=>{
      this.selected_branchdata = _shopreord;
      this.isBranchSelected = true;
      if(redirecturl) {
        this.route.navigate([redirecturl]);
      }
      
    })
  }

  getcounttest() {
    return 123;
  }

  getAddressFromLatitureLongitude(map_latitude:any, map_longitude:any) {
    return this.http.get('https://maps.googleapis.com/maps/api/geocode/json?latlng='+map_latitude+','+map_longitude+'&key=AIzaSyD_J6ltB_3ZzYnRq03p_FkUAKKBDv6QjXs&language=en')
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  load_tags(_type:string) {
    return this.http.get(this.env.API_URL + 'loadtag_list?type='+_type)
    .pipe(
      tap(response => {      
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  get_shoplist(pointer_latitude:any, pointer_longitude:any, radius_km:any, records_limit:any, selected_signature:string, active_branch_id:number, active_cmp_id:number, mdistance_unit:any, selected_lgcity_id:any, quotation_deliverytype:any) {
    return this.http.get(this.env.API_URL + 'location_list?pointer_latitude='+pointer_latitude+'&pointer_longitude='+pointer_longitude+'&radius_km='+radius_km+'&records_limit='+records_limit+'&signature='+selected_signature+'&active_branch_id='+active_branch_id+'&active_cmp_id='+active_cmp_id+'&mainpage=1&mdistance_unit='+mdistance_unit+'&lgcity_id='+selected_lgcity_id+'&quotation_deliverytype='+quotation_deliverytype)
    .pipe(
      tap(response => {
        console.log(response);
        if(response['signature']) {
          this.selected_signature = response['signature'];
          this.storage.set('selected_signature', this.selected_signature);
          this.issSigned = true;
        }
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  load_moreshops(selected_lgcity_id:any, pointer_latitude:any, pointer_longitude:any, radius_km:any, selected_signature:string, active_branch_id:number, active_cmp_id:number, mdistance_unit:any, search_text:any, page:number, quotation_deliverytype:any) {
    return this.http.get(this.env.API_URL + 'location_list?pointer_latitude='+pointer_latitude+'&pointer_longitude='+pointer_longitude+'&radius_km='+radius_km+'&signature='+selected_signature+'&active_branch_id='+active_branch_id+'&active_cmp_id='+active_cmp_id+'&mainpage=1&mdistance_unit='+mdistance_unit+'&search='+search_text+'&page='+page+'&load_moreshoprecord=1&records_limit=1&lgcity_id='+selected_lgcity_id+'&quotation_deliverytype='+quotation_deliverytype)
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  get_neabyshoplist(selected_lgcity_id:any, pointer_latitude:any, pointer_longitude:any, radius_km:any, records_limit:any, selected_signature:string, active_branch_id:number, active_cmp_id:number, quotation_deliverytype:number) {
    return this.http.get(this.env.API_URL + 'location_list?pointer_latitude='+pointer_latitude+'&pointer_longitude='+pointer_longitude+'&radius_km='+radius_km+'&records_limit='+records_limit+'&signature='+selected_signature+'&active_branch_id='+active_branch_id+'&active_cmp_id='+active_cmp_id+'&lgcity_id='+selected_lgcity_id+'&quotation_deliverytype='+quotation_deliverytype)
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  search_shoplist(pointer_latitude:any, pointer_longitude:any, shop_search_text:string, cmpapptag_tag:string, item_filter:any, shop_ctype:any, shop_tags:any, item_category:any, item_subcategory:any) {
    return this.http.get(this.env.API_URL + 'search_branch?pointer_latitude='+pointer_latitude+'&pointer_longitude='+pointer_longitude+'&shop_search_text='+shop_search_text+'&cmpapptag_tag='+cmpapptag_tag)
    .pipe(
      tap(response => {     
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  /*
  shop_index_setup(selected_cmp_id:any, selected_shop_id:any, selected_signature:string) {
    if(selected_signature === undefined || selected_signature === null || selected_signature === 'null') { selected_signature = ''; }
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+selected_cmp_id+'&shop_id='+selected_shop_id+'&getcategories=1&getsubcategories=0&item_list=0&signature='+selected_signature+'&cart=1')
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }
  */


  load_needypersonapplications(selected_signature: string)
  {
    return this.http.get(this.env.API_URL + 'load_needypersonapplication?signature='+selected_signature)
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )    
  } 

  action_muser(muser_email:any, country_id:any, current_lang:string) {
    return this.http.post(this.env.API_URL + 'muser?add=1',
    {
      muser_email: muser_email,
      country_id: country_id,
      lang: current_lang
    })
    .pipe(
      tap(gResponse => {
        return gResponse;
      })
    )
  }

  action_mobilemuser(country_id:number, country_name:string, country_iso2:string, country_calingcode:number, muser_mobile:any, current_lang:string) {
    return this.http.post(this.env.API_URL + 'mobilemuser?add=1',
    {
      country_id: country_id,
      country_name: country_name,
      country_iso2: country_iso2,
      country_calingcode: country_calingcode,
      muser_mobile: muser_mobile,
      lang: current_lang
    })
    .pipe(
      tap(gResponse => {
        return gResponse;
      })
    )
  }

  update_muser(muser_id:any, muser_name:any, country_id:any, country_name:any, country_iso2:any, country_calingcode:any, muser_mobile:any, muser_address:any) {
    return this.http.post(this.env.API_URL + 'muser?update=1',
    {
      muser_id: muser_id,
      muser_name: muser_name,
      country_id: country_id,
      country_name: country_name,
      country_iso2: country_iso2,
      country_calingcode: country_calingcode,
      muser_mobile: muser_mobile,
      muser_address: muser_address
    })
    .pipe(
      tap(response => {    
        return response;
      },
      error => { console.log(error) }
      )
    )
  }


  action_muser_sendpin(muser_email:any, selected_country_id:any, _genenrate:number, current_lang:string) {
    return this.http.post(this.env.API_URL + 'muser?add=1&generate='+_genenrate,
    {
      muser_email: muser_email,
      country_id: selected_country_id,
      lang: current_lang
    })
    .pipe(
      tap(gResponse => {
        return gResponse;
      })
    )
  }

  action_login_muser(selected_country_id:any, muserltoken_token:any, muser_email:any, muser_pin:any, muser_referencecode:any, current_lang:string) {
    return this.http.post(this.env.API_URL + 'muser?login=1',
    {
      muser_email: muser_email,
      muser_pin: muser_pin,
      muserltoken_token: muserltoken_token,
      country_id: selected_country_id,
      muser_referencecode: muser_referencecode,
      lang: current_lang
    })
    .pipe(
      tap(gResponse => {
        return gResponse;
      })
    )
  }

  action_login_mobilemuser(muserltoken_token:any, muser_fmobile:any, muser_pin:any, muser_referencecode:any, current_lang:string) {
    return this.http.post(this.env.API_URL + 'mobilemuser?login=1',
    {
      muser_fmobile: muser_fmobile,
      muser_pin: muser_pin,
      muserltoken_token: muserltoken_token,
      muser_referencecode: muser_referencecode,
      lang: current_lang
    })
    .pipe(
      tap(gResponse => {
        return gResponse;
      })
    )
  }

  action_mobilemuser_sendpin(muser_fmobile:any, country_id:number, _genenrate:number, current_lang:string) {
    return this.http.post(this.env.API_URL + 'mobilemuser?resendcode=1&generate='+_genenrate,
    {
      muser_fmobile: muser_fmobile,
      country_id: country_id,
      lang: current_lang
    })
    .pipe(
      tap(gResponse => {
        return gResponse;
      })
    )
  }


  update_mobilemuser(muser_id:any, muser_name:any, muser_email:any, muser_address:any) {
    return this.http.post(this.env.API_URL + 'mobilemuser?update=1',
    {
      muser_id: muser_id,
      muser_name: muser_name,
      muser_email: muser_email,
      muser_address: muser_address,
    })
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }


  save_needypersonapplication(ndrequest_id: number, ndrequest_name: string, ndrequest_mobile: string, ndrequest_city: string, ndrequest_address: string, ndrequest_description: string, ndrequest_lat: number, ndrequest_lag: number, selected_signature: string)
  {
    return this.http.post(this.env.API_URL + 'save_needypersonapplication?signature='+selected_signature,
    {
      ndrequest_id: ndrequest_id,
      ndrequest_name: ndrequest_name,
      ndrequest_mobile: ndrequest_mobile,
      ndrequest_city: ndrequest_city,
      ndrequest_address: ndrequest_address,
      ndrequest_description: ndrequest_description,
      ndrequest_lat: ndrequest_lat,
      ndrequest_lag: ndrequest_lag
    })
    .pipe(
      tap(gResponse => {
        /*
        if(gResponse['signature'])
        {
          this.storage.setItem('selected_signature', gResponse['signature'])
        }
        */
        return gResponse;
      })
    )
  }


  update_ndrequest(ndrequest_id: number, ndrequest_status: number, selected_signature: string)
  {
    return this.http.post(this.env.API_URL + 'update_needypersonapplication?signature='+selected_signature,
    {
      ndrequest_id: ndrequest_id,
      ndrequest_status: ndrequest_status
    })
    .pipe(
      tap(gResponse => {
        /*
        if(gResponse['signature'])
        {
          this.storage.setItem('selected_signature', gResponse['signature'])
        }
        */
        return gResponse;
      })
    )
  }


  get_needypersonlist(pointer_latitude:any, pointer_longitude:any, radius_km:any, records_limit:any) {
    return this.http.get(this.env.API_URL + 'needypersonslist_list?pointer_latitude='+pointer_latitude+'&pointer_longitude='+pointer_longitude+'&radius_km='+radius_km+'&records_limit='+records_limit)
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  get_ndrequest(ndrequest_id:number) {
    return this.http.get(this.env.API_URL + 'ndrequest_result?ndrequest_id='+ndrequest_id)
    .pipe(
      tap(response => {
        return response;
      },
        error => { console.log(error) }
      )
    )
  }


  shop_index_setup() {
    /*
    console.log('selected_signature');
    console.log(this.selected_signature);

    console.log('selected_branchdata');
    console.log(this.selected_branchdata);
    

   console.log('selected_branchdata');
   console.log(this.selected_branchdata);

   console.log('selected_branchdata CMP');
   console.log(this.selected_branchdata.cmp_id);

   console.log('selected_branchdata Branch');
   console.log(this.selected_branchdata.branch_id);
   */
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&getcategories=1&getsubcategories=0&item_list=0&cart=1&totalrating=1')
    .pipe(
      tap(response => {
        this.calculatefunc(response);
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  aboutpage_setup(pagereference:any, mylocation_latitude:any, mylocation_longitude:any) {
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&cart=1&totalrating=1&aboutusreference='+pagereference+'&mylocation_latitude='+mylocation_latitude+'&mylocation_longitude='+mylocation_longitude)
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }



  load_subcategores(_category:string) {
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&getsubcategories='+_category)
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  get_itemlist(category:string, getted_search_text:string) {
    if(category === undefined) { category = ''; }
    if(getted_search_text === undefined) { getted_search_text = ''; }
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&item_list='+category+'&search_item='+getted_search_text+'&cart=1')
    .pipe(
      tap(response => {
        this.calculatefunc(response);
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  item_addtocart(item_id:number, actionqty:number) {
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&item_addtocart='+item_id+'&qty='+actionqty+'&cart=1')
    .pipe(
      tap(response => {
        this.calculatefunc(response);
        return response;
      },
      error => { console.log(error) }
      )
    )
  }


  item_updatecart(item_id:number, quotationd_id:number, actionqty:number) {
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&item_addtocart='+item_id+'&qty='+actionqty+'&cart=1&updatecart_quotationd_id='+quotationd_id)
    .pipe(
      tap(response => {
        this.calculatefunc(response);
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  itemgroup_add_to_cart(item_id:number, qty:number, totaloption:number, itemgroup_ids:any) {

    let extra_item_segment = '';

    itemgroup_ids.forEach((itemgroup:any) => {
      extra_item_segment = extra_item_segment + '&itemgroup_id_'+itemgroup['itemgroup_id']+'='+itemgroup['itemgroup_value'];
    });
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&itemgroup_addtocart='+item_id+'&qty='+qty+'&totaloption='+totaloption+'&cart=1'+extra_item_segment)
    .pipe(
      tap(response => {
        this.calculatefunc(response);
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  get_cartdetail() {  
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&cart=1')
    .pipe(
      tap(response => {
        this.calculatefunc(response);
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  itemorderoption(item_id:number) {  
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&itemorderoption='+item_id)
    .pipe(
      tap(response => {
        this.calculatefunc(response);
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  quotationddetail(quotationd_id:number, item_id:number) {
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&quotationdspecificationdetail='+quotationd_id+'&quotationddetail_itemid='+item_id)
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  globalcart() {  
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&globalcart=1')
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  get_itemresult(item_id:number) {
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&cart=1&item_id='+item_id)
    .pipe(
      tap(response => {
        this.calculatefunc(response);
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  calculatefunc(_response:any) {
    if(_response['cartitems_list']) {
      let cart_items:number = 0;
      for (let index = 0; index < _response['cartitems_list'].length; index++) {
        //const element = array[index];
        console.log(_response['cartitems_list'][index]['qty']);
        cart_items += parseFloat(_response['cartitems_list'][index]['qty']);
      }
      console.log('CART UPDATED');
      this.storage.set('cart_items', cart_items);
    }
  }

  mobilelogin_setup(country_name:string) {  
    return this.http.get(this.env.API_URL + 'mobilelogin_setup?country_name='+country_name)
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  party_login(country_id:number, country_name:string, country_iso2:string, country_calingcode:string, muser_name:string, muser_mobile: string) {
    return this.http.post(this.env.API_URL + 'party_login?signature='+this.selected_signature,
    {
      country_id: country_id,
      country_name: country_name,
      country_iso2: country_iso2,
      country_calingcode: country_calingcode,
      muser_name: muser_name,
      muser_mobile: muser_mobile
    })
    .pipe(
      tap(gResponse => {
        return gResponse;
      })
    )
  }


  validate_order(party_displayname:string, party_mobile:string, party_address:string, quotation_remarks:string, quotation_deliverytype:number, quotation_date:any, quotation_time:any, muser_id:number) {
    return this.http.post(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&validateorder=1',
    {
      party_displayname: party_displayname,
      party_mobile: party_mobile,
      party_address: party_address,
      quotation_remarks: quotation_remarks,
      quotation_deliverytype: quotation_deliverytype,
      quotation_date: quotation_date,
      quotation_time: quotation_time,
      muser_id: muser_id,

    })
    .pipe(
      tap(response => {    
        return response;
      },
      error => { console.log(error) }
      )
    )
  }



  saveorder(party_displayname:string, party_mobile:string, party_address:string, quotation_remarks:string, quotation_deliverytype:number, quotation_date:any, quotation_time:any, muser_id:number, paidamount:string, payment_method:string, authorization_id:string, payment_id:string, payment_state:string, quotation_deliveryfee:any, quotation_servicefee:any, quotation_lat:any, quotation_lang:any, quotation_delivery:any) {

    return this.http.post(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&placeorder=1',
    {
      party_displayname: party_displayname,
      party_mobile: party_mobile,
      party_address: party_address,
      quotation_remarks: quotation_remarks,
      quotation_deliverytype: quotation_deliverytype,
      quotation_date: quotation_date,
      quotation_time: quotation_time,
      muser_id: muser_id,
      paidamount: paidamount,
      payment_method: payment_method,
      authorization_id: authorization_id,
      payment_id: payment_id,
      payment_state: payment_state,
      quotation_deliveryfee: quotation_deliveryfee,
      quotation_servicefee: quotation_servicefee,

      quotation_lat: quotation_lat,
      quotation_lang: quotation_lang,
      quotation_delivery: quotation_delivery,

    })
    .pipe(
      tap(response => {    
        return response;
      },
      error => { console.log(error) }
      )
    )
  }


  get_orderdetail(muser_id:number, active_order_id:number) {
    return this.http.post(this.env.API_URL + 'shop?disable_branchvalidation=1&signature='+this.selected_signature+'&order_detail='+active_order_id,
    {
      muser_id: muser_id,
    })
    .pipe(
      tap(response => {    
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  actionreview_quotation(index:any, quotation_id:any, action:any, quotation_rating:any, quotation_review:any, muser_id:number) {
    return this.http.post(this.env.API_URL + 'shop?actionreview_quotation=1&signature='+this.selected_signature,
    {
      muser_id: muser_id,
      index: index,
      quotation_id: quotation_id,
      action: action,
      quotation_rating: quotation_rating,
      quotation_review: quotation_review,
    })
    .pipe(
      tap(response => {    
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  get_myorders(muser_id:number) {
    return this.http.post(this.env.API_URL + 'shop?disable_branchvalidation=1&signature='+this.selected_signature+'&myorders=1',
    {
      muser_id: muser_id,
    })
    .pipe(
      tap(response => {    
        return response;
      },
      error => { console.log(error) }
      )
    )
  }


  get_timeslist(quotation_date:any, quotation_deliverytype:number) {
    return this.http.post(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&signature='+this.selected_signature+'&branch_id='+this.selected_branchdata.branch_id+'&times=1',
    {
      quotation_date: quotation_date,
      quotation_deliverytype: quotation_deliverytype,
    })
    .pipe(
      tap(response => {    
        return response;
      },
      error => { console.log(error) }
      )
    )
  }


  rbusiness_submit(rbusiness_name:string, rbusiness_email:string, rbusiness_phone:string, rbusiness_city:string, rbusiness_address:string) {
    return this.http.post(this.env.API_URL + 'rgeneral?active_rbusiness=1',
    {
      rbusiness_name: rbusiness_name,
      rbusiness_email: rbusiness_email,
      rbusiness_phone: rbusiness_phone,
      rbusiness_city: rbusiness_city,
      rbusiness_address: rbusiness_address,
    })
    .pipe(
      tap(response => {    
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  // rdriver_submit(this.rdriver_fname:string, this.rdriver_lname:string, this.rdriver_email:string, this.rdriver_phone:string, this.rdriver_city:string, this.rdriver_address:string, this.rdriver_vehicle:string, this.rdriver_experience:string, this.rdriver_remarks:string).subscribe(

  rdriver_submit(rdriver_fname:string, rdriver_lname:string, rdriver_email:string, rdriver_phone:string, rdriver_city:string, rdriver_address:string, rdriver_vehicle:string, rdriver_experience:string, rdriver_remarks:string) {
      return this.http.post(this.env.API_URL + 'rgeneral?active_rdriver=1',
      {
        rdriver_fname: rdriver_fname,
        rdriver_lname: rdriver_lname,
        rdriver_email: rdriver_email,
        rdriver_phone: rdriver_phone,
        rdriver_city: rdriver_city,
        rdriver_address: rdriver_address,
        rdriver_vehicle: rdriver_vehicle,
        rdriver_experience: rdriver_experience,
        rdriver_remarks: rdriver_remarks
      })
      .pipe(
        tap(response => {    
          return response;
        },
        error => { console.log(error) }
        )
      )
    }

    m_filtertag() {
      return this.http.get(this.env.API_URL + 'mshop?filtertag=1')
      .pipe(
        tap(response => {     
          return response;
        },
        error => { console.log(error) }
        )
      )
    }

    callfilter_subcategory(select_itemcategory_list:any) {
      let categores_string: any = '';
      select_itemcategory_list.forEach(item => {
        categores_string += "&mcat[]="+item;
      });
      return this.http.get(this.env.API_URL + 'mshop?subcategory=1'+categores_string)
      .pipe(
        tap(response => {     
          return response;
        },
        error => { console.log(error) }
        )
      )
    }


  msearch(selected_lgcity_id:any, pointer_latitude:any, pointer_longitude:any, shop_search_text:string, cmpapptag_tag:string, item_filter:any, shop_ctype:any, shop_tags:any, item_category:any, item_subcategory:any, quotation_deliverytype:any) {
    let hitable_urlparameters:any='';
    hitable_urlparameters += '?pointer_latitude='+pointer_latitude;
    hitable_urlparameters += '&pointer_longitude='+pointer_longitude;
    hitable_urlparameters += '&lgcity_id='+selected_lgcity_id;
    hitable_urlparameters += '&shop_search_text='+shop_search_text;
    hitable_urlparameters += '&cmpapptag_tag='+cmpapptag_tag;
    if(item_filter.isChecked) {
      hitable_urlparameters += '&item_filter=1';
    } else {
      hitable_urlparameters += '&item_filter=0';
    }
    hitable_urlparameters += '&quotation_deliverytype='+quotation_deliverytype;
    

    shop_ctype.forEach(element => {
      hitable_urlparameters += '&ctype[]='+element;
    });

    shop_tags.forEach(element => {
      hitable_urlparameters += '&tag[]='+element;
    });

    item_category.forEach(element => {
      hitable_urlparameters += '&icat[]='+element;
    });

    item_subcategory.forEach(element => {
      hitable_urlparameters += '&iscat[]='+element;
    });

    console.log('hitable_urlparameters');
    console.log(hitable_urlparameters);

    return this.http.get(this.env.API_URL + 'msearch'+hitable_urlparameters)
    .pipe(
      tap(response => {     
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

  get_orderdetailinfo(quotation_deliverytype:any, quotation_date:any, quotation_time:any) {  
    return this.http.get(this.env.API_URL + 'shop?cmp_id='+this.selected_branchdata.cmp_id+'&branch_id='+this.selected_branchdata.branch_id+'&signature='+this.selected_signature+'&validateorderdetailinfo=1&quotation_deliverytype='+quotation_deliverytype+'&quotation_date='+quotation_date+'&quotation_time='+quotation_time)
    .pipe(
      tap(response => {
        return response;
      },
      error => { console.log(error) }
      )
    )
  }

}
