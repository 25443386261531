import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { LoadingController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/services/alert.service';
import { EnvService } from 'src/app/services/env.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-qtnd-instructions',
  templateUrl: './qtnd-instructions.page.html',
  styleUrls: ['../../general/ngeneral.scss', './qtnd-instructions.page.scss'],
})
export class QtndInstructionsPage {

  quotationd_id : number;
  quotationd_actiontype : number;
  quotationd_instruction : string = '';

  constructor(
    public app: AppComponent,
    private loadingController: LoadingController,
    private http: HttpClient,
    private env: EnvService,
    private storage: Storage,
    private alertService: AlertService,
    private modalController: ModalController,
    public lang: LangService) { }

  ionViewWillEnter() {
    this.app.setupStorageVariables();
  }

  triggerRadioBtn(_aType:any) {
    this.quotationd_actiontype = _aType.detail.value;
  }

  async _uinstruction() {
    const loading = await this.loadingController.create({
      cssClass: 'loadingScreen',
      spinner: null,
      translucent: true,
      showBackdrop: false,
      message: (this.env.active_company=='aqrab' ? `<div class="_cLoading"><ion-img src="./assets/aqrab/loader.gif"></ion-img></div>` : `<div class="_cLoading"><ion-img src="./assets/foodsted/loader.gif"></ion-img></div>`),
    });
    loading.present();
    const formData = new FormData();
    formData.append('cmp_id', this.app.active_cmp_id.toString());
    formData.append('branch_id', this.app.active_branch_id.toString());
    formData.append('signature', this.app.selected_signature.toString());
    formData.append('pointer_latitude', this.app.mylocation_latitude.toString());
    formData.append('pointer_longitude', this.app.mylocation_longitude.toString());

    formData.append('active_saveinstruction', '1');
    formData.append('quotationd_id', this.quotationd_id.toString());
    formData.append('quotationd_instruction', this.quotationd_instruction);
    formData.append('quotationd_actiontype', this.quotationd_actiontype.toString());

    formData.append('cart', '1');
    this.http.post(this.env.API_URL+'shop', formData).subscribe(
      (response: Response) => {
        if(response['cartitems_list']) {
          this.storage.set('cartitems_list', response['cartitems_list']).then(() => {
            let rResponse = Array();
            rResponse['data'] = 1;
            rResponse['itemlist'] = response['cartitems_list'];
            this.modalController.dismiss(rResponse);
          });
        }
        loading.dismiss();
      },
      error => {
        loading.dismiss();
        console.log( error );
        console.log( error.error );
        if( error.error.messagedetail == undefined ) {
            
            this.alertService.error('', this.lang.v.Error_to_connect);
        } else {
            
            this.alertService.error('', error.error.messagedetail);
        }
      }
    );
  }

}
